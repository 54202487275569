class Server {
  constructor(eventBus) {
    this.url = 'https://api.rekroo.org/api';
    this.path = '/';
    this.token = localStorage.getItem('api_token');
    this.version = 11;
    this.$eventBus = eventBus;
  }

  request2(method, data = {}, onSuccess, onError, onFinally) {
    const requestParams = {...data};
    if (window.location.host.indexOf('localhost:')===0 && !data.token) requestParams.token = this.token;
    const checkForError = (response) => {
      if (!response.ok) throw Error(response.statusText);
      return response.json();
    };

    return fetch(`${this.url + '/' + method}`, {
      method: 'POST',
      body: JSON.stringify(requestParams),
      headers: {
        Accept: 'application/json',
      },
      credentials: "include",
    })
      .then(checkForError)
      .then((data) => {
        if (!data.success) {
            if (onError) onError(data);
            else this.$eventBus.emit('show-error', { error: data.error });
          return;
        }
        if (method.split('/')[1] === 'get') {
          if (data.version > this.version) {
            const checked = window.localStorage.getItem('checkedVersion');
            if (checked && Date.now() - checked < 5000) {
              window.localStorage.setItem('checkedVersion', '0');
            } else {
              window.location.reload();
            }
          }
        }
        if (typeof onSuccess === 'function') onSuccess(data);
        return data;
      })
      .catch((error) => {
        if (typeof onError === 'function') onError(error);

        this.$eventBus.emit('show-error', { error });
        return error;
      })
      .finally((data) => {
        if (typeof onFinally === 'function') onFinally(data);
      });
  }

  setCookie(name, value, days) {
    var expires = '';
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = '; expires=' + date.toUTCString() + ';domain=rekroo.org;path=/;samesite=None;secure;';
    }
    document.cookie = name + '=' + (value || '') + expires + ';';
  }

  request(method, data = {}, options = {}) {
    const requestParams = {
      token: data.token || this.token,
      ...data,
    };
    // const query = this.serialize(requestParams);

    const checkForError = (response) => {
      if (!response.ok) throw Error(response.statusText);
      return response.json();
    };
    if (!options.requestOptions) {
      options.requestOptions = {
        method: 'POST',
        body: JSON.stringify(requestParams),
        credentials: "include",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      };
    }
    return Promise.race([
      fetch(`${this.url + this.path + method}`, options.requestOptions)
        .then(checkForError),
      // .then(checkVersion),
      new Promise((_, reject) => setTimeout(() => reject({ status: 'timeout' }), options.timeout || 10000)),
    ])
      .catch((error) => {
        if (!options.preventDefaultErrorHandler && !options.onError) {
          let message = 'Неизвестная ошибка сервера';
          if (error.status === 'timeout') message = 'Превышено максимальное время ожидания';
          console.log({ error });
          if (error === 'security error 1') {
            alert('logout');
          } else {
            this.$eventBus.emit('show-error', { error });
          }
        }
        if (options.onError) options.onError(error);
        return Promise.reject(error);
      });
  }

  uploadFile(inputFile, data = {}, options = {}) {
    return new Promise((resolve) => {
      if (!inputFile.type.includes('image')) resolve(inputFile);

      const img = new Image();
      img.src = URL.createObjectURL(inputFile);

      img.onload = () => {
        if (img.naturalWidth > 1280) {
          const canvas = document.createElement('canvas');
          const width = 1280;
          const height = Math.floor(1280 * (img.naturalHeight / img.naturalWidth));
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);
          canvas.toBlob(resolve, 'image/jpeg');
        } else {
          resolve(inputFile);
        }
      };
    }).then((file) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('token', data.token || this.token);
      for (let i in data) formData.append(i, data[i]);
      return this.request('files/upload', {}, {
        requestOptions: {
          method: 'POST',
          body: formData,
        },
        ...options,
      });
    });
  }

  hasLoggedIn() {
    return new Promise((resolve, reject) => {
      if (!this.token) {
        resolve(false);
      }
    });
  }

  logout() {
    this.request2('auth/logout', {}, (data)=>{
      if (data.success) {
        // this.setCookie('token_js', '', 0);
        localStorage.clear();
        window.location.reload();
      }
    })
  }
}

export default Server;
