<template>
  <Modal ref="modal" title="Добавить карту">
    <div class="iframe-wrap">
      <iframe v-if="iframeSrc" :src="iframeSrc" frameborder="0"/>
      <Preloader :modelValue="true" v-else/>
    </div>
  </Modal>
</template>

<script>
import Preloader from '@/components/Preloader.vue';

export default {
  name: 'AddCardModal',
  components: { Preloader },
  emits: ['opened', 'closed', 'created', 'cardAdded'],
  data() {
    return {
      iframeSrc: null,
    };
  },
  methods: {
    messageListener(e) {
      const iframeOrigin = new window.URL(this.iframeSrc).origin;
      if (iframeOrigin === e.origin) {
        const data = JSON.parse(e.data);
        if (data.method === 'Payture.PaymentSuccess') {
          this.$emit('cardAdded', this);
        }
      }
    },
    open() {
      this.iframeSrc = null;

      this.$nextTick()
        .then(() => this.$server.request('user/addCard'))
        .then(({ response }) => {
          this.iframeSrc = response;
        });

      this.$refs.modal.open();
      window.addEventListener('message', this.messageListener);
    },
    close() {
      this.$refs.modal.close();
      window.removeEventListener('message', this.messageListener);
    },
  },
};
</script>

<style scoped lang="scss">
.iframe-wrap {
  width: 100%;
  min-height: 420px;

  iframe {
    width: 100%;
    height: 410px;
  }
}
</style>
