<template>
  <Modal ref="modal" content-class="add-candidates-modal" @backdrop-click="close" @close-icon-click="close"
         title="Добавить кандидата">
    <div class="add-candidates-modal-header" v-if="!isClosing">
      <v-menu v-if="checkedCandidateIds.length > 0" :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <div class="selected-candidates-button" v-bind="attrs" v-on="on">
            <CandidateMedia
              v-for="candidate in allCandidates.filter(_candidate=>_candidate.candidate ? checkedCandidateIds.slice(0, 6).includes(_candidate.candidate.id) : checkedCandidateIds.slice(0, 6).includes(_candidate.id))"
              :key="candidate.id"
              :candidate="candidate"
            />
            <span class="selected-candidates-count" v-if="checkedCandidateIds.length > 6">+{{ checkedCandidateIds.length - 6 }}</span>
          </div>
        </template>
        <v-list max-height="400">
          <v-list-item
            v-for="candidate in allCandidates.filter(_candidate=>checkedCandidateIds.includes(_candidate.candidate ? _candidate.candidate.id : _candidate.id))"
            :key="candidate.candidate ? candidate.candidate.id : candidate.id">
            <div class="candidates-list-item_media">
              <CandidateMedia :candidate="candidate"/>
              <CandidateRating :candidate="candidate"/>
            </div>
            <div class="candidates-list-item_inner">
              <div class="candidates-list-item_title">{{ candidate.candidate ? candidate.candidate.name : candidate.name }}</div>
              <div class="candidates-list-item_subtitle">{{ candidate.candidate ? candidate.candidate.surname : candidate.surname }}</div>
            </div>
            <div class="candidates-list-item_after">
              <div class="icon icon-close" @click="changeCandidate(true, candidate)"></div>
            </div>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-row>
      <v-col :cols=" isClosing ? 12 : 9">
        <Searchbar @input="loadCandidates(alreadyExistsCandidates, $event)"/>
      </v-col>
      <v-col v-if="!isClosing" cols="3" class="d-flex align-center">
        <v-btn text color="text_button" :ripple="false" min-height="20" height="20" class="pa-0" @click="createCandidate">
          <div class="icon icon-new mr-1"></div>
          Новый
        </v-btn>
      </v-col>
    </v-row>
    <Loader v-if="loading"/>
    <div class="candidates-list overflow-x-hidden" v-else>
      <ul v-if="candidates.length > 0">
        <li v-for="candidate in candidates" :key="candidate.candidate ? candidate.candidate.id : candidate.id">
          <label class="candidates-list-item">
            <div class="candidates-list-item_media">
              <CandidateMedia :candidate="candidate"/>
              <CandidateRating :candidate="candidate"/>
            </div>
            <div class="candidates-list-item_inner">
              <div class="candidates-list-item_title">{{ candidate.candidate ? candidate.candidate.name : candidate.name }}</div>
              <div class="candidates-list-item_subtitle">{{ candidate.candidate ? candidate.candidate.surname : candidate.surname }}</div>
            </div>
            <div class="candidates-list-item_after">
              <div class="check-wrap">
                <input
                  type="checkbox"
                  name="candidates"
                  :checked="checkedCandidateIds.includes( candidate.candidate ? candidate.candidate.id : candidate.id)"
                  @input="changeCandidate($event, candidate.candidate ? candidate.candidate : candidate)"
                >
                <div class="icon icon-check"></div>
              </div>
            </div>
          </label>
        </li>
      </ul>
      <NoResults v-else>
        <template v-slot:title>Кандидатов пока нет</template>
      </NoResults>
    </div>
    <template v-slot:actions>
      <v-btn depressed :ripple="false" @click="submit" :disabled="!checkedCandidateIds.length" color="primary">
        {{ checkedCandidateIds.length ? `Готово ( ${checkedCandidateIds.length} )` : 'Готово' }}
      </v-btn>
    </template>
    <Alert ref="alert_modal" @accept="close" @change-warn="updateUser"/>
  </Modal>
</template>

<script>
import Alert from '@/components/Alert';

export default {
  name: 'AddCandidatesModal',
  components: {
    Alert,
  },

  props: {
    multiple: Boolean,
  },
  data() {
    return {
      candidates: [],
      allCandidates: [],
      checkedCandidateIds: [],
      filterParams: {
        query: '',
        limit: '20',
      },
      needLoad: false,
      alreadyExistsCandidates: [],
      from: null,
      folder: null,
      loading: false,
      vacancyId: null,
      getCheckedCandidatesFromLS: false,
      isClosing: false,
    };
  },
  methods: {
    open(data) {
      this.candidates = [];
      this.allCandidates = [];
      this.checkedCandidateIds = [];
      this.from = null;
      this.folder = null;
      this.loading = false;
      this.vacancyId = null;
      this.getCheckedCandidatesFromLS = false;

      if (data.getCheckedCandidatesFromLS) this.getCheckedCandidatesFromLS = data.getCheckedCandidatesFromLS;
      if (data.candidates) this.candidates = data.candidates;
      if (data.needLoad) {
        this.needLoad = data.needLoad;
        if (data.alreadyExistsCandidates && !data.isClosing) {
          this.alreadyExistsCandidates = data.alreadyExistsCandidates;
          this.loading = true;
          this.loadCandidates(data.alreadyExistsCandidates, '', true);
        }
      }
      if (data.from) this.from = data.from;
      if (data.folder) this.folder = data.folder.toString();
      if (data.vacancyId) this.vacancyId = data.vacancyId;
      if (data.isClosing) this.isClosing = data.isClosing;

      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    submit() {
      if (this.checkedCandidateIds.length) {
        if (this.folder) {
          this.$eventBus.emit('candidates-selected-to-folder', {
            ids: this.checkedCandidateIds,
            folder: this.folder,
          });
          localStorage.setItem('checked_candidates', '');
          this.close();
          return;
        }
        if (this.from) {
          this.$eventBus.emit(`candidates-selected-${this.from}`, this.checkedCandidateIds);
        } else {
          this.$eventBus.emit('candidates-selected', this.checkedCandidateIds);
        }
        this.close();
      }
    },
    changeCandidate(event, candidate) {
      if ((typeof event === 'boolean' && !event) || event.target && event.target.checked) {
        this.checkedCandidateIds.push(candidate.id);
      } else {
        this.checkedCandidateIds = this.checkedCandidateIds.filter((id) => id !== candidate.id);
      }
    },
    loadCandidates(alreadyExistsIds, query = '', firstLoad = false) {
      this.$server.request2('candidate/get', {
        query,
        vacancy: this.vacancyId ? this.vacancyId : null
      }, (data) => {
        this.loading = false;
        if (firstLoad) this.allCandidates = data.response;
        if (alreadyExistsIds) {
          this.candidates = data.response.filter((candidate) => !alreadyExistsIds.includes(candidate.id));
        }
        if (this.getCheckedCandidatesFromLS && localStorage.getItem('checked_candidates')) {
          this.checkedCandidateIds = JSON.parse(localStorage.getItem('checked_candidates'));
        }
      }, () => this.loading = false);
    },
    createCandidate() {
      this.close();
      if (!(this.$store.state.user.settings && this.$store.state.user.settings.warn_candidate_add)) {
        this.$refs.alert_modal.open({
          title: 'Предупреждение',
          error: `После создания нового кандидата вы вернетесь к добавлению кандидатов в вакансию.<br>Выбранные тоже останутся:)`,
          showCheckbox: true,
        });
      }
      localStorage.setItem('checked_candidates', JSON.stringify(this.checkedCandidateIds));
      this.$router.push({
        path: '/candidates/create',
        query: { v: this.vacancyId }
      });
    },
    updateUser(value) {
      this.$server.request2('user/setSetting', {name: 'warn_candidate_add', value}, (data)=>{
        this.$store.commit('setUserData', data.response);
      });
    }
  },
  created() {
  },
  computed: {
    checkedCandidates() {
      return this.allCandidates.filter(_candidate => _candidate.candidate ? this.checkedCandidateIds.includes(_candidate.candidate.id) : this.checkedCandidateIds.includes(_candidate.id));
    }
  }
};
</script>

<style lang="scss">
.add-candidates-modal {
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }

  .empty-block {
    width: 32px;
    height: 32px;
  }

  .selected-candidates-button {
    cursor: pointer;
    position: relative;
    display: flex;

    & > * {
      margin-left: -9px;

      &:first-child {
        margin-left: 0;
      }
    }

    .selected-candidates-count {
      top: -9px;
      right: -9px;
      position: absolute;
      font-weight: bold;
      font-size: 10px;
      border-radius: 50%;
      padding: 1px;
      display: block;
      width: 16px;
      height: 16px;
    }
  }

  .modal-title {
    margin-bottom: 0;
  }
}

.candidates-list {
  min-height: 285px;
  margin-top: 18px;
  margin-bottom: 28px;

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  li {
    border-top: 1px solid var(--border-color);

    &:last-child {
      border-bottom: 1px solid var(--border-color);
    }
  }
}


.candidates-list-item {
  cursor: pointer;
  display: flex;

  &_media {
    position: relative;
    width: 46px;
    height: 46px;
    padding: 12px 0;
    box-sizing: content-box;
    margin-right: 18px;
    flex-shrink: 0;

    img {
      width: 100%;
      height: 100%;
    }

    .candidate-media {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .candidate-rating {
      position: absolute;
      bottom: 12px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  &_inner {
    width: 100%;
    padding: 15px 0;

    & > * {
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &_title {
    font-size: 14px;
    line-height: 20px;
  }

  &_subtitle {
    font-size: 14px;
    line-height: 16px;
    color: rgba(var(--page-color-main-rgb), .5);
  }

  &_after {
    align-self: stretch;
    display: flex;
    align-items: center;
  }

  .check-wrap {
    input {
      display: none;

      &:checked ~ .icon {
        display: block;
      }
    }

    .icon {
      display: none;
    }
  }
}
</style>
