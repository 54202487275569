<template>
  <div class="finance-head-block">
    <div class="finance-head-block_info">
      <div class="finance-head-block_title">
        {{ sumSeparator(value || 0) }}
      </div>
      <div class="finance-head-block_subtitle">Заработано</div>
    </div>
    <div class="finance-head-block_media">
      <img
        class="finance-head-block_media_img"
        src="@/images/pictures/picture-5.svg"
        alt="finance image"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'FinanceHead',
  props: {
    value: [String, Number],
  },
};
</script>

<style lang="scss">
.finance-head-block {
  margin-bottom: 38px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 24px;
  background: #DCF9F1;
  border-radius: 12px;

  &_title {
    font-weight: bold;
    font-size: 22px;
    line-height: 24px;
    letter-spacing: -0.5px;
  }

  &_subtitle {
    font-size: 13px;
    line-height: 17px;
  }

  &_info {
    & > * {
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &_media {
    position: relative;
    background: #FFFFFF;
    width: 64px;
    height: 64px;
    border-radius: 50%;

    &_img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(30deg);
      width: 120px;
    }
  }
}
</style>
