<template>
  <v-app v-if="deviceWidth > 768">
    <ViewTemplate v-if="isSharedLink"/>
    <ViewTemplate v-else/>
    <Alert ref="error-alert"/>
<!--    <Test v-if="$root.$test"/>-->
  </v-app>
  <v-app v-else>
    <MobilePage/>
  </v-app>
</template>
<script>
import '@/fonts/SFProDisplay/stylesheet.css';
import '@/fonts/rekroo-icons/style.css';
import '@/styles/main.scss';
import MainHeader from '@/components/MainHeader';
import ViewTemplate from '@/components/ViewTemplate.vue';
import MobilePage from '@/views/mobile/MobilePage.vue';
import Test from '@/tests/Test';

export default {
  components: {
    Test,
    MobilePage,
    ViewTemplate,
    MainHeader,
  },
  data() {
    return {
      deviceWidth: window.screen.width,
      isSharedLink: window.location.href.split('/').includes('shr')
    };
  },
  created() {
    window.app = this;
  },
  methods: {
    getUser() {
      this.$server.request2('user/get/me', {}, (data) => {
        this.$store.commit('setUserData', {
          ...data.response,
        });
      }, (data) => {
        if (data.error && data.error.type === '401') {
          if (!this.$route.path.split('/').includes('login')) this.$router.push('/login');
        }
      });
    },
  },
  mounted() {
    this.getUser();
    setTimeout(() => {
      if (document.cookie.indexOf('token_js') === -1 && this.$server.token) {
        // this.$server.setCookie('token_js', this.$server.token, 1000);
      }
    }, 500);
    this.$eventBus.on('show-error', (error, title = 'Ошибка') => {
      this.$refs['error-alert'].open({
        title,
        error: error.error || error
      });
    });
  },
};
</script>
