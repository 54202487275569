<template>
  <Modal ref="modal" content-class="share-modal" title="Поделиться ссылкой" :width="470">
    <div class="link-container">
      <a ref="link" class="share-link external" :href="link" target="_blank">{{ link }}</a>
      <div v-if="copied" class="icon icon-check"></div>
    </div>
    <div class="hint-text" v-if="type === 'vacancy'">Если вы хотите поделиться вакансиями с другим HR, перейдите в нужную вакансию и воспользуйтесь опцией "поделиться" там</div>
    <template v-slot:actions>
      <v-btn
        :ripple="false"
        color="primary"
        depressed
        class="default-button" :class="{'cancel-button': copied}" @click="copy">{{ copied ? 'Cкопировано' : 'Скопировать' }}
      </v-btn>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
export default {
  components: {
    Modal
  },
  name: 'ShareModal',
  data() {
    return {
      link: '',
      copied: false,
      candidate_id: '',
      type: null,
    };
  },
  methods: {
    open(data) {
      this.type = null;
      if (data.type) this.type = data.type;
      if (data.type === 'vacancy') {
        this.link = `https://web.rekroo.org/shr/v/${data.token}`;
      } else {
        this.link = `https://web.rekroo.org/shr/${data.token}`;
      }
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    copy() {
      navigator.clipboard.writeText(this.link);
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 3000);
    },
  },
};
</script>

<style scoped lang="scss">
.share-modal {
  .link-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .share-link {
      display: block;
    }

    .icon-check::before {
      font-size: 18px;
    }
  }
  .hint-text {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: rgba(var(--page-color-main-rgb), 0.5);
  }
}
</style>
