<template>
  <Modal ref="modal" @backdrop-click="onCancel" @close-icon-click="onCancel" :title="title ? title : ''" :vertical-btns="false">
    <v-textarea :rows="2" auto-grow v-model="promptValue" :placeholder="placeholder" :label="message"/>
    <template v-slot:actions>
      <div class="cancel-button mr-4" @click="cancelButtonClick">{{ cancelText }}</div>
      <div :class="{'disabled': !checkValue}" class="default-button" @click="acceptButtonClick">{{ acceptText }}</div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue';

export default {
  name: 'Prompt',
  components: {
    Modal,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    acceptText: {
      type: String,
      default: 'Ок',
    },
    cancelText: {
      type: String,
      default: 'Отмена',
    },
    validate: Function,
  },
  emits: [
    'accept',
    'cancel',
  ],
  data() {
    return {
      modal: null,
      promptValue: '',
    };
  },
  methods: {
    open() {
      this.modal.open();
    },
    close() {
      this.modal.close();
    },
    acceptButtonClick() {
      if (this.checkValue) {
        this.onAccept();
        this.modal.close();
      }
    },
    onAccept() {
      this.$emit('accept', this.promptValue);
    },
    cancelButtonClick() {
      this.onCancel();
      this.modal.close();
    },
    onCancel() {
      this.$emit('cancel');
    },
  },
  computed: {
    checkValue() {
      if (this.validate) return this.validate(this.promptValue);
      return this.promptValue;
    },
  },
  mounted() {
    this.modal = this.$refs.modal;
  },
};
</script>

<style lang="scss">
.prompt-field.form-field {
  margin-bottom: 24px;

  .form-field_title {
    font-size: 14px;
    line-height: 16px;
    color: var(--page-color-main);
  }

  .form-field_input {
    border-bottom: none;
  }
}
</style>
