import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import VueTheMask from 'vue-the-mask';
import Maska from 'maska';
import VCalendar from 'v-calendar';
import moment from 'moment';
import Swiper from 'swiper';
import EventBus from '@/plugins/EventBus';
import Server from '@/server/index';
import Vue2Editor from 'vue2-editor';
import App from './App.vue';
import { router, loginRouter, shareRouter } from './router';
import store from './store';
import GlobalComponents from './globalComponents';
import GlobalMixin from './globalMixin';
import 'swiper/swiper-bundle.css';
import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';
import main from '@/tests/main';

Vue.use(Vue2Editor);
Object.entries(GlobalComponents)
  .forEach(([name, component]) => Vue.component(name, component));

Vue.prototype.$eventBus = new EventBus();

if (location.host.indexOf('localhost:') === 0) {
  Vue.prototype.$test = {
    run: (params) => main.startTests(Vue.prototype.$eventBus, params),
  };
  let params = null;
  if (localStorage.getItem('startTests')) params = {
    ...JSON.parse(localStorage.getItem('startTests'))
  };
  // if (location.href.includes('/login')) params = { from: 'Поделиться_вакансией.loginToSharedAcc' };
  if (params) Vue.prototype.$test.run(params);
}

moment.locale('ru');
moment.relativeTimeThreshold('ss', 0);
moment.relativeTimeThreshold('s', 60);
Vue.prototype.$moment = moment;
Vue.prototype.$server = new Server(Vue.prototype.$eventBus);
Vue.prototype.$swiperCreate = (...args) => {
  return new Swiper(...args);
};

const isShared = window.location.href.split('/').includes('shr');
let currentRouter = {};
const hasLocalToken = !!window.localStorage.getItem('api_token');

if (isShared) {
  currentRouter = shareRouter;
} else {
  currentRouter = router;
}
window.hasLocalToken = hasLocalToken;
Vue.use(Maska)
  .use(currentRouter)
  .use(VueTheMask)
  .use(VCalendar);
Vue.mixin(GlobalMixin);
Vue.component('vue-timepicker', VueTimepicker);

new Vue({
  render: (h) => h(App),
  router: currentRouter,
  vuetify,
  store,
}).$mount('#app');

if (hasLocalToken) {
  const server = new Server();
  server.hasLoggedIn()
    .then((result) => {
      if (!result) {
        window.localStorage.setItem('api_token', '');
        if (!isShared) window.location.reload();
      }
    });
}
