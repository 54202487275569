export default {
  setItem(state, {
    item,
    section,
  }) {
    if (typeof item !== 'object') throw Error('"item" is not Object type');
    if (!item.id) throw Error('attribute "id" is not set');
    if (typeof section !== 'string') throw Error('"section" is not String type');
    if (!state.sections[section]) throw Error('Section not found in state');

    const sectionArr = state.sections[section];
    const index = sectionArr.findIndex(({ id }) => String(id) === String(item.id));
    if (index !== -1) {
      sectionArr[index] = { ...sectionArr[index], ...item };
    } else {
      sectionArr.push({ ...item });
    }
  },

  removeItem(state, {
    id,
    section,
  }) {
    if (!id) throw Error('attribute "id" is not set');
    if (typeof section !== 'string') throw Error('"section" is not String type');
    if (!state.sections[section]) throw Error('Section not found in state');

    const sectionArr = state.sections[section];
    const index = sectionArr.findIndex((item) => Number(item.id) === Number(id));
    sectionArr.splice(index, 1);
  },

  sortSection(state, {
    section,
    sort,
  }) {
    if (typeof section !== 'string') throw Error('"section" is not String type');
    if (!state.sections[section]) throw Error('Section not found in state');

    const sectionArr = state.sections[section];
    sectionArr.sort(sort);
  },

  setResumeHtml(state, object) {
    const index = state.resumeHtml.findIndex((item) => Number(item.id) === Number(object.id));
    if (index !== -1) {
      state.resumeHtml[index] = object;
    } else {
      state.resumeHtml.push({ ...object });
    }
  },

  setNotify(state, object) {
    const index = state.notifies.findIndex((item) => String(item.id) === String(object.id));
    if (index !== -1) {
      state.notifies[index] = object;
    } else {
      state.notifies.push({ ...object });
    }
  },
  removeNotify(state, id) {
    const index = state.notifies.findIndex((item) => String(id) === String(item.id));
    state.notifies.splice(index, 1);
  },
  sortAndSliceNotifies(state) {
    const sort = (a, b) => Number(b.datetime_notify) - Number(a.datetime_notify);
    const notSeen = state.notifies.filter((notifies) => !notifies.seen).sort(sort);
    const seen = state.notifies.filter((notifies) => notifies.seen).sort(sort);
    const all = [...notSeen, ...seen];
    state.notifies = all.slice(0, 10);
  },

  setUserData(state, data) {
    state.user = data;
  },

  setCard(state, object) {
    const index = state.cards.findIndex((item) => String(item.CardId) === String(object.CardId));
    if (index !== -1) {
      state.cards[index] = object;
    } else {
      state.cards.push({ ...object });
    }
  },
  removeCard(state, CardId) {
    const index = state.cards.findIndex((item) => String(CardId) === String(item.CardId));
    const card = state.cards[index];
    state.user.defaultCard = '';
    state.cards.splice(index, 1);
  },
};
