<template>
  <Modal ref="modal" :title="title ? title : ''">
    <div class="alert-message" v-html="error"></div>
    <v-checkbox v-if="showCheckbox" label="Больше не показывать" :true-value="1" :false-value="0" @change="onChangeShowWarn"
                class="alert-checkbox"
    />
    <template v-slot:actions>
      <div class="default-button" @click="acceptButtonClick">Ок</div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue';

export default {
  name: 'Alert',
  components: {
    Modal,
  },
  emits: [
    'accept',
    'change-warn'
  ],
  data() {
    return {
      title: '',
      error: '',
      showCheckbox: false
    };
  },
  methods: {
    open(params) {
      this.title = '';
      this.error = '';
      this.showCheckbox = false;
      this.title = params.title;
      this.error = params.error;
      if (params.showCheckbox) this.showCheckbox = params.showCheckbox;
      this.$refs.modal.open();

    },
    close() {
      this.$refs.modal.close();
    },
    acceptButtonClick() {
      this.$emit('accept');
      this.$refs.modal.close();
    },
    onChangeShowWarn(val) {
      this.$emit('change-warn', val);
    }
  },
};
</script>

<style lang="scss">
.alert-message {
  text-align: center;
  margin-bottom: 24px;
}

.alert-checkbox {
  width: fit-content;
  margin: 0 auto;
}
</style>
