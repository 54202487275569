<template>
  <v-menu
    ref="popover"
    class="popover-list-area"
    offset-x
    :min-width="width"
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        :style="activatorStyles"
        style="width: fit-content; height: fit-content"
        v-if="isSlot"
        v-bind="attrs"
        v-on="on">
        <slot
          name="activator"/>
      </div>
      <v-btn icon @click.stop v-else style="height: 100%"
             v-bind="attrs"
             v-on="on"
      >
        <v-icon>mdi-dots-horizontal
        </v-icon>
      </v-btn>
    </template>
    <v-list class="popover-list">
      <v-list-item
        class="popover-list-item"
        v-for="(button, index) in controlButtons" :key="index"
        v-if="button !== ''"
      >
        <v-btn text :color="button.color" @click="button.handler([item])">
          <v-icon size="16" v-if="button.icon" class="mr-1">{{button.icon}}</v-icon>
          {{ button.text }}</v-btn>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'PopoverList',
  props: ['controlButtons', 'item', 'isSlot', 'width', 'activatorStyles'],
  methods: {
    itemOnClick(button) {
      if (typeof button.handler === 'function') {
        if (button.type === 'global') {
          button.handler(this, [this.item]);
        } else {
          button.handler(this, this.item);
        }
      }
      this.hide();
    },
    hide() {
      this.$refs.popover.hide();
    },
  },
};
</script>

<style lang="scss">

.el-popper.popover-list-area {
  padding: 0;
  background: #FFFFFF;
  box-shadow: 2px 8px 30px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  border: none;
}

.popover-list {
  padding: 0 12px;
}

.popover-list-item {
  border-bottom: 1px solid var(--border-color);
  padding: 0;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  color: var(--page-color-main);
  font-family: var(--font-family);
  cursor: pointer;
  display: flex;
  align-items: center;
  word-break: break-word;
  text-align: left;
  &:last-child {
    border-bottom: none;
  }
  .icon {
    font-size: 18px;
    color: rgba(var(--page-color-main-rgb), .25);

    &.left-of {
      margin-right: 6px;
    }
  }
  .v-btn {
    padding: 12px 0 !important;
    justify-content: flex-start;
    flex-grow: 1;
    &__content {
      align-items: flex-start;
    }
  }
}
</style>
