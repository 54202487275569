import functions from '@/tests/functions';
import scripts from '@/tests/scripts';
import places from '@/tests/places';


async function dashboardCreateActions(index) {
  let dashboardPage = functions.querySelector('.dashboard-page');
  let actions = dashboardPage.querySelectorAll('.dashboard-actions_items .dashboard-actions_item');
  return actions[index];
}

async function openDashboard() {
  functions.openSection(0);
  await functions.wait(300);
}

export default {
  async openDashboard() {
    await openDashboard();
  },

  async createVacancy() {
    let action = await dashboardCreateActions(0);
    action.click();
    await functions.wait(300);
    let vacancy = await functions.createVacancy();
    await functions.wait(300);
    await openDashboard();
    let dashboardVacancyBlock = functions.querySelector('.dashboard-block-vacancies');
    let latestVacancies = dashboardVacancyBlock.querySelectorAll('.vacancy-item');
    let latestVacancy = functions.querySelector('.vacancy-item_title', latestVacancies[0]).innerText;
    if (latestVacancy !== vacancy.title) throw 'Новая вакансия не добавилась в список Последних вакансий';
  },

  async createCandidate() {
    let action = await dashboardCreateActions(1);
    action.click();
    await functions.wait(300);

    let candidates = await functions.createCandidateManual();
    await functions.wait(300);
    functions.openSection(0);
    await functions.wait(300);
    let dashboardCandidatesBlock = functions.querySelector('.dashboard-block-candidates');
    let latestCandidates = dashboardCandidatesBlock.querySelectorAll('.candidate-item');
    let latestCandidate = functions.querySelector('.candidate-main-info_title', latestCandidates[0]).innerText;
    if (latestCandidate !== `${candidates[candidates.length - 1].name} ${candidates[candidates.length - 1].surname}`) throw 'Новый кандидат не добавился в список Последних кандидатов';
  },

  async createInterview() {
    let action = await dashboardCreateActions(2);
    action.click();
    await functions.wait(300);

    await functions.fillEventModal();
  },

  async createNotify() {
    let action = await dashboardCreateActions(3);
    action.click();
    await functions.wait(300);

    await  functions.fillNotifyModal();
  },

  async createScript() {
    let action = await dashboardCreateActions(4);
    action.click();
    await functions.wait(300);
    await scripts.createScript();
  },
  async createPlace() {
    await openDashboard();
    let action = await dashboardCreateActions(5);
    action.click();
    await functions.wait(300);
    await places.createPlace();
  }

};