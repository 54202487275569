export default {
  masksSumSeparator: ['#', '##', '###', '# ###', '## ###', '### ###', '# ### ###', '## ### ###', '### ### ###'],
  foldersNames: [
    'Новые',
    'Прошёл скрининг',
    'Прошёл собеседование',
    'Отправлены заказчику',
    'Назначено собеседование с заказчиком',
    'Отправлен оффер',
    'Выведенные на работу',
  ],
  candidatesFoldersStatuses: [
    [0, 1, 2],
    [3, 4, 5, 6],
    [7, 8, 9],
    [10, 11, 12, 13],
    [14, 15, 16, 17],
    [18, 19, 20],
    [21, 22],
  ],
  candidatesStatuses: [
    {
      text: 'Новый',
    },
    {
      text: 'Связаться ещё раз',
      color: 'blue-bg',
    },
    {
      text: 'Не подходит',
      color: 'red-bg',
    },
    {
      text: 'Прошёл скриннинг',
    },
    {
      text: 'Назначено собеседование',
      color: 'green-bg',
    },
    {
      text: 'Связаться ещё раз',
      color: 'blue-bg',
    },
    {
      text: 'Не подходит',
      color: 'red-bg',
    },
    {
      text: 'Прошёл собеседование',
    },
    {
      text: 'Связаться ещё раз',
      color: 'blue-bg',
    },
    {
      text: 'Не подходит',
      color: 'red-bg',
    },
    {
      text: 'Отправлен заказчику',
    },
    {
      text: 'Согласован заказчиком',
      color: 'green-bg',
    },
    {
      text: 'Напомнить заказчику',
      color: 'blue-bg',
    },
    {
      text: 'Отклонён заказчиком',
      color: 'red-bg',
    },
    {
      text: 'Назначено собеседование с заказчиком',
    },
    {
      text: 'Проведено собеседование с заказчиком',
      color: 'green-bg',
    },
    {
      text: 'Назначить заново',
      color: 'blue-bg',
    },
    {
      text: 'Отклонён заказчиком',
      color: 'red-bg',
    },
    {
      text: 'Отправлен оффер',
    },
    {
      text: 'Отклонил оффер',
      color: 'red-bg',
    },
    {
      text: 'Принял оффер',
      color: 'green-bg',
    },

    {
      text: 'На испытательном сроке',
      color: 'blue-bg',
    },
    {
      text: 'Вышел на работу',
      color: 'green-bg',
    },
  ],
  vacancyStatuses: [
    {
      status: 1,
      text: 'Открыта',
      color: ''
    },
    {
      status: 2,
      text: 'Отменена',
      color: 'red-bg',
    },
    {
      status: 3,
      text: 'Закрыта',
      color: 'green-bg',
    },
  ],
  candidateContactsStatuses: {
    0: 'Дополнительный телефон',
    1: 'Telegram',
    3: 'VK',
    4: 'Facebook',
  },
  accessTypes: [
    {
      type: 'read',
      name: 'Чтение'
    },
    {
      type: 'full',
      name: 'Полный доступ'
    },
    {
      type: 'remove',
      name: 'Удалить доступ'
    },
  ],
};
