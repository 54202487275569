import dashboard from '@/tests/dashboard';
import vacancies from '@/tests/vacancies';
import functions from '@/tests/functions';
import candidates from '@/tests/candidates';
import events from '@/tests/events';
import profile from '@/tests/profile';
import auth from '@/tests/auth';
import share_vacancy from '@/tests/share_vacancy';
import scripts from '@/tests/scripts';
import places from '@/tests/places';
let sections = [
  {
    title: 'Поделиться_вакансией',
    methods: share_vacancy
  },
  {
    title: 'Авторизация',
    methods: auth
  },
  {
    title: 'Дашборд',
    methods: dashboard,
  },
  {
    title: 'Вакансии',
    methods: vacancies
  },
  {
    title: 'Кандидаты',
    methods: candidates
  },
  {
    title: 'События',
    methods: events,
  },
  {
    title: 'Профиль',
    methods: profile
  },
  {
    title: 'Скрипты',
    methods: scripts
  },
  {
    title: 'Адреса',
    methods: places
  }
];
let results = {
  success: 0,
  error: 0,
  warning: 0,
  total: 0,
  tests: []
};

for (let i = 0; i < sections.length; ++i) {
  sections[i].testsCount = Object.keys(sections[i].methods).length;
  results.total += sections[i].testsCount;
}
let startTests = async function (eventBus, params) {
  console.log('params',params);
  if (!localStorage.getItem('startTests')) {
    await functions.wait(300);
    if (!location.href.includes('/login')) functions.openSection(0);
    localStorage.setItem('startTests', JSON.stringify(params));
    location.reload();
    return;
  }
  results.timeStart = Date.now();
  localStorage.setItem('startTests', '');
  let key;
  window.delayBetweenMethods = params.speed === 'Fast' ? 100 : 600;
  await functions.wait(300);
  let runTest = !params.from;
  console.log({runTest});
  for (let i = 0; i < sections.length; ++i) {
    for (let method in sections[i].methods) {
      let testName = `${sections[i].title}.${method}`;
      if (params.from && !runTest && testName == params.from) {
        runTest = true;
      }
      if (!runTest) continue;
      // if (testName !== 'Вакансии.vacancyPageEventsTab') continue;
      results.tests.push({
        method: testName,
        response: ''
      });
      eventBus.emit('tests', results);
      let result;
      let asyncMethod = sections[i].methods[method];
      let timeoutPromise = new Promise(r => setTimeout(r, window.delayBetweenMethods));
      try {
        await Promise.all([asyncMethod(params), timeoutPromise])
          .then(v => result = v[0]);
      } catch (e) {
        result = typeof e === 'string' ? e : e.stack;
        console.log({ e });
      }
      key = typeof result === 'undefined' ? 'success' : 'error';
      results[key]++;
      results.tests[results.tests.length - 1].response = key === 'success' ? 'success' : result;
      // results.time = Date.now() - results.timeStart;
      eventBus.emit('tests', results);
      if (key === 'error') break;
    }
    if (key === 'error') {
      break;
    }
  }
  results.done = Date.now();
  eventBus.emit('tests', results);
};
export default { startTests };