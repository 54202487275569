<template>
  <Modal ref="modal" content-class="close-vacancy-modal" title="Закрыть вакансию" :vertical-btns="false" :width="490">
    <v-row class="mb-4">
      <v-col cols="6">
        <v-text-field
          :key="vacancy.fee"
          label="Гонорар"
          placeholder="50 000"
          v-model="vacancy.fee"
          v-maska="$store.state.static.masksSumSeparator"
          ref="fee-field"
          hide-details="auto"
        />
      </v-col>
      <v-col cols="6">
        <v-checkbox
          v-model="vacancy.fee_received"
          label="Гонорар получен"
          hide-details="auto"
        />
      </v-col>
    </v-row>
    <v-row class="ma-0 align-center dates-for-all" v-if="selectedCandidates.length > 1">
      <v-col cols="4" class="py-0">
        <v-checkbox
          v-model="probationOn"
          @change="dateWorkStart = ''; probationDateFrom = ''; probationDateTo = ''"
        >
          <template #label>
            <span class="text-no-wrap">Испыт. срок</span>
          </template>
        </v-checkbox>
      </v-col>
      <v-col v-if="!probationOn" class="px-0 py-0" cols="6">
        <DateMenu :value="dateWorkStart" placeholder="Дата выхода на работу"
                  input-class="pa-0 ma-0"
                  @update="dateWorkStart = $event; $forceUpdate" :is-clearable="true" :full-width="true"/>
      </v-col>
      <template v-else>
        <v-col class="px-0 date-col mr-4" cols="4">
          <DateMenu
            :full-width="true"
            input-class="pa-0 ma-0"
            :value="probationDateFrom" placeholder="Начало"
            @update="probationDateFrom = $event;" :is-clearable="true"/>
        </v-col>
        <v-col class="px-0 date-col" cols="4">
          <DateMenu :value="probationDateTo" placeholder="Конец"
                    :full-width="true"
                    input-class="pa-0 ma-0"
                    @update="probationDateTo = $event;" :is-clearable="true"/>
        </v-col>
      </template>
<!--      <v-spacer></v-spacer>-->
<!--      <v-btn small depressed :ripple="false" width="80" @click="applyDatesForAll">Применить<br>ко всем</v-btn>-->
    </v-row>
    <div class="selected-candidates">
      <div class="selected-candidates_item mb-4 " v-for="(candidate, index) in selectedCandidates" :key="candidate.id">
        <div class="selected-candidates_item_media">
          <CandidateMedia :candidate="candidate"/>
        </div>
        <div class="selected-candidates_item_title mr-2">
          {{ candidate.candidate ? candidate.candidate.name : candidate.name }}
          {{ candidate.candidate ? candidate.candidate.surname : candidate.surname }}
        </div>
<!--        <div class="selected-candidates_item_dates">
          <v-col class="pa-0 date-col mr-6">
            <v-checkbox
              class="mt-0"
              v-model="candidate.probationOn"
              @change="candidate.date_work = ''; candidate.date_notify_start = ''; candidate.date_notify = ''; $forceUpdate"
              hide-details="auto"
            >
              <template #label>
                <span style="font-size: 12px; white-space: nowrap">Испыт. срок</span>
              </template>
            </v-checkbox>
          </v-col>
          <v-row class="date-work-container pa-0" v-if="!candidate.probationOn">
            <v-col class="pa-0 date-col" style="min-width: 200px">
              <DateMenu :value="candidate.date_work" placeholder="Дата выхода на работу"
                        input-class="pa-0 ma-0"
                        @update="candidate.date_work = $event; candidate.errorDateWork = ''; $forceUpdate" :is-clearable="true" :full-width="true"
                        :error-text="candidate.errorDateWork"/>
            </v-col>
          </v-row>
          <v-row class="pa-0" v-else>
            <v-col class="pa-0 mr-4 date-col">
              <DateMenu
                :value="candidate.date_notify_start" placeholder="Начало"
                :full-width="true"
                input-class="pa-0 ma-0"
                @update="candidate.date_notify_start = $event; candidate.errorProbationDateFrom = ''" :is-clearable="true"
                :error-text="candidate.errorProbationDateFrom"/>
            </v-col>
            <v-col class="pa-0 date-col">
              <DateMenu :value="candidate.date_notify" placeholder="Конец"
                        :full-width="true"
                        input-class="pa-0 ma-0"
                        @update="candidate.date_notify = $event; candidate.errorProbationDateTo = ''" :is-clearable="true"
                        :error-text="candidate.errorProbationDateTo"/>

            </v-col>
          </v-row>
        </div>-->
        <v-spacer></v-spacer>
        <div class="selected-candidates_item_close" @click="removeCandidate(index)">
          <div class="icon icon-close"></div>
        </div>
      </div>
      <div class="selected-candidates_add" @click="openModalAddCandidates">
        <div class="selected-candidates_add_icon">
          <div class="icon icon-new"></div>
        </div>
        <div class="selected-candidates_add_text">Добавить кандидата</div>
      </div>
    </div>

    <!--    TODO закрытие вакансии: уведомления-->
    <!--        <div v-if="probationOn && probationDateFrom && probationDateTo">
              <div class="checkbox-row mb-4" v-for="(option, index) in remindOptions" :key="index">
                <v-checkbox
                  v-model="option.remind_value"
                  :label="option.remind_label"
                  hide-details
                />
                <div class="checkbox-row_content">
                  <div class="radio-labels" v-if="option.remind_value">
                    <v-btn-toggle v-model="option.remind_date_value" mandatory>
                      <v-btn small class="mr-3"
                             v-for="(btn, _index) in option.remind_dates"
                             :key="_index"
                      >{{ btn.label }}
                      </v-btn>
                    </v-btn-toggle>
                  </div>
                   <DateMenu :value="option.remind_date" label="Дата напоминания"
                        @update="option.remind_date = $event" :is-clearable="true"/>
                </div>
              </div>
            </div>-->
    <template v-slot:actions>
      <div class="cancel-button mr-4" @click="close">Назад</div>
      <div class="default-button" @click="submit">Готово</div>
    </template>
    <OnClose ref="on-close-modal" :remind-probation-start-on="remindProbationStartOn"/>
  </Modal>
</template>

<script>
import AddCandidatesModal from '@/views/candidates/AddCandidatesModal.vue';
import OnClose from '@/views/vacancy/close/OnClose';
import DateMenu from '@/components/DateMenu';

export default {
  name: 'CloseVacancyModal',
  components: {
    DateMenu,
    AddCandidatesModal,
    OnClose
  },
  data() {
    return {
      vacancy: {},
      selectedCandidates: [],
      probation_from_menu: false,
      probation_to_menu: false,
      probationOn: false,
      remind_probation_start_date_menu: false,
      remind_probation_end_date_menu: false,
      remind_ask_menu: false,
      probationDateFrom: null,
      probationDateTo: null,
      remindProbationStartOn: false,
      remindProbationStartType: 0,
      otherRemindProbationStartDate: '',

      candidateIds: [],
      remindProbationEndOn: false,
      remindProbationEndType: null,
      otherRemindProbationEndDate: '',
      remindAskOn: false,
      remindAskType: null,
      otherRemindAskDate: '',
      dateWorkStart: null,
      dateWorkStart_menu: false,
      remindOptions: [
        {
          remind_label: 'Напомнить мне о начале исп. срока',
          remind_value: this.remindProbationStartOn,
          remind_date_value: this.remindProbationStartType,
          remind_dates: [
            { label: 'За день' },
            { label: 'В день' },
            { label: 'Другая дата' },
          ],
          remind_menu_value: this.remind_probation_start_date_menu,
          remind_date: this.otherRemindProbationStartDate,
        },
        {
          remind_label: 'Напомнить мне об окончании исп. срока',
          remind_value: this.remindProbationEndOn,
          remind_date_value: this.remindProbationEndType,
          remind_dates: [
            { label: 'За день' },
            { label: 'В день' },
            { label: 'Другая дата' },
          ],
          remind_menu_value: this.remind_probation_end_date_menu,
          remind_date: this.otherRemindProbationEndDate,
        },
        {
          remind_label: 'Напомнить поинтересоваться через',
          remind_value: this.remindAskOn,
          remind_date_value: this.remindAskType,
          remind_dates: [
            { label: '2 мес' },
            { label: '1 мес' },
            { label: 'Другая дата' },
          ],
          remind_menu_value: this.remind_ask_menu,
          remind_date: this.otherRemindAskDate,
        },
      ],
    };
  },
  methods: {
    openModalAddCandidates() {
      this.$eventBus.emit('add-candidates-modal-open', {
        multiple: true,
        candidates: this.candidates,
        vacancyId: this.vacancy.id,
        isClosing: true,
      });
    },
    addCandidates(candidateIds) {
      if (this.candidates) {
        this.selectedCandidates = [];
        const ids = candidateIds.filter((id) => !this.candidateIds.includes(id));
        this.candidateIds.push(...ids);
        this.candidateIds.forEach((candidateId) => {
          this.selectedCandidates.push(this.candidates.find((candidate) => candidate.candidate.id === candidateId));
        });
      }
    },
    removeCandidate(index) {
     this.selectedCandidates.splice(index, 1);
     this.candidateIds.splice(index, 1);
    },
    open(data) {
      this.vacancy = {};
      this.selectedCandidates = [];
      this.probation_from_menu = false;
      this.probation_to_menu = false;
      this.probationOn = false;
      this.remind_probation_start_date_menu = false;
      this.remind_probation_end_date_menu = false;
      this.remind_ask_menu = false;
      this.probationDateFrom = null;
      this.probationDateTo = null;
      this.remindProbationStartOn = false;
      this.remindProbationStartType = 0;
      this.otherRemindProbationStartDate = '';
      this.candidateIds = [];
      this.remindProbationEndOn = false;
      this.remindProbationEndType = null;
      this.otherRemindProbationEndDate = '';
      this.remindAskOn = false;
      this.remindAskType = null;
      this.otherRemindAskDate = '';
      this.dateWorkStart = null;
      this.dateWorkStart_menu = false;

      if (data.vacancyId) this.getVacancy(data.vacancyId);
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    getVacancy(vacancyId) {
      this.$server.request2(`vacancy/get2/${vacancyId}`, {}, (data) => {
        this.vacancy = data.response;
        const selectedCandidates = data.response.candidates.filter(candidate => !['', '0'].includes(candidate.date_work) || !['', '0'].includes(candidate.date_notify_start) || !['', '0'].includes(candidate.date_notify));

         this.selectedCandidates = selectedCandidates.map(candidate => {
          return {
            id: candidate.candidate.id,
            name: candidate.candidate.name,
            surname: candidate.candidate.surname,
            image: candidate.image,
            date_work: candidate.date_work,
            date_notify_start: candidate.date_notify_start,
            date_notify: candidate.date_notify,
            probationOn: !candidate.date_work,
            errorProbationDateFrom: '',
            errorProbationDateTo: '',
            errorDateWork: '',
          };
        });
         this.vacancy.fee_received = this.vacancy.datetime_fee_receive*1 > 0;
        this.candidateIds = this.selectedCandidates.map(candidate => candidate.id);
      });
    },
    prepareCandidatesToResponse() {
      /*if (this.remindProbationStartOn) {
      if (this.remindProbationStartType === '0') {
        params.remind_probation_start = this.$moment(this.probationDateFrom)
          .add(-1, 'days')
          .format('YYYY-MM-DD');
      }
      if (this.remindProbationStartType === '1') {
        params.remind_probation_start = params.date_notify_start;
      }
      if (this.remindProbationStartType === '2') {
        params.remind_probation_start = this.$moment(this.otherRemindProbationStartDate)
          .format('YYYY-MM-DD');
      }
    }
    if (this.remindProbationEndOn) {
      if (this.remindProbationEndType === '0') {
        params.remind_probation_end = this.$moment(this.probationDateTo)
          .add(-1, 'days')
          .format('YYYY-MM-DD');
      }
      if (this.remindProbationEndType === '1') {
        params.remind_probation_end = params.date_notify;
      }
      if (this.remindProbationEndType === '2') {
        params.remind_probation_end = this.$moment(this.otherRemindProbationEndDate)
          .format('YYYY-MM-DD');
      }
    }
    if (this.remindAskOn) {
      if (this.remindAskType === '0') {
        params.remind_ask = this.$moment(this.probationDateTo)
          .add(1, 'months')
          .format('YYYY-MM-DD');
      }
      if (this.remindAskType === '1') {
        params.remind_ask = this.$moment(this.probationDateTo)
          .add(2, 'months')
          .format('YYYY-MM-DD');
      }
      if (this.remindAskType === '2') {
        params.remind_ask = this.$moment(this.otherRemindAskDate)
          .format('YYYY-MM-DD');
      }
    }*/

      this.applyDatesForAll();
      let preparedCandidates = this.selectedCandidates;
      preparedCandidates.forEach(candidate => {
        if (candidate.probationOn) {
          candidate.date_work = '';
          if (candidate.date_notify_start) {
            this.$moment(candidate.date_notify_start)
              .format('YYYY-MM-DD');
          } else {
            candidate.errorProbationDateFrom = 'Укажите дату начала испыт. срока';
          }
          if (candidate.date_notify) {
            this.$moment(candidate.date_notify)
              .format('YYYY-MM-DD');
          } else {
            candidate.errorProbationDateTo = 'Укажите дату окончания испыт. срока';
          }
        }
        if (!candidate.probationOn && candidate.date_work) {
          candidate.date_notify_start = '';
          candidate.date_notify = '';
          this.$moment(candidate.date_work)
            .format('YYYY-MM-DD');
        } else {
          candidate.errorDateWork = 'Укажите дату выхода на работу';
        }
      });
      const mappedCandidates = preparedCandidates.map(candidate=>{
        return {
          probationOn: candidate.probationOn,
          date_notify_start: candidate.date_notify_start ? this.$moment(candidate.date_notify_start).format('YYYY-MM-DD') : '',
          date_notify: candidate.date_notify ? this.$moment(candidate.date_notify).format('YYYY-MM-DD') : '',
          date_work: candidate.date_work ? this.$moment(candidate.date_work).format('YYYY-MM-DD') : '',
          id: candidate.id
        }
      })
      this.$forceUpdate();
      if (mappedCandidates.find(candidate=>candidate.probationOn && (!candidate.date_notify_start || !candidate.date_notify ))) {
        return;
      }
      if (mappedCandidates.find(candidate=>!candidate.probationOn && !candidate.date_work)) {
        return;
      }
      return mappedCandidates;
    },
    submit() {
      if (!this.prepareCandidatesToResponse()) return;

      this.vacancy.fee = this.vacancy.fee.replaceAll(' ', '');
      if (this.vacancy.fee_received && this.vacancy.datetime_fee_receive*1 === 0) {
        this.vacancy.datetime_fee_receive = this.$moment().unix();
      }
      this.$server.request2('vacancy/close', {
        vacancy: { fee: this.vacancy.fee, datetime_fee_receive: this.vacancy.datetime_fee_receive },
        id: this.vacancy.id,
        candidates: this.prepareCandidatesToResponse(),
      }, () => {
        this.$eventBus.emit('vacancy-close', this.vacancy.id);
        if (this.$route.path.includes('finance')) {
          this.close();
        } else {
          this.$router.push({ name: 'Vacancies' });
          if (!(this.$store.state.user.settings && this.$store.state.user.settings.success_close_vacancy_modal)) {
            this.$refs['on-close-modal'].open();
          }
          this.close();
        }
      });
    },
    applyDatesForAll() {
      this.selectedCandidates.forEach(candidate => {
        candidate.date_work = this.dateWorkStart;
        candidate.probationOn = this.probationOn;
        candidate.date_notify_start = this.probationDateFrom;
        candidate.date_notify = this.probationDateTo;
        candidate.errorProbationDateFrom = '';
        candidate.errorProbationDateTo = '';
        candidate.errorDateWork = '';
      });
      this.$forceUpdate();
    }
  },
  computed: {
    candidates() {
      return this.vacancy.candidates;
    },
  },
  created() {
    this.$eventBus.on('candidates-selected', (candidatesIds) => {
      this.addCandidates(candidatesIds);
    });
  },
  beforeDestroy() {
    this.$eventBus.off('candidates-selected');
  }
};
</script>

<style lang="scss">
.close-vacancy-modal {
  .dates-for-all {
    border-bottom: 1px solid var(--border-color);
  }

  .date-col {
    max-width: 130px;
  }

  .selected-candidates {
    margin-bottom: 24px;
    padding: 15px 0;

    &_add {
      max-width: 300px;
      padding: 9px 0;
      display: flex;
      align-items: center;
      cursor: pointer;

      &_icon {
        width: 24px;
        height: 24px;
        margin-right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: var(--border-color);

        .icon {
          font-size: 12px;
          font-weight: 600;
        }
      }

      &_text {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
      }
    }

    &_item {
      display: flex;
      align-items: center;

      &_media {
        flex-shrink: 0;
        margin-right: 12px;

        .candidate-media {
          width: 24px;
          height: 24px;
        }
      }

      &_title {
        width: 100%;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        max-width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &_dates {
        display: flex;
        align-items: center;

      }

      &_close {
        cursor: pointer;
        padding: 9px 0 9px 9px;
        flex-shrink: 0;

        .icon {
          font-size: 15px;
          font-weight: 500;
          color: rgba(var(--page-color-main-rgb), .25);
        }
      }
    }
  }

}
</style>
