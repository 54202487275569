<template>
  <div class="change-rating-event">
    <div class="change-rating-event_title">Ваша оценка</div>
    <div class="change-rating-event_items">
      <div
        class="icon"
        :class="[classRateIconFromPosition(current), colorIcon]"
        v-for="(current, index) in 5"
        :key="index"
        @click="setRate(current)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChangeEventRating',
  props: {
    modelValue: [String, Number],
  },
  emits: ['update:modelValue'],
  methods: {
    setRate(rating) {
      if (this.modelValue === rating) {
        this.$emit('update:modelValue', null);
        return;
      }
      this.$emit('update:modelValue', rating);
    },
    classRateIconFromPosition(position) {
      if (!Number(this.modelValue) || position > this.modelValue) return 'icon-rating-line';
      return 'icon-rating';
    },
  },
  computed: {
    colorIcon() {
      if (!Number(this.modelValue)) return '';
      if (this.modelValue >= 4) return 'green_icon';
      if (this.modelValue >= 3) return 'yellow_icon';
      return 'red_icon';
    },
  },
};
</script>

<style lang="scss">
.change-rating-event {
  margin-top: 10px;
  margin-bottom: 10px;
  &_title {
    font-size: 14px;
    line-height: 16px;
    color: rgba(var(--page-color-main-rgb), .5);
    margin-bottom: 8px;
  }

  .icon {
    font-size: 28px;
    color: rgba(0, 0, 0, .25);
    cursor: pointer;

    &.green_icon {
      color: #8ABCAE;
    }

    &.yellow_icon {
      color: #BA9A35;
    }

    &.red_icon {
      color: #B8705E;
    }
  }
}
</style>
