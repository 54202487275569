import functions from '@/tests/functions';

export default {
  async openCalendar() {
    functions.openSection(3);
    await functions.wait(300);
  },
  async createEvent() {
    let page = functions.querySelector('.page');
    functions.querySelector('.page-header-button.default-button', page).click();
    await functions.wait(300);
    let createdEvent = await functions.fillEventModal();
    console.log('createdEvent data', createdEvent);
    await functions.wait(700);
    let calendar = functions.querySelector('.vc-container .vc-weeks', page);
    let dayWhenCreatedEvent = functions.querySelector(`.vc-day.id-${createdEvent.date}`, calendar);
    let eventsList = functions.querySelector('.event-list', page);

    if (!eventsList.innerHTML.includes(createdEvent.candidate) && !eventsList.innerHTML.includes(createdEvent.time_from))
      throw 'Ивент с кандидатом не создался';
  }
}