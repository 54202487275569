<template>
  <Modal ref="modal" :title="place.id ? 'Редактировать адрес' : 'Добавить адрес'">
    <v-form class="form">
      <v-autocomplete
        ref="search_address_field"
        class="mb-4"
        v-model="place.address"
        :loading="loadingAddresses"
        label="Поиск адреса или места"
        :items="addresses"
        :search-input.sync="search"
        no-data-text="Адрес не найден"
        return-object
        hide-no-data
        item-text="address"
        persistent-placeholder
        append-icon="mdi-chevron-down"
        :hide-details="!addressError"
        :error-messages="addressError"
      />
      <v-text-field
        v-model="place.title"
        placeholder="Место встречи"
        label="Название"
        persistent-placeholder
      />
      <v-text-field
        v-model="place.comment"
        placeholder="5 этаж, офис 105, напротив ресепшн"
        label="Этаж, офис, другая полезная информация"
        persistent-placeholder
      />
    </v-form>
    <template v-slot:actions>
      <v-btn :loading="submitLoading" class="default-button" :class="{'disabled': !checkData}" @click="submit"
             depressed color="primary"
             :ripple="false">
        Сохранить
      </v-btn>
      <div class="remove-button" @click="$refs['confirm-remove'].open()" v-if="place.id">Удалить</div>
    </template>
    <Confirm ref="confirm-remove" message="Удалить адрес?" @accept="remove"/>
  </Modal>
</template>

<script>
import LiveSearch from '@/components/LiveSearch.vue';

export default {
  name: 'PlaceModal',
  components: { LiveSearch },
  emits: ['submit', 'remove'],
  data() {
    return {
      places: [],
      place: {},
      addressError: '',
      geolocation: {},
      currentSearchedAddress: {},
      addresses: [],
      loadingAddresses: false,
      search: null,
      submitLoading: false,
    };
  },
  methods: {
    open(params = {}) {
      this.place.id = null;
      this.place = {};
      this.addresses = [];
      this.geolocation = {};
      if (params.id) this.place.id = params.id;
      if (window.navigator.geolocation && !params.id) {
        window.navigator.geolocation.getCurrentPosition(({ coords }) => {
          this.geolocation.lat = coords.latitude;
          this.geolocation.lng = coords.longitude;
          this.searchPlaces();
        });
      }

      if (this.place.id) {
        this.$server.request2(`place/get/${this.place.id}`, {}, (data) => {
          this.place = data.response;
          this.addresses.push(this.place);
          this.$refs.search_address_field.focus();
        });
      }
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    searchPlaces(query) {
      this.loadingAddresses = true;

      this.$server.request2('place/search', { q: query, lat: this.geolocation.lat, lng: this.geolocation.lng }, (data) => {
        this.addresses = data.response;
        this.loadingAddresses = false;
        if (!query && this.geolocation.lat && this.geolocation.lng && this.addresses.length) {
          this.place.address = this.addresses[0];
          this.$refs.search_address_field.focus();
        }
      }, () => {
        this.loadingAddresses = false;
      });
    },
    remove() {
      this.$emit('remove', this);
      this.$server.request2(`place/remove/${this.place.id}`, {}, (data) => {
        this.close();
        this.$eventBus.emit('place-updated');
      });
    },
    submit() {
      if (!this.place.address) this.addressError = 'Укажите адрес';
      if (this.checkData) {
        this.place.address = this.place.address.address;
        const url = this.place.id ? `place/update/${this.place.id}` : 'place/create';
        this.submitLoading = true;
        this.$server.request2(url, this.place, (data) => {
          this.submitLoading = false;
          this.$eventBus.emit('place-updated');
          this.close();
        }, () => {
          this.submitLoading = false;
        });
      }
    },
  },
  computed: {
    checkData() {
      return !!this.place.address;
    },
  },
  watch: {
    search(val) {
      this.addressError = '';
      this.searchPlaces(val);
    },
  }
};
</script>

<style scoped lang="scss">
.form {
  margin-bottom: 40px;
}

.place-list-item {
  padding: 10px 0;
  cursor: pointer;
}
</style>
