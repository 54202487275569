<template>
  <div class="candidate-folder" :class="{'red-bg': +candidate.not_suitable, 'blue-bg': +candidate.date_notify, 'green-bg': +candidate.date_work}">
    <span v-if="+candidate.not_suitable">Не подходит</span>
    <span v-else-if="+candidate.date_notify">На испыт. сроке</span>
    <span v-else-if="+candidate.date_work">Вышел на работу</span>
    <span v-else-if="candidate.vacancy && candidate.vacancy.funnel">{{ candidate.vacancy.funnel[+candidate.folder].title }}</span>
    <span v-else-if="funnel">{{ funnel[+candidate.folder].title }}</span>
  </div>
</template>

<script>
export default {
  name: 'CandidateFolder',
  props: ['candidate', 'funnel'],
};
</script>

<style scoped lang="scss">
.candidate-folder {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 8px;

  border-radius: 4px;
  background: var(--border-color);
  width: fit-content;
  max-width: 350px;
  overflow: hidden;
  span {
    white-space: nowrap;
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
    text-transform: uppercase;
    color: var(--page-color-main);
    text-overflow: ellipsis;
    text-align: center;
  }
  &.green-bg {
    background: #C3F7E8;
  }

  &.blue-bg {
    background: #D0EEFE;
  }

  &.red-bg {
    background: #FFE2E6;
  }
}
</style>
