<template>
  <Modal ref="modal" title="Выбрать адрес">
    <div class="d-flex justify-center align-center" style="height: 200px" v-if="loading">
      <v-progress-circular color="primary"/>
    </div>
    <div v-else class="places-list">
      <ul v-if="places.length > 0">
        <li v-for="place in places" :key="place.id">
          <label class="places-list-item">
            <div class="places-list-item_inner">
              <div class="places-list-item_title">{{ place.title }}</div>
              <div class="places-list-item_subtitle">{{ place.address }}</div>
            </div>
            <div class="places-list-item_after">
              <div class="check-wrap">
                <input
                  :type="multiple ? 'checkbox' : 'radio'"
                  name="places"
                  :checked="checkedPlaceIds.includes(place.id)"
                  @change="changePlace($event, place)"
                >
                <div class="icon icon-check"></div>
              </div>
            </div>
          </label>
        </li>
      </ul>
      <NoResults
        v-else
      >
        <template v-slot:title>Адресов пока нет</template>
      </NoResults>
    </div>
    <div class="modal-actions">
      <v-btn @click="submit" depressed :ripple="false" color="primary" :disabled="!checkedPlaceIds.length">
        {{checkedPlaceIds.length ? `Готово ( ${checkedPlaceIds.length} )` : 'Готово'}}
      </v-btn>
    </div>
  </Modal>
</template>

<script>
export default {
  name: 'SelectPlacesModal',
  props: {
    multiple: Boolean,
  },
  data() {
    return {
      places: [],
      checkedPlaceIds: [],
      loading: false,
    };
  },
  methods: {
    open() {
      this.$refs.modal.open();
      this.loadList();
    },
    close() {
      this.loading = false;
      this.places = [];
      this.checkedPlaceIds = [];
      this.$refs.modal.close();
    },
    submit() {
      if (this.checkedPlaceIds.length) {
        this.$emit('submit', this.multiple ? this.checkedPlaceIds : this.places.find((place) => place.id === this.checkedPlaceIds[0]));
        this.places = [];
        this.checkedPlaceIds = [];
        this.close();
      }
    },
    changePlace(event, place) {
      if (!this.multiple) this.checkedPlaceIds = [];
      if (event.target.checked) {
        this.checkedPlaceIds.push(place.id);
        if (!this.multiple) this.submit();
      } else {
        this.checkedPlaceIds = this.checkedPlaceIds.filter((id) => id !== place.id);
      }
    },
    loadList() {
      this.loading = true;
      return this.$server.request2('place/get', {}, (data) => {
        this.places = data.response;
        this.loading = false;
      }, ()=>{
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.places-list {
  min-height: 285px;
  margin-top: 18px;
  margin-bottom: 28px;

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  li {
    border-top: 1px solid var(--border-color);

    &:last-child {
      border-bottom: 1px solid var(--border-color);
    }
  }
}

.places-list-item {
  cursor: pointer;
  display: flex;

  &_inner {
    width: 100%;
    padding: 15px 0;

    & > * {
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &_title {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  &_subtitle {
    font-size: 14px;
    line-height: 16px;
    color: rgba(var(--page-color-main-rgb), .5);
  }

  &_after {
    min-width: 16px;
    align-self: stretch;
    display: flex;
    align-items: center;
  }

  .check-wrap {
    input {
      display: none;

      &:checked ~ .icon {
        display: block;
      }
    }

    .icon {
      display: none;
    }
  }
}
</style>
