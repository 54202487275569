export default {
  getItem: (state) => (id, section) => {
    const sectionArr = state.sections[section];
    return sectionArr.find((item) => String(item.id) === String(id))
  },
  getItems: (state) => (filter = () => true, section = '') => {
    if (section) return state.sections[section].filter(filter);
    const arr = [];
    Object.keys(state.sections).forEach((key) => {
      arr.push(...state.sections[key].filter(filter));
    });
    return arr;
  },

  getVacancy: (state) => (id) => {
    const vacancy = state.sections.vacancies.find((item) => Number(item.id) === Number(id));
    if (!vacancy) return undefined;
    const candidates = vacancy.candidates.map((candidateId) => {
      const section = state.sections.candidates;
      return section.find((candidate) => Number(candidateId) === Number(candidate.id));
    });
    return {
      ...vacancy,
      candidates,
    };
  },

  getVacancies: (state) => (filter) => {
    const vacancies = Array.from(state.sections.vacancies, (vacancy) => ({
      ...vacancy,
      candidates: Array.from(vacancy.candidates, (id) => state.sections.candidates.find((candidate) => Number(id) === Number(candidate.id))),
    }));

    if (typeof filter === 'function') return vacancies.filter(filter);
    return vacancies;
  },

  getCandidate: (state) => (id) => {
    const candidate = state.sections.candidates.find((c) => Number(c.id) === Number(id));
    if (!candidate) return undefined;
    return {
      ...candidate,
      vacancy: state.sections.vacancies.find((v) => Number(v.id) === Number(candidate.vacancy)),
    };
  },

  getCandidates: (state) => (filter) => {
    const candidates = Array.from(state.sections.candidates, (c) => ({
      ...c,
      vacancy: state.sections.vacancies.find((v) => Number(v.id) === Number(c.vacancy)),
    }));

    if (typeof filter === 'function') return candidates.filter(filter);
    return candidates;
  },

  getEvent: (state) => (id) => {
    const event = state.sections.events.find((e) => Number(e.id) === Number(id));
    if (!event) return undefined;
    return {
      ...event,
      vacancy: state.sections.vacancies.find((v) => Number(v.id) === Number(event.vacancy)),
      candidate: state.sections.candidates.find((c) => Number(c.id) === Number(event.candidate)),
    };
  },

  getEvents: (state) => (filter) => {
    const events = Array.from(state.sections.events, (e) => ({
      ...e,
      vacancy: state.sections.vacancies.find((v) => Number(v.id) === Number(e.vacancy)),
      candidate: state.sections.candidates.find((c) => Number(c.id) === Number(e.candidate)),
    }));

    if (typeof filter === 'function') return events.filter(filter);
    return events;
  },

  getResumeHtml: (state) => (id) => state.resumeHtml.find((r) => Number(r.id) === Number(id)),
};
