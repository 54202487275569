<template>
  <Modal ref="modal" title="Выбрать скрипт">
    <div class="d-flex justify-center align-center" style="height: 200px" v-if="loading">
      <v-progress-circular color="primary"/>
    </div>
    <div class="scripts-list" v-else>
      <ul v-if="scripts.length > 0">
        <li v-for="script in scripts" :key="script.id">
          <label class="scripts-list-item">
            <div class="scripts-list-item_inner">
              <div class="scripts-list-item_title">{{ script.title }}</div>
              <div class="scripts-list-item_subtitle">{{ script.address }}</div>
            </div>
            <div class="scripts-list-item_after">
              <div class="check-wrap">
                <input
                  :type="multiple ? 'checkbox' : 'radio'"
                  name="scripts"
                  :checked="checkedScriptIds.includes(script.id)"
                  @change="changeScript($event, script)"
                >
                <div class="icon icon-check"></div>
              </div>
            </div>
          </label>
        </li>
      </ul>
      <NoResults v-else>
        <template v-slot:title>Скриптов пока нет</template>
        <template v-slot:text>
          <v-btn depressed :ripple="false" color="primary" @click="$router.push('/scripts/script/new')">Создать</v-btn>
        </template>
      </NoResults>
    </div>
    <div class="modal-actions">
      <v-btn depressed :ripple="false" :disabled="!checkedScriptIds.length"
             @click="submit" color="primary">{{checkedScriptIds.length ? `Готово ( ${checkedScriptIds.length} )` : 'Готово'}}</v-btn>
    </div>
  </Modal>
</template>

<script>
export default {
  name: 'SelectScriptsModal',
  props: {
    multiple: Boolean,
  },
  data() {
    return {
      scripts: [],
      checkedScriptIds: [],
      loading: false,
    };
  },
  methods: {
    open() {
      this.loadList();
      this.$refs.modal.open();
    },
    close() {
      this.scripts = [];
      this.checkedScriptIds = [];
      this.loading = false;
      this.$refs.modal.close();
    },
    submit() {
      if (this.checkedScriptIds.length) {
        this.$emit('submit', this.multiple ? this.checkedScriptIds : this.scripts.find(((script) => script.id === this.checkedScriptIds[0])));
        this.scripts = [];
        this.checkedScriptIds = [];
        this.close();
      }
    },
    changeScript(event, script) {
      if (!this.multiple) this.checkedScriptIds = [];
      if (event.target.checked) {
        this.checkedScriptIds.push(script.id);
        if (!this.multiple) this.submit();
      } else {
        this.checkedScriptIds = this.checkedScriptIds.filter((id) => id !== script.id);
      }
    },
    loadList() {
      this.loading = true;
      this.$server.request2('form/get', {}, (data) => {
        this.loading = false;
        this.scripts = data.response;
      }, ()=>{
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.scripts-list {
  min-height: 285px;
  margin-top: 18px;
  margin-bottom: 28px;

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  li {
    border-top: 1px solid var(--border-color);

    &:last-child {
      border-bottom: 1px solid var(--border-color);
    }
  }
}

.scripts-list-item {
  cursor: pointer;
  display: flex;

  &_inner {
    width: 100%;
    padding: 15px 0;

    & > * {
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &_title {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  &_subtitle {
    font-size: 14px;
    line-height: 16px;
    color: rgba(var(--page-color-main-rgb), .5);
  }

  &_after {
    min-width: 16px;
    align-self: stretch;
    display: flex;
    align-items: center;
  }

  .check-wrap {
    input {
      display: none;

      &:checked ~ .icon {
        display: block;
      }
    }

    .icon {
      display: none;
    }
  }
}
</style>
