<template>
  <div class="main-header">
    <div class="main-header-inner">
      <div class="left" >
        <div class="burger-button" @click="$eventBus.emit('toggle-sidebar-visibility')" v-if="!isSharedView">
          <svg width="24" height="8" viewBox="0 0 24 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="6" width="24" height="2" fill="#111111"/>
            <rect width="24" height="2" fill="#111111"/>
          </svg>
        </div>
        <router-link to="/">
          <img src="@/images/logo.svg" class="logo">
        </router-link>
      </div>
      <div class="right" v-if="!isSharedView">
        <div class="toolbar">
          <v-menu
            max-height="calc(100% - 64px - var(--header-height))"
            offset-y ref="notifies-popover" min-width="300" @input="onOpenNotifyMenu" content-class="notify-list-menu">
            <template v-slot:activator="{ on, attrs }">
              <div class="toolbar-item notifications"
                   v-bind="attrs"
                   v-on="on">
                <div class="toolbar-item_count" v-if="notSeenNotifies.length">
                  <span>{{ notSeenNotifies.length }}</span>
                </div>
                <div class="icon icon-bell"></div>
              </div>
            </template>
            <NotifyList @notify-click="$refs['notifies-popover'].hide()"/>
          </v-menu>
          <!--          <div class="toolbar-item settings">
                      <div class="icon icon-settings"></div>
                    </div>-->
          <div class="toolbar-item logout pr-0" @click="$eventBus.emit('logout-confirm-open')">
            <div class="icon icon-import rotate-left"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NotifyList from '@/views/notifies/NotifyList.vue';

export default {
  name: 'MainHeader',
  components: { NotifyList },
  data() {
    return {
      isSharedView: false,
    }
  },
  created() {
    this.isSharedView = window.location.href.includes('shr');
  },
  methods: {
    onOpenNotifyMenu(opened) {
      if (opened) {
        setTimeout(()=>this.seen(), 1000);
      } else {
        clearTimeout();
      }
    },
    seen() {
      if (this.notSeenNotifies.length) {
        this.notSeenNotifies.forEach(notify=>{
          this.$server.request2('user/seenNotify', {id: notify.id}, ()=>{

          });
        })
      }
    }
  },
  computed: {
    notSeenNotifies() {
      return this.$store.state.notifies.filter(({ seen }) => !seen);
    },
  },
};
</script>
<style scoped lang="scss">
.main-header {
  width: 100%;
  height: var(--header-height);
  padding: 0 40px 0 var(--page-offset-left);
  box-shadow: 2px 8px 30px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 2;
}

.main-header-inner {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1120px;
  .left {
    display: flex;
    .burger-button {
      margin-right: 28px;
      cursor: pointer;
      display: none;
      @media (max-width: 1024px) {
        display: block;
      }
    }
  }
}

.toolbar {
  display: flex;
}

.toolbar-item {
  position: relative;
  padding: 9px;
  cursor: pointer;

  &_count {
    right: 9px;
    top: 6px;
    position: absolute;
    width: 14px;
    height: 14px;
    font-weight: 600;
    font-size: 8px;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFEF2;
    background: #FF4050;
    border-radius: 50%;
  }

  .icon {
    font-size: 28px;
    color: rgba(0, 0, 0, .25);
  }
}
</style>
