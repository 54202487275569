<template>
  <div class="app-main-container">

    <MainSidebar v-if="!isSharedLink && user.id"/>
    <div class="view-content" :style="isSharedLink ? 'width: 100%;' : ''">
      <MainHeader v-if="user.id"/>
      <router-view v-slot="{ Component }">
        <keep-alive include="Vacancies,Vacancy">
          <component :is="Component"/>
        </keep-alive>
      </router-view>
    </div>
    <CreateVacancyModal ref="create-vacancy-modal"/>
    <CreateEventModal ref="create-event-modal"/>
    <NotMainEventModal ref="not-main-event-modal"/>
    <AddCandidatesModal
      ref="add-candidates-modal"
      v-on="addCandidatesModal.on"
      v-bind="addCandidatesModal.props"
    />
    <SelectVacanciesModal ref="select-vacancies-modal"/>
    <CandidateNoteModal ref="candidate-note-modal"/>
    <ChangeFolderCandidatesModal ref="change-folder-modal"/>
    <CloseVacancyModal ref="close-vacancy-modal"/>
    <ReceiptFeeModal ref="receipt-fee-modal"/>
    <PlaceModal ref="place-modal"/>
    <AddCardModal
      ref="add-card-modal"
      v-on="addCardModal.on"
      v-bind="addCardModal.props"
    />
    <SupportModal ref="support-modal"/>
    <Confirm ref="logout-confirm" title="Хотите выйти?" @accept="$server.logout()"/>
    <ShareModal ref="share_modal"/>
    <ModalWithFields ref="edit-user-modal"
                     :fields="modalFields" @submit="updateUser" title="Укажите свое имя"
                     subtitle="Заказчик будет знать, кто ему отправил"
    />
    <Confirm ref="confirm_modal"/>
    <ContactsModal ref="candidate_contacts_modal"/>
    <LoaderModal ref="loader_modal"/>
    <EditResumeModal ref="edit_resume_modal"/>
    <ConflictVacancyModal ref="conflict_vacancy_modal"/>

  </div>
</template>

<script>
import LoaderModal from '@/components/LoaderModal';
import MainSidebar from '@/components/MainSidebar.vue';
import MainHeader from '@/components/MainHeader.vue';
import CreateVacancyModal from '@/views/vacancies/CreateVacancyModal.vue';
import CreateEventModal from '@/views/event/create/CreateEventModal.vue';
import NotMainEventModal from '@/views/event/NotMainEventModal.vue';
import AddCandidatesModal from '@/views/candidates/AddCandidatesModal.vue';
import CandidateNoteModal from '@/views/candidate/CandidateNoteModal.vue';
import ChangeFolderCandidatesModal from '@/views/vacancy/ChangeFolderCandidatesModal.vue';
import CloseVacancyModal from '@/views/vacancy/close/CloseVacancyModal.vue';
import ReceiptFeeModal from '@/views/vacancy/ReceiptFeeModal.vue';
import SelectVacanciesModal from '@/views/vacancies/SelectVacanciesModal.vue';
import PlaceModal from '@/views/places/PlaceModal.vue';
import SupportModal from '@/views/profile/SupportModal.vue';
import AddCardModal from '@/views/payment/AddCardModal.vue';
import ShareModal from '@/views/candidates/ShareModal.vue';
import Confirm from '@/components/Confirm';
import ShareVacancy from "@/views/vacancy/ShareModal";
import ContactsModal from '@/views/candidate/ContactsModal';
import EditResumeModal from '@/views/candidate/EditResumeModal';
import ConflictVacancyModal from '@/views/vacancy/ConflictVacancyModal';
export default {
  name: 'ViewTemplate',
  components: {
    Confirm,
    AddCardModal,
    SupportModal,
    PlaceModal,
    SelectVacanciesModal,
    ReceiptFeeModal,
    CloseVacancyModal,
    ChangeFolderCandidatesModal,
    CandidateNoteModal,
    AddCandidatesModal,
    NotMainEventModal,
    CreateEventModal,
    CreateVacancyModal,
    MainSidebar,
    MainHeader,
    ShareModal,
    ShareVacancy,
    ContactsModal,
    LoaderModal,
    EditResumeModal,
    ConflictVacancyModal
  },
  data() {
    return {
      isSharedLink: window.location.href.split('/').includes('shr'),
      idCurrentEditedEvent: null,
      idNotMainEvent: null,
      addCandidatesModal: {
        on: {},
        props: {},
      },
      selectVacanciesModal: {
        on: {},
        props: {},
      },
      candidateNoteModal: {
        on: {},
      },
      closeVacancyModal: {
        on: {},
        props: {},
      },
      placeModal: {
        on: {},
        props: {},
      },
      addCardModal: {
        on: {},
        props: {},
      },
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    modalFields() {
      return [
        {
          title: 'Имя',
          name: 'name',
          placeholder: 'Не указано',
          value: this.user.name,
        },
        {
          title: 'Фамилия',
          name: 'surname',
          placeholder: 'Не указана',
          value: this.user.surname,
        },
      ];
    },
  },
  methods: {
    updateUser(data) {
      this.$server.request('user/update', data)
        .then(({ response }) => this.$store.commit('setUserData', {
          ...this.user,
          ...response,
        }));
    },
  },
  created() {
    this.$store.dispatch('subscribeToNotifications')
      .then((stop) => {
        this.stopSubscribe = stop;
      });
  },
  mounted() {
    this.$eventBus.on('create-vacancy-modal-open', () => {
      this.$refs['create-vacancy-modal'].open();
    });

    this.$eventBus.on('create-event-modal-open', (params = {}) => {
      this.$refs['create-event-modal'].open(params);
    });

    this.$eventBus.on('not-main-event-modal-open', (params) => {
      this.$refs['not-main-event-modal'].open(params);
    });

    this.$eventBus.on('add-candidates-modal-open', (data) => {
      this.$refs['add-candidates-modal'].open(data);
    });

    this.$eventBus.on('select-vacancies-modal-open', (data) => {
      this.$refs['select-vacancies-modal'].open(data);
    });

    this.$eventBus.on('candidate-note-modal-open', (data) => {
      this.$refs['candidate-note-modal'].open(data);
    });

    this.$eventBus.on('change-folder-candidates-modal-open', (data) => {
      this.$refs['change-folder-modal'].open(data);
    });

    this.$eventBus.on('close-vacancy-modal-open', (data) => {
      this.$refs['close-vacancy-modal'].open(data);
    });

    this.$eventBus.on('receipt-fee-modal-open', (params) => {
      this.$refs['receipt-fee-modal'].open(params);
    });

    this.$eventBus.on('place-modal-open', (params = {}) => this.$refs['place-modal'].open(params));
    this.$eventBus.on('add-card-modal-open', ({
      on = {},
      props = {},
    } = {}) => {
      this.addCardModal.on = on;
      this.addCardModal.props = props;
      this.$nextTick(() => {
        this.$refs['add-card-modal'].open();
      });
    });

    this.$eventBus.on('logout-confirm-open', () => this.$refs['logout-confirm'].open());
    this.$eventBus.on('call-support-modal', () => this.$refs['support-modal'].open());
    this.$eventBus.on('open-share-candidate-modal', (token) => this.$refs.share_modal.open(token));
    this.$eventBus.on('open-edit-user-modal', () => this.$refs['edit-user-modal'].open());
    this.$eventBus.on('candidate-contacts-modal-open', (candidate_id, phone, isNotify)=>{
      this.$refs.candidate_contacts_modal.open(candidate_id, phone, isNotify);
    });
    this.$eventBus.on('open-loader-modal', ()=>{
      this.$refs.loader_modal.open();
    });
    this.$eventBus.on('close-loader-modal', ()=>{
      this.$refs.loader_modal.close();
    });
    this.$eventBus.on('open-edit-resume-modal', (params)=>{
      this.$refs.edit_resume_modal.open(params);
    });
    this.$eventBus.on('open-conflict-modal', (params)=>{
      this.$refs.conflict_vacancy_modal.open(params);
    })
  },
};
</script>

<style scoped lang="scss">
.app-main-container {
  display: flex;
}
</style>
