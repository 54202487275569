<template>
  <div class="page half-width dashboard-page" style="flex-direction: row;overflow: auto;">
    <div class="page-content dashboard-page-content" style="overflow: visible;width: auto">
      <DashboardBlock title="События" :to="{name: 'Calendar'}">
        <template #head-right>
          <div class="dashboard-tab-links">
            <div
              class="dashboard-tab-link"
              :class="{'active' : activeEventsTab === 'yesterday'}"
              @click="activeEventsTab = 'yesterday'"
            >
              Вчера
            </div>
            <div
              class="dashboard-tab-link"
              :class="{'active' : activeEventsTab === 'today'}"
              @click="activeEventsTab = 'today'"
            >
              Сегодня
            </div>
            <div
              class="dashboard-tab-link"
              :class="{'active' : activeEventsTab === 'tomorrow'}"
              @click="activeEventsTab = 'tomorrow'"
            >
              Завтра
            </div>
          </div>
        </template>
        <template #content v-if="loadingEvents">
          <Loader height="200"/>
        </template>
        <template #content v-else>
          <div class="dashboard-tabs">
            <div class="dashboard-tab" :class="{'active' : activeEventsTab === 'yesterday'}">
              <EventList
                :groupByDates="false"
                :events="events.yesterday"
                v-if="events.yesterday.length"
              />
              <NoResults v-else>
                <template #title>Вчера</template>
                <template #text>
                  За вчера событий нет
                </template>
              </NoResults>
            </div>
          </div>
          <div class="dashboard-tabs">
            <div class="dashboard-tab" :class="{'active' : activeEventsTab === 'today'}">
              <EventList
                :groupByDates="false"
                :events="events.today"
                v-if="events.today.length"
              />
              <NoResults v-else>
                <template #title>Сегодня</template>
                <template #text>
                  Сегодня событий нет
                </template>
              </NoResults>
            </div>
          </div>
          <div class="dashboard-tabs">
            <div class="dashboard-tab" :class="{'active' : activeEventsTab === 'tomorrow'}">
              <EventList
                :groupByDates="false"
                :events="events.tomorrow"
                v-if="events.tomorrow.length"
              />
              <NoResults v-else>
                <template #title>Завтра</template>
                <template #text>
                  На завтра событий нет
                </template>
              </NoResults>
            </div>
          </div>
        </template>
      </DashboardBlock>
      <DashboardBlock title="Последние кандидаты" :to="{name: 'Candidates'}" classes="dashboard-block-candidates">
        <template #content v-if="loadingCandidate">
          <Loader height="200"/>
        </template>
        <template #content v-else>
          <div class="dashboard-list" v-if="candidates.length">
            <ul>
              <li v-for="candidate in candidates" :key="candidate.id">
                <DashboardCandidateItem :candidate="candidate"/>
              </li>
            </ul>
          </div>
          <NoResults v-else>
            <template #title>Не найдено</template>
            <template #text>
              Кандидатов ещё нет
            </template>
          </NoResults>
        </template>
      </DashboardBlock>
      <DashboardBlock title="Последние вакансии" :to="{name: 'Vacancies'}" classes="dashboard-block-vacancies">
        <template #content v-if="loadingVacancies">
          <Loader height="200"/>
        </template>
        <template #content v-else>
          <div class="dashboard-list" v-if="vacancies.length">
            <ul>
              <li v-for="vacancy in vacancies" :key="vacancy.id">
                <DashboardVacancyItem :vacancy="vacancy"/>
              </li>
            </ul>
          </div>
          <NoResults v-else>
            <template #title>Не найдено</template>
            <template #text>
              Вакансий ещё нет
            </template>
          </NoResults>
        </template>
      </DashboardBlock>
      <DashboardBlock
        :title="`Аналитика за
          ${$moment(analyticsDates[analyticsMonthIndex]).locale('ru').format('MMMM YYYY')}
        `"
        :to="{name: 'Analytics'}"
      >
        <template #content>
          <FinanceHead :value="analytics.earned[0]"/>
          <AnalyticsGrid
            :analytics="analytics"
            :monthIndex="analyticsMonthIndex"
            :dates="analyticsDates"
            @update:monthIndex="updateMonthIndex"
            :loading="loadingAnalytics"
          />
        </template>
      </DashboardBlock>
    </div>
    <div class="page-right-content dashboard-actions-content">
      <div class="dashboard-actions">
        <div class="dashboard-actions_title">Действия</div>
        <div class="dashboard-actions_items">
          <div
            class="dashboard-actions_item"
            @click="$eventBus.emit('create-vacancy-modal-open')"
          >
            <div class="dashboard-actions_item_text">Новая вакансия</div>
            <div class="dashboard-actions_item_media">
              <img
                class="dashboard-actions_item_media_icon"
                src="@/images/action-icons/vacancy.svg"
                alt="vacancy icon"
              >
            </div>
          </div>
          <div
            class="dashboard-actions_item"
            @click="$router.push({name: 'candidatesCreate'})"
          >
            <div class="dashboard-actions_item_text">Новый кандидат</div>
            <div class="dashboard-actions_item_media">
              <img
                class="dashboard-actions_item_media_icon"
                src="@/images/action-icons/candidate.svg"
                alt="candidate icon"
              >
            </div>
          </div>
          <div
            class="dashboard-actions_item"
            @click="$eventBus.emit('create-event-modal-open', {type: '0'})"
          >
            <div class="dashboard-actions_item_text">Создать собеседование</div>
            <div class="dashboard-actions_item_media">
              <img
                class="dashboard-actions_item_media_icon"
                src="@/images/action-icons/interview.svg"
                alt="interview icon"
              >
            </div>
          </div>
          <div
            class="dashboard-actions_item"
            @click="$eventBus.emit('create-event-modal-open', {type: '2'})"
          >
            <div class="dashboard-actions_item_text">Создать напоминание</div>
            <div class="dashboard-actions_item_media">
              <img
                class="dashboard-actions_item_media_icon"
                src="@/images/action-icons/notify.svg"
                alt="notify icon"
              >
            </div>
          </div>
          <div
            class="dashboard-actions_item"
            @click="$router.push({name: 'Script', params: {scriptId: 'new'}})"
          >
            <div class="dashboard-actions_item_text">Новый скрипт собеседования</div>
            <div class="dashboard-actions_item_media">
              <img
                class="dashboard-actions_item_media_icon"
                src="@/images/action-icons/script.svg"
                alt="script icon"
              >
            </div>
          </div>
          <div
            class="dashboard-actions_item"
            @click="$eventBus.emit('place-modal-open')"
          >
            <div class="dashboard-actions_item_text">Новый адрес</div>
            <div class="dashboard-actions_item_media">
              <img
                class="dashboard-actions_item_media_icon"
                src="@/images/action-icons/place.svg"
                alt="place icon"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardBlock from '@/views/dashboard/DashboardBlock.vue';
import EventList from '@/views/event/EventList.vue';
import DashboardCandidateItem from '@/views/dashboard/DashboardCandidateItem.vue';
import DashboardVacancyItem from '@/views/dashboard/DashboardVacancyItem.vue';
import AnalyticsGrid from '@/views/analytics/AnalyticsGrid.vue';
import FinanceHead from '@/views/finance/FinanceHead.vue';

export default {
  name: 'Dashboard',
  components: {
    FinanceHead,
    AnalyticsGrid,
    DashboardVacancyItem,
    DashboardCandidateItem,
    EventList,
    DashboardBlock,
  },
  data() {
    return {
      activeEventsTab: 'today',
      events: {
        today: [],
        yesterday: [],
        tomorrow: [],
      },
      candidates: [],
      vacancies: [],
      analytics: {
        average_cost: [],
        average_cycle_time: [],
        average_funnel: [],
        average_interview: [],
        earned: [],
        interviews: [],
        new_candidates: [],
      },
      today: new Date(),
      analyticsMonthIndex: 0,
      loadingEvents: false,
      loadingCandidate: false,
      loadingVacancies: false,
      loadingAnalytics: false,
    };
  },
  methods: {
    loadEventsForDays() {
      const today = this.$moment()
        .format('YYYY-MM-DD');
      const yesterday = this.$moment(today)
        .add(-1, 'days')
        .format('YYYY-MM-DD');
      const tomorrow = this.$moment(today)
        .add(1, 'days')
        .format('YYYY-MM-DD');
      this.loadingEvents = true;
      const eventRequest = (date, day)=> this.$server.request2('event/get', {
        date_from: date,
        date_to: date,
        status: [0, 1],
        type: [0, 1, 2],
        limit: 4,
      }, (data)=>{
        this.loadingEvents = false;
        this.events[day] = data.response;
      }, ()=>{
        this.loadingEvents = false;
      });
      eventRequest(yesterday, 'yesterday');
      eventRequest(today, 'today');
      eventRequest(tomorrow, 'tomorrow');
    },
    loadLastCandidates() {
      this.loadingCandidate = true;
      this.$server.request2('candidate/get', {
        order: 'desc',
        limit: 3
      }, (data) => {
        this.loadingCandidate = false;
        this.candidates = data.response;
      }, ()=>{
        this.loadingCandidate = false;
      });
    },
    loadLastVacancies() {
      this.loadingVacancies = true;
      this.$server.request2('vacancy/get', {order: 'desc', limit: 3}, (data)=>{
        this.vacancies = data.response;
        this.loadingVacancies = false;
      }, ()=>{
        this.loadingVacancies = false;
      });
    },
    loadAnalytics() {
      const date = this.$moment().format('YYYY-MM');
      this.loadingAnalytics = true;
      return this.$server.request2('user/analytics', { date }, (data)=>{
        this.loadingAnalytics = false;
        this.analytics = data.response;
      }, ()=>{
        this.loadingAnalytics = false;
      });
    },
    updateMonthIndex(value) {
      this.analyticsMonthIndex = value;
    },
  },
  mounted() {
    this.loadLastCandidates();
    this.loadEventsForDays();
    this.loadAnalytics();
    this.loadLastVacancies();
  },
  computed: {
    analyticsDates() {
      const arr = new Array(24).fill(null);
      return arr.map((_, index) => this.$moment(this.today)
        .date(1)
        .add(-index, 'months')
        .format('YYYY-MM-DD'));
    },
  },
  created() {
    this.$eventBus.on('event-created, event-remove, event-update', this.loadEventsForDays);
    this.$eventBus.on('candidates-create, candidate-update, candidate-remove', this.loadLastCandidates);
    this.$eventBus.on('vacancy-create, vacancy-update, vacancy-remove', this.loadLastVacancies);
  },
  beforeDestroy() {
    this.$eventBus.off('event-created, event-remove, event-update');
    this.$eventBus.off('candidates-create, candidate-update, candidate-remove');
    this.$eventBus.off('vacancy-create, vacancy-update, vacancy-remove');
  },
};
</script>
<style scoped lang="scss">
.page-content {
  .dashboard-block {
    margin-bottom: 64px;

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 40px;
    }
  }
}

.dashboard-tab-links {
  display: flex;
  justify-content: space-between;

  & > * {
    margin-right: 18px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.dashboard-tab-link {
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;;
  color: rgba(var(--page-color-main-rgb), .3);

  &.active {
    color: var(--page-color-main);
  }
}

.dashboard-tab {
  display: none;

  &.active {
    display: block;
  }

  .no-results-block {
    position: relative;
    margin-top: 60px;
    margin-bottom: 0;

    &::v-deep(.no-results-block_line) {
      margin-top: -40px;
      margin-bottom: -40px;
    }
  }
}

.dashboard-list {
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  li {
    border-top: 1px solid var(--border-color);

    &:last-child {
      border-bottom: 1px solid var(--border-color);
    }
  }
}

.finance-head-block {
  margin-top: 28px;
  margin-bottom: 18px;
  background: #FFFFFF;
  box-shadow: 2px 8px 30px rgba(0, 0, 0, 0.2);

  &::v-deep(.finance-head-block_media) {
    background: #DCF9F1;
  }
}

.analytics-grid {
  grid-template-columns: 280px 280px;
}

.page-right-content {
  right: 40px;
}
.dashboard-actions-content {
  justify-content: flex-end;
  position: absolute;
}
.dashboard-actions {
  width: 286px;
  &_title {
    margin-bottom: 24px;
    font-size: 22px;
    line-height: 32px;
    font-weight: bold;
    letter-spacing: -0.5px;
  }

  &_items {
    display: flex;
    flex-direction: column;

    & > * {
      margin-bottom: 18px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &_item {
    cursor: pointer;
    padding: 12px 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 2px 8px 30px rgba(0, 0, 0, 0.2);
    border-radius: 10px;

    &_text {
      font-size: 14px;
      line-height: 18px;
    }

    &_media {
      flex-shrink: 0;
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: var(--border-color);

      &_icon {
        width: 24px;
        height: 24px;
      }
    }
  }
}

@media screen and (max-width: 1160px) {
  .dashboard-page-content {
    padding-left: 40px;
    padding-right: 40px;
  }
}
</style>
