import Vue from 'vue';
import Vuex from 'vuex';
import staticData from '@/staticData';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import calendarModule from './modules/calendar';
import candidatesModule from './modules/candidates';
import vacanciesModule from './modules/vacancies';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    sections: {
      vacancies: [],
      candidates: [],
      events: [],
    },
    resumeHtml: [],
    notifies: [],
    places: [],
    scripts: [],
    cards: [],
    static: staticData,
    user: {},
  },
  getters,
  mutations,
  actions,
  modules: {
    calendarModule,
    candidatesModule,
    vacanciesModule,
  },
});

export default store;
