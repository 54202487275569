<template>
  <Modal ref="modal">
    <div class="completion-modal after-submit-modal" :class="afterSubmitModalParams.class">
      <img
        src="@/images/pictures/picture-2.svg"
        alt="vacancy closed picture"
        class="completion-modal_icon-1"
      >
      <img
        v-if="afterSubmitModalParams.icon"
        :src="afterSubmitModalParams.icon"
        alt="vacancy closed picture"
        class="completion-modal_icon-2"
      >
      <div class="completion-modal_check">
        <div class="icon icon-check"></div>
      </div>
      <NoResults>
        <template #title>{{ afterSubmitModalParams.title }}</template>
        <template #text>{{ afterSubmitModalParams.text }}</template>
      </NoResults>
      <div
        v-if="!candidate && openVacancy"
        class="transparent-button button-to-vacancy"
        @click="toVacancy"
      >
        Перейти к вакансии
      </div>
    </div>
    <template v-slot:actions>
      <div class="default-button" @click="close()">Готово</div>
    </template>
  </Modal>
</template>

<script>
export default {
  name: 'AfterChangingFeeModal',
  props: ['afterSubmitModalParams', 'candidate', 'openVacancy'],
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    toVacancy() {
      this.$router.push(`/vacancies/vacancy/${this.vacancyId}/`);
      this.close();
    },
  }
};
</script>

<style scoped>

</style>