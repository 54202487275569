import functions from '@/tests/functions';

function openTab(index, page) {
  functions.querySelector(`.tab-link:nth-child(${index + 1})`, page)
    .click();
}

async function openFirstVacancy() {
  await functions.wait(200);
  functions.openSection(1);
  await functions.wait(200);
  let page = functions.querySelector('.vacancies-page');
  let row = functions.querySelector('.v-data-table__wrapper table tbody tr:first-child', page);
  row.click();
  await functions.wait(500);
}

let vacancy = {};
export default {
  async createModal() {
    // return;
    functions.openSection(1);
    await functions.wait(300);
    functions.querySelector('.vacancies-page .page-header-button')
      .click();
    await functions.wait(300);

    vacancy = await functions.createVacancy();

    if (location.pathname.split('/')
      .slice(0, 3)
      .join('/') !== '/vacancies/vacancy') {
      throw 'Не произошло перехода на страницу вакансии';
    }
    if (functions.querySelector('.page-vacancy .page-title')
      .innerText
      .trim() !== data[0].trim()) {
      throw 'Названия вакансий не совпадает';
    }
  },
  async vacancyPageFunnelTab() {
    await openFirstVacancy();
    let vacancyPage = functions.querySelector('.page-vacancy');
    functions.querySelector('.v-tab:nth-child(1)', vacancyPage)
      .click();
    await functions.wait(500);
    functions.querySelector('.vacancy-folders_actions button:nth-child(1)', vacancyPage)
      .click();
    await functions.wait(100);
    let steps = vacancyPage.querySelectorAll('.vacancy-folders_list .vacancy-folders_item_inner');
    if (steps.length !== 3) throw 'Количество этапов воронки не 3';
    functions.fillTextInput(functions.querySelector('.vacancy-folders_item_title input', steps[0]), functions.genLongText(5), false);
    await functions.wait(100);
    functions.querySelector('.hidden-checkbox-wrap .v-input__slot', steps[1])
      .click();
    await functions.wait(100);
    functions.querySelector('.vacancy-folders_item_edit .v-icon', steps[2])
      .click();
    await functions.wait(300);
    functions.confirm();
    await functions.wait(100);
    functions.querySelector('.vacancy-folders_actions button:nth-child(3)', vacancyPage)
      .click();
    await functions.wait(300);
    let modal = functions.getModal();
    let form = functions.querySelector('.edit-folder-form', modal);
    await functions.fillForm(form, [functions.genLongText(5), 1]);
    functions.querySelector('button.primary', modal)
      .click();
    await functions.wait(600);
    functions.querySelector('.vacancy-folders_actions button:nth-child(2)', vacancyPage)
      .click();
  },
  async vacancyPageEditTab() {
    functions.openSection(1);
    await functions.wait(300);
    let page = functions.querySelector('.vacancies-page');
    let row = functions.querySelector('.v-data-table__wrapper table tbody tr:first-child', page);
    row.click();
    await functions.wait(500);
    let vacancyPage = functions.querySelector('.page-vacancy');
    functions.querySelector('.v-tab:nth-child(3)', vacancyPage)
      .click();
    await functions.wait(500);
    let listItems = vacancyPage.querySelectorAll('.about-list li');//.about-list_item_value
    if (!listItems.length) throw 'Список с параметрами не найден в редактировании вакансии';
    // for (let i = 0; i < listItems.length; ++i) {
    //   let listItem = listItems[i];
    //   functions.querySelector('.about-list_item_after', listItem)
    //     .click();
    //   await functions.wait(200);
    //   let modal = functions.getModal();
    //   let fieldName = functions.querySelector('.about-list_item_title', listItem)
    //     .innerText
    //     .trim();
    //   if (!modal) throw `Модалка редактирования поля "${fieldName}" не открылась`;
    //   let modalTitle = functions.querySelector('.form-field_title', modal)
    //     .innerText
    //     .trim();
    //   if (fieldName !== modalTitle) throw `Название модалки и поля не совпадают (${fieldName}!=${modalTitle})`;
    //   let inputs = modal.querySelectorAll('input');
    // }
    // return;
    for (let i = 0; i < listItems.length; ++i) {
      let listItem = listItems[i];
      let value = functions.querySelector('.about-list_item_value, .about-list_item_textarea', listItem, false);
      if (!value) throw 'Не получилось взять значение в строке редактирования';
      let innerText = value.innerText.trim();
      let text = functions.digitsOnly(innerText, '-')
        .split('-');
      let text2 = text[1];
      text = text[0];
      functions.querySelector('.about-list_item_after', listItem)
        .click();
      await functions.wait(300);
      let modal = functions.getModal();
      let fieldName = functions.querySelector('.about-list_item_title', listItem)
        .innerText
        .trim();
      if (!modal) throw `Модалка редактирования поля "${fieldName}" не открылась`;
      let modalTitle = functions.querySelector('.form-field_title', modal)
        .innerText
        .trim();
      if (modalTitle.indexOf(modalTitle) !== 0) throw `Название поля в модалке неверное ("${modalTitle}" начинается не с "${fieldName}")`;
      let inputs = modal.querySelectorAll('input, select, textarea');
      if (inputs.length === 0) {
        throw 'В модалке нет инпутов';
      } else if (inputs.length === 2 && !text2) {
        throw 'В модалке 2 инпута, а должен быть один (второго значения нет)';
      } else if (inputs.length > 2) throw 'В модалке больше 2-х инпутов';
      let inputValues = [inputs[0].value.trim(), inputs[1] ? inputs[1].value.trim() : ''];
      let compare = [
        [[innerText, inputValues[0]], [innerText, vacancy.title]],
        [[text, inputValues[0].split(' ')
          .join('')], [text, vacancy.fee]],
        [[text, inputValues[0]], [text, vacancy.experience]],
        [[text, inputValues[0].split(' ')
          .join('')], [text2, inputValues[1].split(' ')
          .join('')], [text, vacancy.salary_from], [text2, vacancy.salary_to]],
        [['2', inputValues[0]], [innerText, 'Женский']],
        [[text, inputValues[0]], [text, vacancy.age_from], [text2, vacancy.age_to]],
        [[innerText, inputValues[0]], [innerText, vacancy.additional]],
        [[innerText, inputValues[0]], [innerText, vacancy.date_from.split('-')
          .reverse()
          .join('.')]],
        [[innerText, inputValues[0]], [innerText, vacancy.date_to.split('-')
          .reverse()
          .join('.')]],
      ][i];
      console.log({
        compare,
        inputs
      });
      for (let j in compare) {
        if (compare[j][0] !== compare[j][1]) throw `Поле ${fieldName}, "${compare[j][0]}" не равно "${compare[j][1]} (сравнение №${j})"`;
      }
      let values = [];
      for (let j = 0; j < inputs.length; ++j) {
        let input = inputs[j];
        console.log({ input });
        let dateInput = input.closest('.datepicker-field');
        let value = input.value + '1';
        if (dateInput) {
          value = functions.date(24 * 3600 * (1 + j + i));
          await functions.fillDateInput(dateInput, value);
          value = value.split('-')
            .reverse()
            .join('');
          // return;
        } else if (input.tagName === 'SELECT') {
          value = functions.fillSelectPlain(input, 1);
        } else {
          functions.fillTextInput(input, value, false);
          await functions.wait(100);
          value = input.value;
        }
        values.push(value);
      }
      value = values.join(' - ');
      functions.querySelector('.default-button')
        .click();
      await functions.wait(300);
      let savedValue = functions.querySelector('.about-list_item_value, .about-list_item_textarea', listItem)
        .innerText
        .trim();
      console.log({ savedValue });
      if (!isNaN(+savedValue[0])) {
        savedValue = functions.digitsOnly(savedValue, '- ')
          .trim();
      }
      if (savedValue !== value) throw `Было изменено поле ${fieldName} на "${value}", но после сохранения оно равно "${savedValue}"`;
      await functions.wait(100);
    }
  },
  async vacancyPageEventsTab() {
    await openFirstVacancy();
    let vacancyPage = functions.querySelector('.page-vacancy');
    functions.querySelector('.v-tab:nth-child(2)', vacancyPage)
      .click();
    await functions.wait(500);
    let createInterviewButton = functions.querySelector('.vacancy-events_header button:nth-child(1)', vacancyPage);
    createInterviewButton.click();
    await functions.wait(300);
    let eventData = await functions.fillEventModal();
    let eventElement = functions.querySelector('.event-list_section .event-list-item', vacancyPage);
    if (!eventElement.innerText.includes(eventData.candidate)) throw `Неверное имя кандидата в созданном ивенте: в тексте "${eventElement.innerText}" нет "${eventData.candidate}"`;
    functions.querySelector('button.v-btn button', eventElement)
      .click();
    await functions.wait(200);
    functions.querySelector('button.red_color--text', functions.currentMenu())
      .click();
    await functions.wait(300);
    if (document.contains(eventElement)) throw 'Ивент не удалился';

    let createNotifyButton = functions.querySelector('.vacancy-events_header button:nth-child(2)', vacancyPage);
    createNotifyButton.click();
    await functions.wait(500);

    let notifyData = await functions.fillNotifyModal();

    functions.querySelector('.modal-actions button', functions.getModal())
      .click();
    let notifyElement = functions.querySelector('.event-list_section .event-list-item', vacancyPage);
    if (!notifyElement.innerText.includes(notifyData.candidate)) throw `Неверное имя кандидата в созданном ивенте: в тексте "${notifyElement.innerText}" нет "${notifyData.candidate}"`;
    if (!notifyElement.innerText.includes(notifyData.comment)) throw `Неверный коммент в созданном ивенте: в тексте "${notifyElement.innerText}" нет "${notifyData.comment}"`;
    functions.querySelector('button.v-btn button', notifyElement)
      .click();
    await functions.wait(200);
    functions.querySelector('button.red_color--text', functions.currentMenu())
      .click();
    await functions.wait(300);
    if (document.contains(notifyElement)) throw 'Ивент не удалился';
  },
  async table() {
    // return;
    functions.openSection(1);
    await functions.wait(200);
    let page = functions.querySelector('.vacancies-page');
    let row = functions.querySelector('.v-data-table__wrapper table tbody tr:first-child', page);
    if (!row) throw 'Строки в таблице не найдены';
    let firstVacancyTitle = functions.querySelector('.vacancy-title', row)
      .innerText
      .trim();
    if (!firstVacancyTitle) throw 'Пустое название вакансии в первой строке таблицы';
    functions.fillTextInput(functions.querySelector('input[type="text"]', page), 'Тестовая вакансия 2023-01-07T20:49:46', false);
    await functions.wait(300);
    if (page.querySelectorAll('.tab:first-child .v-data-table__wrapper table tbody tr').length !== 1) throw 'Неверно работает фильтр, в таблице кол-во строк!=1';
    functions.fillTextInput(functions.querySelector('input[type="text"]', page), '', false);
    await functions.wait(300);
    openTab(1, page);
    await functions.wait(600);
    let rowArchive = functions.querySelector('.tab:nth-child(2) .v-data-table__wrapper table tbody tr:first-child', page);
    if (!rowArchive) throw 'Строки в архиве не найдены';
    let firstVacancyTitleArchive = functions.querySelector('.vacancy-title', rowArchive)
      .innerText
      .trim();
    if (!firstVacancyTitleArchive) throw 'Пустое название вакансии в первой строке архива';
    if (firstVacancyTitle === firstVacancyTitleArchive) throw `Не переключилась вкладка Архив ("${firstVacancyTitle}"="${firstVacancyTitleArchive}")`;
    openTab(0, page);
    await functions.wait(300);
    row = functions.querySelector('.v-data-table__wrapper table tbody tr:first-child', page);
    functions.querySelector('td:last-child button button', row)
      .click();
    await functions.wait(300);
    if (1) { // пока выключил тест на удаление вакансии
      functions.querySelector('.v-list-item:nth-child(1) button', functions.currentMenu())
        .click();
      await functions.wait(300);
      await functions.confirm();
      await functions.wait(300);
      row = functions.querySelector('.v-data-table__wrapper table tbody tr:first-child', page);
      let firstVacancyTitle2 = functions.querySelector('.vacancy-title', row)
        .innerText
        .trim();
      if (firstVacancyTitle2 === vacancy.title) throw 'Вакансия не удалилась';
    }
    openTab(1, page);
    await functions.wait(300);
    let lastRowArchive = functions.querySelector('.tab:nth-child(2) .v-data-table__wrapper table tbody tr:last-child', page);
    let rowsCount = page.querySelectorAll('.tab:nth-child(2) .v-data-table__wrapper table tbody tr').length;
    functions.scrollTo(lastRowArchive);
    await functions.wait(300);
    let rowsCount2 = page.querySelectorAll('.tab:nth-child(2) .v-data-table__wrapper table tbody tr').length;
    if (rowsCount2 <= rowsCount) throw 'Новые строки не подгрузились после скролла';
  },

};