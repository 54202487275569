<template>
  <Modal ref="modal" title="Шаблон сообщения" :width="type === 'select' ? 700 : 400">
    <v-row class="pa-0 ma-0">
      <v-col class="ma-0 pa-0">
        <v-row class="pa-0 ma-0 template-options">
          <v-btn small depressed min-height="32" @click="insertVar(event.id ? event.candidate.name : templateData.name)">Имя</v-btn>
          <v-btn small depressed min-height="32" @click="insertVar( event.id ? event.date.split('-').reverse().join('.') : templateData.date)">Дата</v-btn>
          <v-btn small depressed min-height="32" @click="insertVar( event.id ? $moment.unix(event.datetime_from).format('HH:mm') : templateData.time)">Время</v-btn>
          <v-btn
            v-if="event.place && event.place.id*1"
            small depressed min-height="32" @click="insertVar( `${event.place.title}, ${event.place.address}` )">Место
          </v-btn>
          <v-btn v-if="!event.id" small depressed min-height="32" @click="insertVar(templateData.address )">Место</v-btn>
          <v-btn v-if="event.vacancy && event.vacancy.id*1" small depressed min-height="32"
                 @click="insertVar( event.vacancy.title)">Вакансия</v-btn>
          <v-btn v-if="!event.id" small depressed min-height="32"
                 @click="insertVar( templateData.vacancy )">Вакансия</v-btn>
          <v-btn small depressed min-height="32" @click="insertVar( event.id ? event.candidate.surname : templateData.surname)">Фамилия</v-btn>
        </v-row>
        <v-row class="ma-0 pa-0">
          <input type="text" id="test_input">
<!--          <span class="placeholder">Введите текст...</span>-->

          <div :contenteditable="true" class="template-msg-input mt-4"></div>
        </v-row>
        <v-row class="pa-0 ma-0" v-if="type === 'select'">
          <v-btn color="primary" depressed class="mb-4">Начать диалог</v-btn>
        </v-row>
        <v-row class="pa-0 ma-0" v-if="type === 'select'">
          <v-btn depressed>Пропустить</v-btn>
        </v-row>
      </v-col>
      <v-col class="ma-0 pa-0 pl-8" v-if="type === 'select'">
        <v-list>
          <v-list-item>Lorem ipsum dolor sit amet, consectetur adipisicing elit. A ad asperiores commodi dolore dolorem et incidunt laboriosam
            laudantium molestiae, mollitia natus nemo perspiciatis quod recusandae reiciendis rem repellendus. Quidem, soluta.
          </v-list-item>
          <v-list-item>Lorem ipsum dolor sit amet, consectetur adipisicing elit. A ad asperiores commodi dolore dolorem et incidunt laboriosam
            laudantium molestiae, mollitia natus nemo perspiciatis quod recusandae reiciendis rem repellendus. Quidem, soluta.
          </v-list-item>
          <v-list-item>Lorem ipsum dolor sit amet, consectetur adipisicing elit. A ad asperiores commodi dolore dolorem et incidunt laboriosam
            laudantium molestiae, mollitia natus nemo perspiciatis quod recusandae reiciendis rem repellendus. Quidem, soluta.
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <template #actions v-if="['create', 'edit'].includes(type)">
      <v-btn color="primary" depressed class="mb-4" @click="save">Сохранить</v-btn>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';

export default {
  name: 'NotifyMessageTemplate',
  components: { Modal },
  data() {
    return {
      event: {},
      cursorPos: 0,
      tagsCount: 0,
      template: {},
      templateData: {
        name: 'Имя',
        surname: 'Фамилия',
        date: 'Дата',
        time: 'Время',
        vacancy: 'Вакансия',
        address: 'Адрес',
      },
      type: '',//create, edit, select
    };
  },
  emits: ['on-update'],
  methods: {
    open(params) {
      this.cursorPos = 0;
      this.event = {};
      this.tagsCount = 0;
      this.template = {};
      this.type = '';
      window.tf = this.htmlSubstring;
      this.$refs.modal.open();
      if (params.event) this.event = params.event;
      if (params.id) this.getTemplate(params.id);
      this.type = params.type;
      setTimeout(() => {
        this.onChange();
      });
    },
    getTemplate(id) {
      this.$server.request2(`messageTemplate/get/${id}`, {}, (data)=>{
        this.template = data.response;
      });
    },
    save() {
      const method = this.template.id ? `messageTemplate/update/${this.template.id}` : 'messageTemplate/create';
      this.$server.request2(method, this.template, ()=>{

      });
    },
    getCaretPosition(element) {
      var caretOffset = 0;
      var doc = element.ownerDocument || element.document;
      var win = doc.defaultView || doc.parentWindow;
      var sel;
      if (typeof win.getSelection != 'undefined') {
        sel = win.getSelection();
        if (sel.rangeCount > 0) {
          var range = win.getSelection()
            .getRangeAt(0);
          var preCaretRange = range.cloneRange();
          preCaretRange.selectNodeContents(element);
          preCaretRange.setEnd(range.endContainer, range.endOffset);
          caretOffset = preCaretRange.toString().length;
        }
      } else if ((sel = doc.selection) && sel.type != 'Control') {
        var textRange = sel.createRange();
        var preCaretTextRange = doc.body.createTextRange();
        preCaretTextRange.moveToElementText(element);
        preCaretTextRange.setEndPoint('EndToEnd', textRange);
        caretOffset = preCaretTextRange.text.length;
      }
      return caretOffset;
    },
    onChange() {
      const input = document.querySelector('.template-msg-input');
      ['input', 'click', 'keydown'].forEach((e) => {
        input.addEventListener(e, () => {
          let pos = this.getCaretPosition(document.querySelector('.template-msg-input'));
          if (pos || pos === 0) this.cursorPos = pos;
          this.template.text = input.textContent;
        });
      });
      input.addEventListener('focus', (e) => {
        input.classList.add('focus');
      });
      input.addEventListener('blur', (e) => {
        if (!e.target.outerText.length) input.classList.remove('focus');
      });
    },
    getVar(value) {
      return `<span class="msg-var" style="display: inline-block;
  height: 32px;
  padding: 4px 8px;
  background: var(--color-gray); margin-top: 4px" contenteditable="false">${value}</span>&nbsp;`;
    },
    insertVar(value) {
      this.tagsCount++;
      const templateInput = document.querySelector('.template-msg-input');
      templateInput.classList.add('focus');
      templateInput.innerHTML = this.htmlSubstring(templateInput.innerHTML, 0, this.cursorPos+1) + this.getVar(value) + this.htmlSubstring(templateInput.innerHTML, this.cursorPos+1);
    },
    htmlSubstring(html, from, length = null) {
      let result = '';
      let realIndex = 0;
      let isTag = false;
      let isHTMLSymbol = false;
      for (let i = 0; i < html.length; ++i) {
        let l = html[i];
        if (l === '<') {
          isTag = true;
        } else if (l === '>') {
          isTag = false;
          // continue;
        }
        if (l === '&') {
          isHTMLSymbol = true;
        } else if (l === ';') {
          isHTMLSymbol = false;
          // continue;
        }
        if (!isTag && !isHTMLSymbol) realIndex++;
        if (realIndex > from) result += l;
        if (length && realIndex - from > length) return result;
      }
      return result;
    }
  },
};
</script>

<style scoped lang="scss">
.template-options {
  gap: 16px;
}

.template-msg-input {
  min-height: 50px;
  cursor: text;
  width: 100%;
  max-width: 450px;

  .placeholder {
    opacity: .3;
    font-weight: bold;
    font-size: 14px;
    display: inline-block;
  }

  &.focus {
    .placeholder {
      display: none;
    }
  }
}

.msg-var {
  display: inline-block;
  height: 32px;
  padding: 4px 8px;
  background: var(--color-gray);
}

</style>