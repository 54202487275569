<template>
  <div :class="[classes, statusInfo.color]" class="candidate-status">
    {{ statusInfo.text }}
  </div>
</template>

<script>
export default {
  name: 'CandidateStatus',
  props: {
    candidate: Object,
    classes: String,
  },
  computed: {
    statusInfo() {
      return this.$store.state.static.candidatesStatuses[Number(this.candidate.status)];
    },
  },
};
</script>

<style lang="scss">
  .candidate-status  {
    display: inline-block;
    padding: 10px 8px;
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
    text-transform: uppercase;
    color: var(--page-color-main);
    border-radius: 4px;
    background: var(--border-color);

    max-width: 170px;
    &.green-bg {
      background: #C3F7E8;
    }

    &.blue-bg {
      background: #D0EEFE;
    }

    &.red-bg {
      background: #FFE2E6;
    }
  }
</style>
