import functions from '@/tests/functions';

async function openHeaderDropdownMenu(buttonIndex) {
  functions.querySelector('.candidate-page .page-header button.v-btn')
    .click();
  await functions.wait(300);
  let menu = functions.querySelector('.v-menu__content.menuable__content__active');
  if (!menu) throw 'Меню с кнопками в хэдере не открылось';
  let buttonsDropdownMenuCandidateHeader = menu.querySelectorAll('.v-list .popover-list-item button');
  await functions.wait(300);
  if (!buttonsDropdownMenuCandidateHeader.length) throw 'Кнопок в меню нет';
  return buttonsDropdownMenuCandidateHeader[buttonIndex];
}

async function checkContactModal() {
  await functions.wait(300);
  let contactsModal = functions.querySelector('.v-dialog.v-dialog--active');
  await functions.wait(300);
  if (functions.querySelector('.modal-title', contactsModal).innerText !== 'Контакты') throw 'Заголовок модалки не Контакты';
  let contacts = contactsModal.querySelectorAll('.contacts-block_list_item');
  if (!contacts.length) throw 'Список контактов пустой';

  await functions.wait(300);

  contactsModal.querySelector('.icon-close')
    .click();
  //надо как то проверять, что контактов показано столько, сколько их на самом деле
}

async function createComment(selector) {
  functions.querySelector(selector)
    .click();
  await functions.wait(300);
  await fillCommentInModal();
}

async function fillCommentInModal() {
  let modal = functions.querySelector('.v-dialog.edit-event-modal.v-dialog--active');
  functions.querySelector('.ql-editor.ql-blank', modal).innerHTML = functions.genLongText(100);
  await functions.wait(300);
  let rateItems = modal.querySelectorAll('.change-rating-event_items .icon.icon-rating-line');
  rateItems[4].click();
  functions.querySelector('.default-button', modal)
    .click();
}

async function selectVacancy() {
  let vacanciesModal = functions.querySelector('.v-dialog.v-dialog--active');
  if (functions.querySelector('.modal-title', vacanciesModal).innerText !== 'Выбрать вакансию') throw 'Заголовок модалки не "Выбрать вакансию"';
  let vacanciesList = vacanciesModal.querySelectorAll('.v-list-item');
  let randomVacancyIndex = Math.floor(Math.random() * (vacanciesList.length - 1));
  let randomVacancy = vacanciesList[randomVacancyIndex];
  let randomVacancyTitle = functions.querySelector('.v-list-item__title', randomVacancy).innerText;
  randomVacancy.click();
  return randomVacancyTitle;
}

export default {
  async openCreate() {
    functions.openSection(2);
    await functions.wait(300);
    functions.querySelector('#page-candidates .page-header-button')
      .click();
    await functions.wait(300);
  },

  async createManual() {
    await functions.createCandidateManual();
  },

  async checkFilters() {
    functions.openSection(2);
    await functions.wait(500);
    let page = functions.querySelector('#page-candidates');
    let filterButton = functions.querySelector('.searchbar-wrap .transparent-button', page);
    filterButton.click();
    await functions.wait(300);
    let filterModal = functions.getModal();
    if (functions.querySelector('.modal-title', filterModal).innerText !== 'Фильтры') throw `'Заголовок модалки не "Фильтры", а ${functions.querySelector('.modal-title', filterModal).innerText}'`
    let inputKeyWords = functions.querySelector('.keywords_input', filterModal);
    let randomKeyWord = functions.genLongText(2)
    functions.fillTextInput(inputKeyWords, randomKeyWord, false);
    await functions.wait(300);
    functions.querySelector('.keywords_add-button').click();
    let filterData = [
      functions.genLongText(2),
      functions.rand(15, 24),
      functions.rand(25, 40),
      functions.rand(0, 2),
      functions.rand(3, 5),
      true,
      functions.rand(1, 2),
    ];
    let inputForm = functions.querySelector('.form', filterModal);
    await functions.fillForm(inputForm, filterData);
    functions.querySelector('.v-btn.primary', filterModal).click();
    await functions.wait(700);
    filterButton.click();
    await functions.wait(600);
    filterModal = functions.getModal();
    if (functions.querySelector('.modal-title', filterModal).innerText !== 'Фильтры') throw `'Заголовок модалки не "Фильтры", а ${functions.querySelector('.modal-title', filterModal).innerText}'`
    let savedKeyWord = functions.querySelector('.keywords_item_value', filterModal).innerText;
    if (savedKeyWord !== randomKeyWord) throw `Ключевое слово не сохранилось, введенное: ${randomKeyWord},сохраненное: ${savedKeyWord}`;
    functions.querySelector('.v-btn:not(.primary)', filterModal).click();
  },
  async checkTable() {
    functions.openSection(2);
    await functions.wait(500);
    let page = functions.querySelector('#page-candidates');
    let rows = page.querySelectorAll('tr');
    if (!rows.length) throw 'Таблица кандидатов пустая';
    let randomIndex = Math.floor(Math.random() * (rows.length - 1));

    let randomCandidateName = functions.querySelector('td:nth-child(2) .candidate-main-info_title', rows[randomIndex]).innerText;
    await functions.fillTextInput(functions.querySelector('.searchbar-wrap input', page), randomCandidateName, false);
    await functions.wait(600);
    let newRows = page.querySelectorAll('tr');
    let searchedCandidateName = functions.querySelector('td:nth-child(2) .candidate-main-info_title', newRows[1]).innerText;
    if (randomCandidateName !== searchedCandidateName) throw `Искомый кандидат не найден, искомый: ${randomCandidateName}, найденный: ${searchedCandidateName}`;
    await functions.fillTextInput(functions.querySelector('.searchbar-wrap input', page), '', false);
    await functions.wait(600);
    rows = page.querySelectorAll('tr');

    let firstCandidateDropdownMenuBtn = functions.querySelector('td:last-child button', rows[1]);
    let firstCandidateName = functions.querySelector('td:nth-child(2) .candidate-main-info_title', rows[1]).innerText;
    firstCandidateDropdownMenuBtn.click();
    await functions.wait(300);

    let dropdownMenu = functions.querySelector('.v-menu__content.menuable__content__active');
    let menuButtons = dropdownMenu.querySelectorAll('.v-list .popover-list-item button');
    menuButtons[0].click();
    await checkContactModal();

    firstCandidateDropdownMenuBtn.click();
    menuButtons[1].click();
    await functions.wait(300);
    if (functions.querySelector('.v-btn__content', menuButtons[1])
      .innerHTML
      .includes('Прикрепить')) {
      let randomVacancyTitle = await selectVacancy();
      await functions.wait(700);
      let vacancyAfter = functions.querySelector('.candidate-vacancy', rows[1]).innerText;
      if (vacancyAfter !== randomVacancyTitle) throw 'Вакансия у кандидата не изменилась';
    } else {
      let vacancyBefore = functions.querySelector('.candidate-vacancy', rows[1]).innerText;
      let alertModal = functions.getModal();
      if (functions.querySelector('.alert-message', alertModal).innerText !== 'Открепить выбранных кандидатов?') throw 'Текст в подтверждении открепления неверный';
      functions.querySelector('.default-button', alertModal)
        .click();
      await functions.wait(900);
      let vacancyAfter = functions.querySelector('.candidate-vacancy', rows[1]).innerText;
      if (vacancyAfter !== vacancyBefore) throw 'Вакансия у кандидата не изменилась';
    }

    firstCandidateDropdownMenuBtn.click();
    menuButtons[2].click();
    await functions.wait(300);
    let shareModal = functions.getModal();
    if (functions.querySelector('.modal-title', shareModal).innerText !== 'Поделиться ссылкой') throw 'Заголовок модалки не Поделиться ссылкой';

    shareModal.querySelector('.icon-close')
      .click();
    await functions.wait(300);
    firstCandidateDropdownMenuBtn.click();
    menuButtons[3].click();
    await functions.wait(300);
    let removeModal = functions.getModal();
    if (functions.querySelector('.alert-message', removeModal).innerText !== 'Вы точно хотите удалить кандидатов(-а)?') throw `Заголовок модалки не "Вы точно хотите удалить кандидатов(-а)?", а ${functions.querySelector('.alert-message', removeModal)}`;
    functions.querySelector('.default-button', removeModal)
      .click();
    await functions.wait(700);
    let firstCandidateNameAfterRemoving = functions.querySelector('td:nth-child(2) .candidate-main-info_title', rows[1]).innerText;
    if (firstCandidateName === firstCandidateNameAfterRemoving) throw 'Кандидат не удалился';

    let lastRow = functions.querySelector('.v-data-table__wrapper table tbody tr:last-child', page);
    let rowsCount = page.querySelectorAll('.v-data-table__wrapper table tbody tr').length;
    functions.scrollTo(lastRow);
    await functions.wait(300);
    let rowsCount2 = page.querySelectorAll('.v-data-table__wrapper table tbody tr').length;
    if (rowsCount2 <= rowsCount) throw 'Новые строки не подгрузились после скролла';

    rows = page.querySelectorAll('.v-data-table__wrapper table tbody tr');
    functions.querySelector('.v-simple-checkbox', rows[0]).click();
    await functions.wait(300);
    let bottomMenu = functions.querySelector('.control-panel', document, false);
    if (!bottomMenu) throw 'Нижнее меню с кнопками не открылось';
    let bottomMenuButtons = bottomMenu.querySelectorAll('.v-btn');

    bottomMenuButtons[0].click();
    await selectVacancy();

    functions.querySelector('.v-simple-checkbox', rows[0]).click();
    await functions.wait(300);
    bottomMenuButtons[1].click();
    await functions.wait(300);
    let alertModal = functions.querySelector('.v-dialog.v-dialog--active');
    if (functions.querySelector('.alert-message', alertModal).innerText !== 'Открепить выбранных кандидатов?') throw 'Текст в подтверждении открепления неверный';
    functions.querySelector('.default-button', alertModal)
      .click();
    await functions.wait(300);

    functions.querySelector('.v-simple-checkbox', rows[0]).click();
    await functions.wait(300);
    bottomMenuButtons[2].click();
    await functions.wait(300);
    shareModal = functions.getModal();
    if (functions.querySelector('.modal-title', shareModal).innerText !== 'Поделиться ссылкой') throw 'Заголовок модалки не Поделиться ссылкой';
    shareModal.querySelector('.icon-close')
      .click();

    functions.querySelector('.v-simple-checkbox', rows[0]).click();
    await functions.wait(300);
    bottomMenuButtons[3].click();
    await functions.wait(300);
    removeModal = functions.getModal();
    if (functions.querySelector('.alert-message', removeModal).innerText !== 'Вы точно хотите удалить кандидатов(-а)?') throw `Заголовок модалки не "Вы точно хотите удалить кандидатов(-а)?", а ${functions.querySelector('.alert-message', removeModal)}`;
    functions.querySelector('.default-button', removeModal)
      .click();
    await functions.wait(700);
    firstCandidateNameAfterRemoving = functions.querySelector('td:nth-child(2) .candidate-main-info_title', rows[1]).innerText;
    if (firstCandidateName === firstCandidateNameAfterRemoving) throw 'Кандидат не удалился';

  },
  async openCandidate() {
    functions.openSection(2);
    await functions.wait(300);
    functions.querySelector('.candidates-table tbody tr:first-child')
      .click();
    await functions.wait(1500);
  },

  async openContactsModal() {
    let button = await openHeaderDropdownMenu(0);
    button.click();
    await checkContactModal();
  },

  async openShareModal() {
    let button = await openHeaderDropdownMenu(1);
    button.click();
    await functions.wait(300);
    let shareModal = functions.querySelector('.v-dialog.v-dialog--active');
    await functions.wait(300);
    if (functions.querySelector('.modal-title', shareModal).innerText !== 'Поделиться ссылкой') throw 'Заголовок модалки не Поделиться ссылкой';

    shareModal.querySelector('.icon-close')
      .click();
  },

  async openContactLaterModal() {
    let candidatePage = functions.querySelector('.candidate-page');
    let tabs = candidatePage.querySelectorAll('.v-tab');
    tabs[1].click();
    await functions.wait(700);
    let eventsBefore = candidatePage.querySelectorAll('.candidate-chain-list li');

    let button = await openHeaderDropdownMenu(2);
    button.click();
    await functions.wait(300);
    let contactLaterModal = functions.querySelector('.v-dialog.v-dialog--active');
    await functions.wait(300);
    if (functions.querySelector('.modal-title', contactLaterModal).innerText !== 'Связаться еще раз позже') throw 'Заголовок модалки не Связаться еще раз позже';

    let remindData = {
      comment: functions.genLongText(),
      checkbox: true,
      date: functions.date(24 * 3600 * Math.floor(Math.random() * 15)),
      time: functions.rand(10, 23) + ':' + functions.rand(10, 59),
    };

    await functions.fillForm(functions.querySelector('.modal-content', contactLaterModal), Object.values(remindData));

    contactLaterModal.querySelector('.default-button')
      .click();
    await functions.wait(300);
    let eventsAfter = candidatePage.querySelectorAll('.candidate-chain-list li');
    if (eventsAfter.length === eventsBefore.length) throw 'Ивент - напоминание не добавился';
  },

  async toggleFavourite() {
    let isFavouriteBefore = functions.querySelector('.page-title .v-icon.mdi-star', document, false);
    let button = await openHeaderDropdownMenu(3);
    button.click();
    await functions.wait(500);
    let clickResult = functions.querySelector('.page-title .v-icon.mdi-star', document, false);
    if (isFavouriteBefore === clickResult) throw 'Статус кандидата не изменился';
  },

  async createCommentFromAvatar() {
    await createComment('.page-header .candidate-avatar_rating');
  },

  async openContactsModalFromAvatar() {
    functions.querySelector('.candidate-avatar_contacts')
      .click();
    await checkContactModal();
  },
  async attachVacancy() {
    let vacancyInfoText = functions.querySelector('.candidate-vacancy_info-text');
    if (vacancyInfoText.innerText === 'Прикрепить к вакансии') {
      vacancyInfoText.click();
      await functions.wait(500);

      let randomVacancyTitle = await selectVacancy();
      await functions.wait(300);
      let vacancyAttachTitle = functions.querySelector('.candidate-vacancy_info-title').innerText;
      if (randomVacancyTitle !== vacancyAttachTitle) throw 'Название выбранной вакансии не соответствует записанному названию';
      await functions.wait(300);
    }
  },
  async changeFolder() {
    let vacancyInfoText = functions.querySelector('.candidate-vacancy_info-text');
    if (vacancyInfoText.innerText === 'Прикреплен:') {
      let button = await openHeaderDropdownMenu(5);
      button.click();
      await functions.wait(500);

      let folderBefore = functions.querySelector('.candidate-folder span').innerText;

      let foldersModal = functions.getModal();
      if (functions.querySelector('.modal-title', foldersModal).innerText !== 'Переместить кандидата') throw 'Заголовок модалки не Переместить кандидата';
      let folders = foldersModal.querySelectorAll('.folder-list-item');
      if (folders.length) {
        let randomFolderIndex = Math.floor(Math.random() * (folders.length - 1));
        let randomFolder = folders[randomFolderIndex];
        randomFolder.click();
        await functions.wait(700);
        let folderAfter = functions.querySelector('.candidate-folder span').innerText;
        if (folderBefore.toLowerCase() === folderAfter.toLowerCase()) throw `Название этапа не поменялось, старое: ${folderBefore}, новое:  ${folderAfter}`;
      } else {
        console.log('В вакансии этапов нет');
        foldersModal.querySelector('.icon-close')
          .click();
      }
    }
  },

  async setNotSuitable() {
    let vacancyInfoText = functions.querySelector('.candidate-vacancy_info-text');
    if (vacancyInfoText.innerText === 'Прикреплен:') {
      let button = await openHeaderDropdownMenu(3);
      button.click();
      await functions.wait(1000);
      let status = functions.querySelector('.candidate-folder span').innerText;
      if (status.toLowerCase() !== 'не подходит') throw `Статус кандидата не изменился на "не подходит",оказалось ${status}`;
    }
  },

  async detachVacancy() {
    let vacancyInfoText = functions.querySelector('.candidate-vacancy_info-text');
    if (vacancyInfoText.innerText === 'Прикреплен:') {
      functions.querySelector('.candidate-vacancy .icon-close')
        .click();
      await functions.wait(300);
      let alertModal = functions.querySelector('.v-dialog.v-dialog--active');
      if (functions.querySelector('.alert-message', alertModal).innerText !== 'Открепить от вакансии?') throw 'Текст в подтверждении открепления неверный';
      functions.querySelector('.default-button', alertModal)
        .click();
      await functions.wait(300);
    }
  },
  async createCommentFromPage() {
    await createComment('.candidate-comment_title span');
  },

  async editComment() {
    let comment = functions.querySelector('.candidate-comment_text', document, false);
    if (comment) {
      comment.click();
      await functions.wait(500);
      await fillCommentInModal();
    }
  },

  async selectEventsTab() {
    let candidatePage = functions.querySelector('.candidate-page');
    let tabs = candidatePage.querySelectorAll('.v-tab');
    tabs[1].click();
    await functions.wait(700);

    let eventsBefore = candidatePage.querySelectorAll('.candidate-chain-list li');
    let createInterviewButton = functions.querySelector('.candidate-events-header button:nth-child(1)', candidatePage);
    createInterviewButton.click();
    await functions.wait(300);
    await functions.fillEventModal(false);
    let eventsAfterCreateInterview = candidatePage.querySelectorAll('.candidate-chain-list li');
    if (eventsAfterCreateInterview.length === eventsBefore.length) throw 'Ивент - интервью не добавился';
    let firstEventInList = functions.querySelector('.candidate-chain-item_title span', candidatePage).innerText;
    if (firstEventInList !== 'Собеседование') throw 'Первый в списке ивент - не собеседование';

    let createNotifyButton = functions.querySelector('.candidate-events-header button:nth-child(2)', candidatePage);
    createNotifyButton.click();
    await functions.wait(300);
    await functions.fillNotifyModal(false);
    let eventsAfterCreateNotify = candidatePage.querySelectorAll('.candidate-chain-list li');
    if (eventsAfterCreateInterview.length === eventsAfterCreateNotify.length) throw 'Ивент - напоминание не добавился';
  },

  async selectEditTab() {
    let candidatePage = functions.querySelector('.candidate-page');
    let tabs = candidatePage.querySelectorAll('.v-tab');
    tabs[2].click();
    await functions.wait(700);
    let listItems = candidatePage.querySelectorAll('.about-list li');//.about-list_item_value
    if (!listItems.length) throw 'Список с параметрами не найден в редактировании кандидата';
    for (let i = 1; i < listItems.length; ++i) {
      let listItem = listItems[i];
      let value,
        text,
        text2,
        innerText;
      if (i !== 8) {
        value = functions.querySelector('.about-list_item_value, .about-list_item_textarea', listItem, false);
        if (!value) throw 'Не получилось взять значение в строке редактирования';
        innerText = value.innerText.trim();
        text = functions.digitsOnly(innerText, '-')
          .split('-');
        text2 = text[1];
        text = text[0];
      }

      functions.querySelector('.about-list_item_after', listItem)
        .click();
      await functions.wait(300);

      let modal = functions.getModal();
      let fieldName = functions.querySelector('.about-list_item_title', listItem)
        .innerText
        .trim();
      if (!modal) throw `Модалка редактирования поля "${fieldName}" не открылась`;
      let modalTitle = functions.querySelector('.form-field_title', modal)
        .innerText
        .trim();
      if (modalTitle.indexOf(modalTitle) !== 0) throw `Название поля в модалке неверное ("${modalTitle}" начинается не с "${fieldName}")`;
      let inputs = modal.querySelectorAll('input, select, textarea');

      if (i !== 8) {
        if (inputs.length === 0) {
          throw 'В модалке нет инпутов';
        } else if (inputs.length === 2 && !text2) {
          throw 'В модалке 2 инпута, а должен быть один (второго значения нет)';
        } else if (inputs.length > 2) throw 'В модалке больше 2-х инпутов';
      }

      let values = [];
      for (let j = 0; j < inputs.length; ++j) {
        let input = inputs[j];
        console.log({ input });
        let dateInput = input.closest('.datepicker-field');
        let value = input.value + `${Math.floor(Math.random() * 10)}`;
        if (dateInput) {
          value = functions.date(24 * 3600 * (Math.random() * 15 + j + i));
          await functions.fillDateInput(dateInput, value);
          value = value.split('-')
            .reverse()
            .join('');
          // return;
        } else if (input.tagName === 'SELECT') {
          value = functions.fillSelectPlain(input, 1);
        } else if (modalTitle.toLowerCase()
          .includes('телефон')) {
          value = functions.generatePhone();
          functions.fillTextInput(input, value, false);
        } else {
          functions.fillTextInput(input, value.slice(0, 12), false);
          await functions.wait(100);
          value = input.value;
        }
        values.push(value);
      }
      value = values.join(' - ');
      functions.querySelector('.default-button', modal)
        .click();
      await functions.wait(300);
      let savedValue = functions.querySelector('.about-list_item_value, .about-list_item_textarea', listItem)
        .innerText
        .trim();

      if (!isNaN(+savedValue[0])) {
        savedValue = functions.digitsOnly(savedValue, '- ')
          .trim();
      }
      if (modalTitle.toLowerCase()
        .includes('телефон')) {
        savedValue = savedValue.replace(/\D/g, '');
      }
      console.log({ savedValue });
      if (i !== 8) {
        if (savedValue !== value) throw `Было изменено поле ${fieldName} на "${value}", но после сохранения оно равно "${savedValue}"`;
      } else {
        let listItemsAfterAddingContact = candidatePage.querySelectorAll('.about-list li');//.about-list_item_value
        if (listItems.length === listItemsAfterAddingContact.length) throw 'Контакт не добавился';
      }
      await functions.wait(100);
    }
  }
};