const parseEventNames = (eventName) => eventName.replace(/ /g, '').split(',');

class eventBus {
  constructor() {
    this.events = {};
  }

  on(eventNames, fn) {
    parseEventNames(eventNames).forEach((name) => {
      this.events[name] = this.events[name] || [];
      this.events[name].push(fn);
    });
  }

  off(eventNames, fn) {
    parseEventNames(eventNames).forEach((name) => {
      if (this.events[name]) {
        this.events[name] = this.events[name].filter((item) => item !== fn);
      }
    });
  }

  emit(eventNames, ...args) {
    parseEventNames(eventNames).forEach((name) => {
      if (this.events[name]) {
        this.events[name].forEach((fn) => fn(...args));
      }
    });
  }
}

export default eventBus;
