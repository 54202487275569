import functions from '@/tests/functions';

export default {
  async logout() {
    functions.querySelector('.toolbar .toolbar-item.logout').click();
    await functions.wait(300);
    let confirmLogoutModal = functions.getModal();
    if (functions.querySelector('.modal-title', confirmLogoutModal).innerText !== 'Хотите выйти?') throw `Текст в модалке не "Хотите выйти?", ${functions.querySelector('.modal-title', confirmLogoutModal).innerText}`;
    functions.querySelector('.default-button', confirmLogoutModal).click();
  },
  async login(viaEmail = false) {
    let loginModal = functions.querySelector('.login-container');
    let modalTitle = functions.querySelector('.modal-login-title', loginModal).innerText;
    functions.querySelector('.transparent-button', loginModal).click();
    await functions.wait(300);
    let modalTitleAfterToggleType = functions.querySelector('.modal-login-title', loginModal).innerText;
    if (modalTitle === modalTitleAfterToggleType) throw `Заголовок модалки не изменился после смены способа авторизации, ожидалось: "Введите email", было: ${modalTitleAfterToggleType} `

    if (!viaEmail) {
      functions.querySelector('.transparent-button', loginModal).click();
      await functions.wait(500);
      modalTitleAfterToggleType = functions.querySelector('.modal-login-title', loginModal).innerText;

      if (modalTitle !== modalTitleAfterToggleType) throw `Заголовок модалки не "Номер телефона", ${modalTitleAfterToggleType}`;

      let phoneInput = functions.querySelector('input', loginModal);
      functions.fillTextInput(phoneInput, '79999999999', false);
      await functions.wait(200);
      functions.querySelector('.v-btn.primary', loginModal).click();
      await functions.wait(700);
      functions.querySelector('.modal-login-subtitle span').click();
      await functions.wait(700);
      let modalTitleAfterBack = functions.querySelector('.modal-login-title', loginModal).innerText;
      if (modalTitle !== modalTitleAfterBack) throw `Заголовок модалки не "Номер телефона", ${modalTitleAfterToggleType}`;
      phoneInput = functions.querySelector('input', loginModal);
      functions.fillTextInput(phoneInput, '79999999999', false);
      await functions.wait(300);
      functions.querySelector('.v-btn.primary', loginModal).click();
      await functions.wait(300);
    } else {
      let emailInput = functions.querySelector('input', loginModal);
      functions.fillTextInput(emailInput, 'm@khromov.su', false);
      await functions.wait(150);
      functions.querySelector('.v-btn.primary', loginModal).click();
    }
  },
  async checkCode() {
    let loginModal = functions.querySelector('.auth-container');
    let otpForm = functions.querySelector('.v-otp-input', loginModal);
    let code = [1, 2, 3, 4];
    await functions.fillForm(otpForm, code);
  }
}