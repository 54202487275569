<template>
  <Modal noVerticalPadding noVerticalPadding ref="modal" content-class="change-folder-candidates-modal" :is-no-scroll="false"
    :title="ids.length > 1 ? `Переместить кандидатов ( ${ids.length} )` : 'Переместить кандидата'">
    <div class="folder-list" v-if="vacancy.funnel && vacancy.funnel.length">
      <ul>
        <li v-for="(_folder, key) in vacancy.funnel" :key="key">
          <div class="folder-list-item" @click=" key.toString() === folder.toString() ? '' : changeFolder(key)">
            <div class="folder-list-item_media">
              <div class="icon icon-folder" :style="key.toString() === folder.toString() ? 'color: var(--primary-color);' : 'color: var(--border-color);'"></div>
            </div>
            <div class="folder-list-item_title">{{ _folder.title }}</div>
          </div>
        </li>
      </ul>
    </div>
    <NoResults v-else>
      <template #text>Воронка пустая</template>
    </NoResults>
  </Modal>
</template>

<script>
import NoResults from '@/components/NoResults';
export default {
  name: 'ChangeFolderCandidatesModal',
  components: { NoResults },
  data() {
    return {
      ids: [],
      vacancy: {},
      folder: '',
    };
  },
  methods: {
    open(data) {
      this.folder = '';
      this.ids = data.candidateIds;
      this.getVacancy(data.vacancyId);
      if (data.folder) this.folder = data.folder;
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    getVacancy(vacancyId) {
      this.$server.request2(`vacancy/get2/${vacancyId}`, {}, (data) => {
        this.vacancy = data.response;
      });
    },
    changeFolder(folder) {
      this.$server.request2('candidate/changeFolder', {
        vacancy: this.vacancy.id,
        candidates: this.ids,
        not_suitable: 0,
        folder,
      }, () => {
        this.$eventBus.emit('update-candidate-in-folder');
        this.close();
      });
    },
  },
};
</script>

<style lang="scss">
.change-folder-candidates-modal {
  .folder-list {
    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
    }

    li {
      &:not(:last-child) {
        border-bottom: 1px solid var(--border-color);
      }
    }
  }

  .folder-list-item {
    display: flex;
    align-items: center;
    padding: 15px 0;
    cursor: pointer;

    &_media {
      margin-right: 10px;

      .icon {
        font-size: 32px;;
        color: rgba(var(--page-color-main-rgb), .25);
      }
    }

    &_title {
      font-weight: 500;
    }
  }
}
</style>
