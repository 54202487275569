import functions from '@/tests/functions';
import dashboard from '@/tests/dashboard';

let scriptTitle;
export default {
  async openScripts() {
    functions.querySelector(`.main-sidebar .nav-list:nth-child(3) :nth-child(4) a`).click();
    await functions.wait(300);
  },
  async openScript() {
    functions.querySelector('.page-header-button.default-button').click();
    await functions.wait(300);
  },
  async createScript() {
    let scriptPage = functions.querySelector('.script-page');
    let inputTitle = functions.querySelector('.input-script-title input', scriptPage);
    scriptTitle = functions.genLongText(3)
    await functions.fillTextInput(inputTitle, scriptTitle, false);

    let questionListContainer = functions.querySelector('.question-list', scriptPage);
    functions.querySelector('.add-new-question', scriptPage).click();
    await functions.wait(300);
    functions.querySelector('.add-new-question', scriptPage).click();
    await functions.wait(300);
    let questions = questionListContainer.querySelectorAll('.question-list_item');
    for (let question of questions) {
      let questionInput = functions.querySelector('.question-list_item_title input', question);
      functions.fillTextInput(questionInput, functions.genLongText(3));
      functions.querySelector('.add-option-button', question).click();
      await functions.wait(300);

      let optionsInput = question.querySelectorAll('.question-list_option_input');
      optionsInput.forEach((option)=>{
        functions.fillTextInput(option, functions.genLongText(3));
      });
    }
    await functions.wait(300);
    functions.querySelector('button.default-button', scriptPage).click();
    await functions.wait(700);
  },
  async checkScript() {
    let scriptsList = functions.querySelector('.script-list');
    let scripts = scriptsList.querySelectorAll('li .script-list_item_title');
    scripts = Array.from(scripts);
    scripts =  scripts.map((script)=>script.innerText);
    if (!scripts.includes(scriptTitle)) throw 'Новый скрипт в списке не появился';
  },
  async deleteScript() {
    let scriptsList = functions.querySelector('.script-list');
    let scripts = scriptsList.querySelectorAll('li');
    let randomScriptIndex = functions.rand(0, scripts.length - 1);
    functions.querySelector('.icon-trash', scripts[randomScriptIndex]).click();
    await functions.wait(300);
    let modal = functions.getModal();
    functions.confirm();
    let scriptsAfterDeleting = scriptsList.querySelectorAll('li');
    if (scripts.length === scriptsAfterDeleting) throw 'Кол-во скриптов не уменьшилось после удаления'
  }
}