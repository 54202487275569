<template>
  <label class="checkbox">
    <input :type="type" :id="id" :checked="modelValue" @change="onChange" :name="name" :value="value">
    <div class="checkbox-bg"></div>
    <div class="checkbox-icon icon icon-check"></div>
  </label>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    type: {
      type: String,
      default: 'checkbox',
    },
    name: String,
    id: String,
    modelValue: Boolean,
    value: Boolean,
    resultValue: String,
  },
  emits: [
    'checked',
    'unchecked',
    'update:modelValue',
    'update:resultValue',
  ],
  methods: {
    updateValue(event) {
      this.$emit('update:modelValue', event.target.checked);
      this.$emit('update:resultValue', event.target.checked ? this.value : '');
    },
    onChange(event) {
      this.updateValue(event);
      if (event.target.checked) {
        this.$emit('checked', event);
      } else {
        this.$emit('unchecked', event);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  position: relative;
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;

  [type="checkbox"], [type="radio"] {
    display: none;

    &:checked {
      & ~ .checkbox-icon {
        visibility: visible;
      }

      & ~ .checkbox-bg {
        background: var(--page-color-main);
      }
    }
  }

  .checkbox-icon {
    visibility: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #FFFFFF;
    font-size: 12px;
  }

  .checkbox-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--border-color);
  }
}
</style>
