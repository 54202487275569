import Server from '@/server/index';

const server = new Server();

export default {
  getCandidateFromApi(context, id) {
    return server.request(`candidate/get/${id}`)
      .then((dataResponse) => context.dispatch('setCandidate', dataResponse.response));
  },
  getCandidatesFromApi(context, filterParams) {
    return server.request('candidate/get', filterParams)
      .then((dataResponse) => Promise.all(dataResponse.response.map((c) => context.dispatch('setCandidate', c))));
  },

  subscribeToNotifications(context, timeBetween = 5000) {
    let isContinues = true;
    const stop = () => {
      isContinues = false;
    };
    let lastUpdate = null;
    const request = () => {
      if (!isContinues) return;

      if (window.app.$store.state.user.id) {
        server.request('user/notifies', { lastUpdate }, { preventDefaultErrorHandler: true })
          .then((responseData) => {
            responseData.response.forEach((item) => context.commit('setNotify', item));
            context.commit('sortAndSliceNotifies');
            lastUpdate = responseData.datetime;
          })
          .finally(() => setTimeout(request, timeBetween));
      }
    };
    request();
    return stop;
  },

  dispatchItem(context, {
    item,
    itemName,
    sectionName,
  }) {
    let object = { ...item };

    const emptyItem = {
      vacancy: null,
      candidates: [],
      candidate: null,
      events: [],
    };
    if (sectionName === 'vacancies') {
      delete emptyItem.vacancy;
      delete emptyItem.candidate;
    }
    if (sectionName === 'candidates') {
      delete emptyItem.candidates;
      delete emptyItem.candidate;
    }
    if (sectionName === 'events') {
      delete emptyItem.candidates;
      delete emptyItem.events;
    }
    if (!context.getters.getItem(item.id, sectionName)) {
      object = { ...emptyItem, ...object };
    }

    const setChildSection = (childItemName, childSectionName) => new Promise((resolve) => {
      if (!Object.hasOwnProperty.call(object, childSectionName)) {
        resolve();
        return;
      }
      if (!Array.isArray(object[childSectionName])) {
        object[childSectionName] = [];
        resolve();
        return;
      }
      if (object[childSectionName].every((sectionItem) => typeof sectionItem === 'string')) {
        resolve();
        return;
      }
      Promise.all(object[childSectionName].filter((i) => Number(i.id))
        .map((i) => {
          const childItem = { ...i };
          childItem[itemName] = item.id;
          return context.dispatch('dispatchItem', {
            item: childItem,
            itemName: childItemName,
            sectionName: childSectionName,
          });
        }))
        .then(() => {
          object[childSectionName] = object[childSectionName].map(({ id }) => String(id));
          resolve();
        });
    });

    const setChildItem = (childItemName, childSectionName) => new Promise((resolve) => {
      if (!Object.hasOwnProperty.call(object, childItemName)) {
        resolve();
        return;
      }
      if (!object[childItemName]) {
        object[childItemName] = null;
        resolve();
        return;
      }
      if (!Number(object[childItemName].id)) {
        resolve();
        return;
      }
      if (typeof object[childItemName] === 'string') {
        resolve();
        return;
      }
      context.dispatch('dispatchItem', {
        item: object[childItemName],
        itemName: childItemName,
        sectionName: childSectionName,
      })
        .then(() => {
          object[childItemName] = String(object[childItemName].id);
          resolve();
        });
    });

    const arrFuncSet = [];
    if (itemName === 'vacancy') {
      arrFuncSet.push(() => setChildSection('candidate', 'candidates'));
      arrFuncSet.push(() => setChildSection('event', 'events'));
    }
    if (itemName === 'candidate') {
      arrFuncSet.push(() => setChildItem('vacancy', 'vacancies'));
      arrFuncSet.push(() => setChildSection('event', 'events'));
    }
    if (itemName === 'event') {
      arrFuncSet.push(() => setChildItem('vacancy', 'vacancies'));
      arrFuncSet.push(() => setChildItem('candidate', 'candidates'));
    }
    return Promise.all(arrFuncSet.map((fn) => fn()))
      .then(() => {
        context.commit('setItem', {
          section: sectionName,
          item: object,
        });
      });
  },
  dispatchRemove(context, {
    id,
    itemName,
    sectionName,
  }) {
    const sectionsWithLink = [];
    if (itemName === 'vacancy') {
      sectionsWithLink.push('candidates');
      sectionsWithLink.push('events');
    }
    if (itemName === 'candidate') {
      sectionsWithLink.push('vacancies');
      sectionsWithLink.push('events');
    }
    if (itemName === 'event') {
      sectionsWithLink.push('vacancies');
      sectionsWithLink.push('candidates');
    }
    sectionsWithLink.forEach((section) => {
      const filterSingular = (sectionItem) => {
        const value = sectionItem[itemName];
        return value && String(value.id) === String(id);
      };
      const filterInArray = (sectionItem) => {
        const array = sectionItem[sectionName];
        return array && array.find((_id) => String(_id) === String(id));
      };
      const filter = (sectionItem) => filterSingular(sectionItem) || filterInArray(sectionItem);
      context.getters.getItems(filter, section)
        .forEach((sectionItem) => {
          const mutationItem = { ...sectionItem };
          if (filterSingular(sectionItem)) {
            mutationItem[itemName] = null;
          }
          if (filterInArray(sectionItem)) {
            mutationItem[sectionName] = mutationItem[sectionName].filter((_id) => _id !== id);
          }
          context.commit('setItem', {
            section,
            item: mutationItem,
          });
        });
    });
    context.commit('removeItem', {
      section: sectionName,
      id,
    });
  },

  setCandidate(context, object) {
    return context.dispatch('dispatchItem', {
      item: object,
      itemName: 'candidate',
      sectionName: 'candidates',
    })
      .then(() => context.getters.getCandidate(object.id));
  },
  removeCandidate(context, id) {
    return context.dispatch('dispatchRemove', {
      id,
      itemName: 'candidate',
      sectionName: 'candidates',
    });
  },
  sortCandidates(context) {
    const sort = (a, b) => Number(b.datetime_create) - Number(a.datetime_create);
    context.commit('sortSection', {
      section: 'candidates',
      sort,
    });
  },

  getResumeHtmlFromApi(context, id) {
    return server.request('files/getHtml', { id })
      .then((dataResponse) => context.dispatch('setResumeHtml', dataResponse.response));
  },
  setResumeHtml(context, object) {
    context.commit('setResumeHtml', object);
    return context.getters.getResumeHtml(object.id);
  },
};
