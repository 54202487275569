<template>
  <div class="candidate-main-info">
    <div class="candidate-main-info_media">
      <CandidateMedia :candidate="item"/>
      <CandidateRating :candidate="item"/>
    </div>
    <div class="candidate-main-info_content">
      <div class="candidate-main-info_content_row">
        <div class="candidate-main-info_title"
             :class="{'not-suitable': notSuitable}"
             v-if="item.candidate">{{ item.candidate.name }} {{ item.candidate.surname }}</div>
        <div class="candidate-main-info_title"
             :class="{'not-suitable': notSuitable}"
             v-else>{{ item.name }} {{ item.surname }}</div>
      </div>
      <div class="candidate-main-info_content_row" v-if="item.position && showPosition">
        <div class="candidate-main-info_subtitle">{{ item.position }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CandidateInfoBlock',
  props: ['item', 'notSuitable', 'showPosition'],
};
</script>

<style scoped lang="scss">
.candidate-main-info {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;

  &_media {
    position: relative;
    width: 42px;
    height: 42px;
    margin-right: 18px;
    flex-shrink: 0;

    .candidate-media {
      width: 100%;
      height: 100%;
    }

    .candidate-rating {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  &_content {
    & > * {
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &_title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #171717;
    &.not-suitable {
      color: rgba(var(--page-color-main-rgb), 0.25);
    }
  }

  &_subtitle {
    font-size: 14px;
    line-height: 16px;
    color: rgba(var(--page-color-main-rgb), .5);
  }

}
</style>