<template>
  <div class="event-list-item align-center" @click="itemOnClick"
       :class="{'event-list-item__ended': event.datetime_end}"
  >
    <div class="event-list-item_media">
      <CandidateMedia :candidate="event.candidate" v-if="event.candidate?.id"/>
    </div>
    <div class="event-list-item_inner">
      <div class="event-list-item_inner_row">
        <div class="event-list-item_title">
          <template v-if="String(event.type) === '0' || String(event.type) === '1'">
            <span class="bold" style="background: #C7DEDD;">Собеседование</span>
            <span v-if="event.candidate?.id">
            с {{ event.candidate.name }} {{ event.candidate.surname }}
            </span>
          </template>
          <template v-else-if="String(event.type) === '2'">
            <span class="bold" style="background: #CED9E3;">Напоминание</span>
            <span v-if="event.candidate?.id">
              {{ event.candidate.name }} {{ event.candidate.surname }}
            </span>
          </template>
          <template v-else>
            <span class="bold">{{ event.title }}</span>
            <span v-if="event.candidate?.id">
              {{ event.candidate.name }} {{ event.candidate.surname }}
            </span>
          </template>
          <EventRating :rating="event.rate" v-if="+event.rate"/>
        </div>
      </div>
      <div class="event-list-item_inner_row" v-if="event.datetime_from">
        <div class="event-list-item_subtitle">
          {{
            $moment(Number(`${event.datetime_from}000`))
              .format('HH:mm')
          }} {{event.datetime_to ? ` - ${$moment(Number(`${event.datetime_to}000`))
          .format('HH:mm')}` : ''}}
        </div>
      </div>
      <div class="event-list-item_inner_row" v-if="event.comment">
        <div class="event-list-item_comment">
          <div class="icon icon-comment"></div>
          <span style="white-space: break-spaces;max-height: 200px;" v-html="event.comment"></span>
        </div>
      </div>
    </div>
    <div class="event-list-item_after">
      <slot name="after" :event="event"></slot>
    </div>
  </div>
</template>

<script>
import EventRating from '@/views/event/EventRating.vue';

export default {
  name: 'EventListItem',
  components: { EventRating },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  methods: {
    itemOnClick() {
      if (['0', '1'].includes(this.event.type)) {
        this.$router.push(`/interview/${this.event.id}`);
      } else {
        this.$eventBus.emit('create-event-modal-open', { event_id: this.event.id });
      }
    },
  },
};
</script>

<style lang="scss">
.event-list-item {
  display: flex;
  cursor: pointer;
  height: 93px;
  &__ended {
    opacity: 0.7;
  }
  &_media {
    flex-shrink: 0;
    margin-right: 16px;

    &_content {
      width: 30px;
      height: 30px;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  &_inner {
    flex: 1;
    min-width: 0;
    width: 100%;

    &_row {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & > * {
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &_after {
    height: 100%;
    align-items: center;
    display: flex;
    .icon {
      font-size: 16px;
      color: rgba(var(--page-color-main-rgb), .25);
    }
  }

  .bold {
    padding: 0 5px;
    border-radius: 3px;
    margin-right: 3px;
  }

  &_title {
    font-size: 14px;
    line-height: 20px;
    display: inline-flex;
    align-items: center;
    .event-rating {
      margin-left: 10px;
    }
  }

  &_subtitle {
    font-size: 14px;
    line-height: 16px;
  }

  &_comment {
    font-size: 13px;
    line-height: 17px;
    color: rgba(var(--page-color-main-rgb), .5);
    max-height: 34px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    .icon {
      color: rgba(var(--page-color-main-rgb), .25);
      font-size: 16px;
      margin-right: 7px;
    }
  }
}
</style>
