<template>
  <div :class="classes" class="tabs">
    <div v-if="navLinks" class="tabbar">
      <div v-for="(tab, index) in tabs"
           :key="index"
           :class="{ 'active': tab.isActive }"
           class="tab-link"
           @click="selectTab(tab.name)">
        <span>{{ tab.textLink || tab.name }}</span>
      </div>
    </div>
    <div class="tabs-list">
      <slot></slot>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Tabs',
  props: {
    navLinks: { default: true },
    history: { default: false },
    classes: String,
  },
  data: () => ({
    tabs: [],
    defaultTabName: '',
  }),
  emits: [
    'select-tab',
  ],
  methods: {
    selectTab(tabName) {
      if (this.history) {
        const { path } = this.$route;
        const hash = `#${tabName}`;
        this.$router.push(path + hash);
      }
      this.activeTab = tabName;
      this.$emit('select-tab', tabName);
    },
  },
  mounted() {
    if (!this.defaultTabName) this.defaultTabName = this.tabs[0].name;
    if (this.history) {
      if (this.$route.hash) {
        this.activeTab = this.$route.hash.replace('#', '');
      } else {
        const { path } = this.$route;
        const hash = `#${this.activeTab.name}`;
        this.$router.replace(path + hash);
      }
    }
  },
  computed: {
    activeTab: {
      set(tabName) {
        this.tabs.forEach((tab) => {
          tab.isActive = (tab.name === tabName);
        });
      },
      get() {
        return this.tabs.find((tab) => tab.isActive);
      },
    },
  },
  watch: {
    $route(to, from) {
      if (this.history) {
        const tab = to.hash.replace('#', '') || this.defaultTabName;
        if (!this.activeTab || this.activeTab.name !== tab) this.activeTab = tab;
      }
    },
  },
};
</script>
<style lang="scss">
.tabbar {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: var(--border-color);
    border-radius: 2px;
    z-index: 1;
  }

  .tab-link {
    margin-right: 18px;
    color: rgba(var(--page-color-main-rgb), .3);
    padding-bottom: 12px;
    position: relative;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }

    &.active, &.router-link-active {
      color: rgba(var(--page-color-main-rgb), 1);

      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: var(--page-color-main);
        border-radius: 2px;
        z-index: 2;
      }
    }
  }
}
</style>
