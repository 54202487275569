<template>
  <div class="analytics-grid" v-if="loading">
    <v-skeleton-loader type="image" height="225" style="border-radius: 12px"/>
    <v-skeleton-loader type="image" height="225" style="border-radius: 12px"/>
    <v-skeleton-loader type="image" height="225" style="border-radius: 12px"/>
    <v-skeleton-loader type="image" height="225" style="border-radius: 12px"/>
  </div>
  <div class="analytics-grid" v-else>
    <AnalyticsGridItem
      title="Собеседований проведено"
      :dates="dates"
      :columns="getColumnsForKey('interviews')"
      :monthIndex="monthIndex"
      @update:monthIndex="value => $emit('update:monthIndex', value)"
    />
    <AnalyticsGridItem
      title="Новых кандидатов"
      :dates="dates"
      :columns="getColumnsForKey('new_candidates')"
      :monthIndex="monthIndex"
      @update:monthIndex="value => $emit('update:monthIndex', value)"
    />
    <AnalyticsGridItem
      title="Средняя стоимость 1 собеседования"
      :dates="dates"
      :columns="getColumnsForKey('average_cost')"
      :monthIndex="monthIndex"
      @update:monthIndex="value => $emit('update:monthIndex', value)"
    />
    <AnalyticsGridItem
      title="В среднем собеседований / в день"
      :dates="dates"
      :columns="getColumnsForKey('average_interview')"
      :monthIndex="monthIndex"
      @update:monthIndex="value => $emit('update:monthIndex', value)"
    />
  </div>
</template>

<script>
import AnalyticsGridItem from '@/views/analytics/AnalyticsGridItem.vue';

export default {
  name: 'AnalyticsGrid',
  components: { AnalyticsGridItem },
  props: {
    analytics: Object,
    dates: Array,
    monthIndex: [String, Number],
    loading: Boolean
  },
  emits: ['update:monthIndex'],
  methods: {
    getColumnsForKey(key) {
      if (!this.analytics[key]) return [];
      return this.analytics[key]
        .map((value, index) => ({
          value,
          date: this.dates[index],
        }));
    },
  },
};
</script>

<style scoped lang="scss">
.analytics-grid {
  display: grid;
  grid-template-columns: 320px 320px;
  grid-gap: 24px;
}
</style>
