<template>
  <div class="mobile-page">
    <div class="mobile-page_title">Для мобильных устройств воспользуйтесь приложением</div>
    <div class="mobile-page_apps">
      <a
        href="https://apps.apple.com/ag/app/rekroo/id1527455770"
        class="mobile-page_apps_item"
      >
        <img src="@/images/app-store-icon.svg" alt="App Store image">
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=su.khromov.rekroo"
        class="mobile-page_apps_item"
      >
        <img src="@/images/google-play-icon.svg" alt="Google Play image">
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobilePage',
};
</script>

<style scoped lang="scss">
.mobile-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  &_title {
    text-align: center;
    font-weight: 600;
    font-size: 21px;
  }
  &_apps {
    margin-top: 30px;
    display: flex;
    width: 100%;
    &_item {
      flex: 1;
      height: 44px;
      margin-right: 10px;
      padding: 0 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #333333;
      border-radius: 60px;
      &:last-child {
        margin-right: 0;
      }
      img {
        width: 100%;
      }
    }
  }
}
</style>
