<template>
  <div :class="classes" class="preloader">
    <div v-if="backdrop" class="preloader-backdrop"/>
    <div :style="{top, left}" class="preloader-icon">
      <svg
        :height="size"
        :width="size"
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="50"
          cy="50"
          fill="none"
          r="35"
          stroke-dasharray="164.93361431346415 56.97787143782138"
          stroke-width="11"
        >
          <animateTransform
            attributeName="transform"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            type="rotate"
            values="0 50 50;360 50 50"
          >
          </animateTransform>
        </circle>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Preloader',
  props: {
    backdrop: {
      type: Boolean,
      default: false,
    },
    top: {
      type: String,
      default: '50%',
    },
    left: {
      type: String,
      default: '50%',
    },
    size: {
      type: String,
      default: '50px',
    },
    duration: {
      type: Number,
      default: 100,
    },
    link: { default: null },
    classes: String,
    modelValue: Boolean,
    component: { default: this },
  },
  emits: [
    'update:component',
  ],
  methods: {
    show(duration = this.duration) {
      if (duration) {
        this.$el.style.transition = `${duration}ms`;
        this.$el.style.opacity = 0;
        this.$el.style.display = 'block';
        setTimeout(() => {
          this.$el.style.opacity = 1;
        });
        setTimeout(() => {
          this.$el.removeAttribute('style');
          this.$el.classList.add('preloader-in');
        }, duration);
      } else {
        this.$el.classList.add('preloader-in');
      }
    },
    hide(duration = this.duration) {
      if (duration) {
        this.$el.style.transition = `${duration}ms`;
        this.$el.style.opacity = 1;
        setTimeout(() => {
          this.$el.style.opacity = 0;
        });
        setTimeout(() => {
          this.$el.removeAttribute('style');
          this.$el.classList.remove('preloader-in');
        }, duration);
      } else {
        this.$el.classList.remove('preloader-in');
      }
    },
  },
  created() {
    this.$emit('update:component', this);
  },
  mounted() {
    if (this.modelValue) this.show(0);
  },
  watch: {
    modelValue(value) {
      if (value) {
        this.show();
      } else {
        this.hide();
      }
    },
  },
};
</script>

<style lang="scss">
.preloader {
  --preloder-color: var(--color-blue);
  z-index: 100;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;

  &.preloader-in {
    display: block;
  }
}

.preloader-backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, .4);
}

.preloader-icon {
  position: absolute;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;

  svg circle {
    stroke: var(--preloder-color);
  }
}
</style>
