<template>
  <div class="info-tip">
    {{text}}
  </div>
</template>

<script>
export default {
  name: 'InfoTip',
  props: ['text']
};
</script>

<style scoped lang="scss">
.info-tip {
  background: #F3DDCB;
  border-radius: 12px;
  width: 100%;
  padding: 16px;
}
</style>