<template>
  <Modal ref="modal" @backdrop-click="cancel" @close-icon-click="cancel" :title="title ? title : ''" :vertical-btns="false">
    <div v-if="message" class="alert-message">{{ message }}</div>
    <template v-slot:actions>
      <div class="cancel-button mr-4" @click="cancel">{{ cancelText }}</div>
      <div class="default-button" @click="accept">{{ acceptText }}</div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue';

export default {
  name: 'Confirm',
  components: {
    Modal,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    acceptText: {
      type: String,
      default: 'Да',
    },
    cancelText: {
      type: String,
      default: 'Нет',
    },
  },
  emits: [
    'accept',
    'cancel',
  ],
  data() {
    return {
      stepIndex: null,
      vacancyIds: [],
    };
  },
  methods: {
    open(data) {
      this.stepIndex = null;
      this.vacancyIds = [];
      if (data && data.stepIndex) this.stepIndex = data.stepIndex;
      if (data && data.vacancyIds) this.vacancyIds = data.vacancyIds;
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    accept() {
      if (this.stepIndex) this.$emit('accept', this.stepIndex);
      else this.$emit('accept', this.vacancyIds);
      this.$refs.modal.close();
    },
    cancel() {
      this.$emit('cancel');
      this.$refs.modal.close();
    },
  },
};
</script>

<style lang="scss">
.alert-message {
  text-align: center;
  margin-bottom: 24px;
}
</style>
