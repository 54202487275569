<template>
  <div class="vacancy-progress-stages">
    <div v-if="Number(vacancy.status) === 1" class="vacancy-progress-stages_points">
      <div
        v-for="(folder, key) in folders"
        :key="key"
        class="vacancy-progress-stages_point"
        :class="{'achieved' : lastChainLinkIndex >= key}"
      />
    </div>
    <div v-else class="vacancy-progress-stages_status" :class="status.color">
      {{ status.text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'VacancyProgressStagesBlock',
  props: {
    vacancy: Object,
  },
  computed: {
    status() {
      return this.$store.state.static.vacancyStatuses
        .find((s) => Number(this.vacancy.status) === Number(s.status));
    },
    folders() {
      const folders = { ...this.vacancy.folders };
      if (!folders) return {};

      delete folders['6'];

      if (this.vacancy.folder_client !== '1') {
        delete folders['3'];
        delete folders['4'];
      }
      if (this.vacancy.folder_offer !== '1') {
        delete folders['5'];
      }
      return folders;
    },
    lastChainLinkIndex() {
      const isActive = (value, i, arr) => {
        if (Number(value)) return true;

        for (let j = i; j < arr.length; j += 1) {
          if (Number(arr[j])) return true;
        }

        return false;
      };
      const folders = Object.values(this.folders);
      return Array.from(folders, (length, i) => isActive(length, i, folders))
        .filter(Boolean).length - 1;
    },
  },
};
</script>

<style lang="scss">

.vacancy-progress-stages {

  &_points {
    display: flex;

    & > * {
      margin-right: 4px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &_point {
    width: 24px;
    height: 4px;
    background: var(--border-color);
    border-radius: 1px;

    &.achieved {
      background: #40A3FF;
      opacity: 0.8;
    }
  }

  &_status {
    padding: 2px 6px;
    display: inline-flex;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: var(--page-color-main);
    background: var(--border-color);
    border-radius: 4px;

    &.red {
      background: #FFE2E6;
    }

    &.green {
      background: #C3F7E8;
    }
  }
}
</style>
