export default {
  methods: {
    formatDate(timestamp, format = '{yyyy}-{mm}-{dd}') {
      if (!timestamp) return;
      const monthNames = ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'];
      const monthNamesGenitive = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
      const wd = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'];
      const weekDay = ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'];
      const weekDayGenitive = ['воскресенье', 'понедельник', 'вторник', 'среду', 'четверг', 'пятницу', 'субботу'];

      const date = new Date(timestamp);
      const replaces = {
        yyyy: date.getFullYear(),
        mm: (`0${(date.getMonth() + 1)}`).slice(-2),
        month: monthNames[date.getMonth()],
        Month: monthNames[date.getMonth()][0].toUpperCase() + monthNames[date.getMonth()].slice(1),
        monthGen: monthNamesGenitive[date.getMonth()],
        MonthGen: monthNamesGenitive[date.getMonth()][0].toUpperCase() + monthNamesGenitive[date.getMonth()].slice(1),
        wd: wd[date.getDay()],
        weekDay: weekDay[date.getDay()],
        weekDayGen: weekDayGenitive[date.getDay()],
        dd: (`0${date.getDate()}`).slice(-2),
        HH: (`0${date.getHours()}`).slice(-2),
        MM: (`0${date.getMinutes()}`).slice(-2),
        SS: (`0${date.getSeconds()}`).slice(-2),
      };

      let result = format;
      Object.entries(replaces)
        .forEach(([key, value]) => {
          const reg = new RegExp(`{${key}}`, 'g');
          result = result.replace(reg, value);
        });

      return result;
    },

    datetimeHoursMinutes(str) { // of HH:MM
      const hh = str.split(':')[0];
      const mm = str.split(':')[1];
      return (Number(hh) * 60 * 60 * 1000) + (Number(mm) * 60 * 1000);
    },
    sumSeparator(sum) {
      return String(sum)
        .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 '); // 1 000 000 000
    },
    gtmEvent(event) {
      try {
        window.dataLayer.push({event})
      } catch (e) {
        console.error('gtmEvent exception', e);
      }
    },
    getMasksSumSeparator(maxLength = 10) {
      const mask = (sum) => sum.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')
        .replace(/\d/g, '#');

      const arr = [];
      let i = 1;
      while (i <= Number(maxLength)) {
        const currentSum = Array.from(Array(i), () => '9')
          .join('');
        arr.push(mask(currentSum));
        i += 1;
      }
      return arr; // ['#', '##', '###', '# ###', '## ###', '### ###']
    },
    convertPhoneStr(phone) {
      if (!phone) return '';

      let str = phone.replace(/[+]|[ ]|[-]/g, '');
      if (phone.length === 11) {
        str = str.replace(/(\d)(\d\d\d)(\d\d\d)(\d\d)(\d\d)/, '$1 ($2) $3-$4-$5');
      } else if (phone.length === 12) {
        str = str.replace(/(\d\d\d)(\d\d)(\d\d\d)(\d\d)(\d\d)/, '$1 ($2) $3-$4-$5');
      }
      str = `+${str}`;
      return str;
    },
    declOfNum(number, titles) {
      if (typeof titles === 'string') {
        titles = titles.replace(', ', ',')
          .split(',');
      }

      const cases = [2, 0, 1, 1, 1, 2];

      return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
    },
    validatePhone(value) {
      return true;
      return /^\+\d{1,3}\s?\(\d{3}\)\s?\d{3}(-\d{2}){2}$/.test(value);
    },
    scrollTo(element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    },
    chainPromises(promises) {
      const reducer = (chain, current) => chain.then((results) => {
        const promise = typeof current === 'function' ? current() : current;
        return promise.then((currentResult) => [...results, currentResult]);
      });

      return promises.reduce(reducer, Promise.resolve([]));
    },
    getUrlLocalFile(file) {
      return URL.createObjectURL(file);
    },
    flatDeep(arr, d = 1) {
      return d > 0
        ? arr.reduce((acc, val) => acc.concat(Array.isArray(val) ? this.flatDeep(val, d - 1) : val), [])
        : arr.slice();
    },
  },
};
