<template>
  <Modal ref="modal" class="create-event-modal" @backdrop-click="close" @close-icon-click="close"
         :title="modalTitle ? modalTitle : formData.id ? 'Редактировать событие' : `Новое ${eventsType.find(event=>event.value === formData.type).title.toLowerCase()}`"
         :width="400"
  >
    <Loader v-if="loadingEvent"/>
    <div v-else class="event-form pt-4">
      <v-row>
        <v-select
          class="col-6"
          v-model="formData.type"
          label="Тип события"
          :items="eventsType"
          item-value="value"
          item-text="title"
          append-icon=""
          persistent-placeholder
          :disabled="!!formData.id"
        />
        <DateMenu :value="formData.date" label="Дата" :error-text="dateError"
                  @update="formData.date = $event; $forceUpdate(); dateError = ''"/>
      </v-row>

      <v-autocomplete
        v-model="formData.candidate"
        :loading="loadingCandidates"
        :hide-details="!candidateError"
        label="Кандидат"
        placeholder="Искать по имени..."
        :items="candidates"
        :search-input.sync="search"
        no-data-text="Кандидат не найден"
        return-object
        hide-no-data
        item-text="name"
        persistent-placeholder
        append-icon=""
        :error-messages="candidateError"
        class="candidate-autocomplete-input"
      >
        <template v-slot:item="{item}">
          <div class="candidates-list-item">
            <div class="candidates-list-item_media">
              <CandidateMedia :candidate="item"/>
              <CandidateRating :candidate="item"/>
            </div>
            <div class="candidates-list-item_inner">
              <div class="candidates-list-item_title">{{ item.name }}</div>
              <div class="candidates-list-item_subtitle">{{ item.surname }}</div>
            </div>
          </div>
        </template>
        <template v-slot:selection="{item}">
          <div class="candidates-list-item">
            <div class="candidates-list-item_media">
              <CandidateMedia :candidate="item"/>
              <CandidateRating :candidate="item"/>
            </div>
            <div class="candidates-list-item_inner">
              <div class="candidates-list-item_title">{{ item.name }}</div>
              <div class="candidates-list-item_subtitle">{{ item.surname }}</div>
            </div>
          </div>
        </template>
      </v-autocomplete>
      <v-textarea
        class="mt-6"
        v-if="formData.type === '2'"
        v-model="formData.comment"
        label="Что сделать"
        type="textarea"
        auto-grow
        rows="2"
        placeholder="Например, позвонить кандидату"
        hide-details
        persistent-placeholder
        :error-messages="commentError"
        @input="commentError = ''"
      />
      <v-row class="mt-6">
        <v-text-field
          class="col-6"
          v-maska="'##:##'"
          v-model="formData.datetime_from"
          hide-details="auto"
          placeholder="__:__"
          :label="`Время ${formData.type === '2' ? 'напоминания': 'начала'}`"
          persistent-placeholder
          :error-messages="datetimeFromError"
          @input="datetimeFromError = ''"
        />
        <v-text-field
          v-if="formData.type !== '2'"
          class="col-6"
          v-maska="'##:##'"
          v-model="formData.datetime_to"
          hide-details="auto"
          placeholder="__:__"
          label="Время окончания"
          persistent-placeholder
          :error-messages="datetimeToError"
          @input="datetimeToError = ''"
        />
      </v-row>
      <div v-if="formData.type !== '2'"
        class="mt-5 form-item-script" @click="$refs['select-scripts-modal'].open()">
        <div class="form-item-script_before">
          <div class="icon icon-script"></div>
          <div class="form-item-script_title">Скрипт</div>
        </div>
        <div v-if="Object.keys(formData.form).length && !['', '0'].includes(formData.form.id)" class="form-item-script_after">
          <div class="form-item-script_value">{{ formData.form.title }}</div>
          <div class="icon icon-close" @click.stop="formData.form = {}"/>
        </div>
        <div class="form-item-script_after empty" v-else>
          <div class="form-item-script_value">Не прикреплен</div>
          <div class="icon icon-arrow flip-horizontally"/>
        </div>
      </div>
      <v-row class="my-6">
        <v-select
          v-if="formData.type !== '2'"
          class="col-6 align-end"
          v-model="formData.notify"
          name="notify"
          label="Уведомить"
          type="select"
          item-value="value"
          item-text="title"
          :items="[{value: 0, title: 'В момент события'}, {value: 900, title: 'За 15 мин'}, {value: 3600, title: 'За 1 час'}, {value: 7200, title: 'За 2 часа'}, {value: -1, title: 'Не уведомлять'}]"
          hide-details
          persistent-placeholder
          append-icon=""
        />
        <v-text-field
          class="col-6 align-end"
          v-model="formData.event_link"
          hide-details
          placeholder="Нет ссылки"
          label="Ссылка на событие"
          v-if="formData.type === '1'"
          persistent-placeholder
        />
        <div v-if="formData.type === '0'" class="form-field place-form-input col-6" @click="$refs['select-places-modal'].open()">
          <div class="form-field_title">Место</div>
          <div class="form-field_content">
            <div class="form-field_content_inner" v-if="Object.keys(formData.place).length && !['', '0'].includes(formData.place.id)">
              <span class="form-field_input">{{ formData.place.title }}</span>
              <div class="icon icon-close" @click.stop="formData.place = {}"/>
            </div>
            <div class="form-field_content_inner empty d-flex justify-lg-space-between align-center" v-else>
              <span class="form-field_input">Не указано</span>
              <div class="icon icon-arrow flip-horizontally"/>
            </div>
          </div>
        </div>
      </v-row>
    </div>
    <template v-slot:actions>
      <v-btn color="primary" @click="setEvent()" :loading="loading">Готово</v-btn>
    </template>
    <SelectPlacesModal ref="select-places-modal" @submit="onSelectPlace" :multiple="false"/>
    <SelectScriptsModal ref="select-scripts-modal" @submit="onSelectScript" :multiple="false"/>
    <ConflictDate ref="conflict_date" @callback="setEvent"/>
  </Modal>
</template>

<script>

import SelectPlacesModal from '@/views/places/SelectPlacesModal';
import SelectScriptsModal from '@/views/scripts/SelectScriptsModal';
import ConflictDate from '@/views/event/create/ConflictDate';
export default {
  name: 'CreateEventModal',
  components: {
    ConflictDate,
    SelectPlacesModal,
    SelectScriptsModal
  },
  data() {
    return {
      dateError: '',
      candidateError: '',
      datetimeFromError: '',
      datetimeToError: '',
      loading: false,
      formData: {
        type: '0',
        form: {},
        place: {},
        candidate: {},
        vacancy: {},
        notify: '0',
        datetime_from: '12:00',
      },
      modalTitle: '',
      loadingCandidates: false,
      candidates: [],
      search: null,
      dateMenu: false,
      eventsType: [{value: '0', title: 'Собеседование'}, {value: '1', title: 'Собеседование онлайн'}, {value: '2', title: 'Напоминание'}],
      commentError: '',
      loadingEvent: false,
    };
  },
  emits: ['created'],
  methods: {
    open(data) {
      this.search = null;
      this.loadingCandidates = false;
      this.dateError = '';
      this.candidateError = '';
      this.datetimeFromError = '';
      this.datetimeToError = '';
      this.formData = {
        type: '0',
        form: {},
        place: {},
        candidate: {},
        vacancy: {},
        notify: '0',
        datetime_from: '12:00',
      }
      this.candidates = [];
      if (data.type) {
        this.formData.type = data.type;
      }
      if (data.vacancy) this.formData.vacancy = data.vacancy;

      if (data.candidate) {
        this.formData.candidate = data.candidate;
        this.candidates.push(data.candidate);
        if (data.candidate.vacancy) this.formData.vacancy = data.candidate.vacancy;
      }
      if (data.event_id) {
        this.loadEvent(data.event_id);
      }
      if (data.title) this.modalTitle = data.title;
      if (data.date ) this.formData.date = this.$moment(data.date).format('YYYY-MM-DD');
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    onSelectPlace(place) {
      this.formData.place = place;
    },
    onSelectScript(script) {
      this.formData.form = script;
    },
    querySelections(v) {
      this.loadingCandidates = true;
      this.$server.request2('candidate/get', { query: v }, (data) => {
        this.candidates = data.response;
        this.loadingCandidates = false;
      });
    },
    loadEvent(event_id) {
      this.loadingEvent = true;
      this.$server.request2(`event/get/${event_id}`, {}, (data)=>{
        this.loadingEvent = false;
        this.formData = data.response;
        if ( Object.keys(data.response.candidate).length) {
          this.candidates.push(data.response.candidate);
        }
        this.formData.datetime_from = data.response.datetime_from ? this.$moment.unix(data.response.datetime_from).format('HH:mm') : '';
        this.formData.datetime_to = !['', '0'].includes(data.response.datetime_to) ? this.$moment.unix(data.response.datetime_to).format('HH:mm') : '';
      }, ()=>{
        this.loadingEvent = false;
      })
    },
    setEvent(match = null) {
      if (this.prepareData() === 'error') return;
      const dataToRequest = { ...this.prepareData()};
      if (match !== null) dataToRequest.match = match;
      this.loading = true;
      let url = this.formData.id ? `event/update/${this.formData.id}` : 'event/create';
      this.$server.request2(url, dataToRequest, (data) => {
        this.loading = false;
        if (Object.keys(this.formData.candidate).length > 0 && this.$store.state.user.settings && !this.$store.state.user.settings.show_notify_contacts) {
          this.$eventBus.emit('candidate-contacts-modal-open', {
            candidateId: this.formData.candidate.id,
            phone: this.formData.candidate.phone,
            isNotify: true,
            event: data.response,
            showCheckbox: true
          });
        }
        if (this.$route.path.includes('candidate')) this.$eventBus.emit('event-created-in-candidate');
        this.$eventBus.emit('event-created');
        this.$emit('created', dataToRequest.date);
        this.close();
      }, (data) => {
        if (data.info) {
          this.$refs.conflict_date.open({newEvent: this.formData, existEvent: data.info.event});
        }
        this.loading = false;
      });
    },
    prepareData() {
      let preparedData = { ...this.formData };

      if (!preparedData.date) this.dateError = 'Укажите дату';
      if (Object.keys(this.formData.candidate).length === 0) this.candidateError = 'Выберите кандидата';

      if (!preparedData.date || (Object.keys(this.formData.candidate).length === 0)) return 'error';

      preparedData.date = this.$moment(preparedData.date).format('YYYY-MM-DD');

      if (preparedData.candidate.id) preparedData.candidate = preparedData.candidate.id;
      else preparedData.candidate = '';
      if (preparedData.place.id) preparedData.place = preparedData.place.id;
      else preparedData.place = '';
      if (preparedData.form.id) preparedData.form = preparedData.form.id;
      else preparedData.form = '';
      if (preparedData.vacancy.id) preparedData.vacancy = preparedData.vacancy.id;
      else preparedData.vacancy = '';

      if (this.formData.datetime_from) {
        let checkedDatetimeFrom = this.formData.datetime_from.split(':');
        if (checkedDatetimeFrom[0]*1 > 23 || checkedDatetimeFrom[1]*1>59) {
          this.datetimeFromError = 'Неверно указано время';
          return 'error';
        }
        let date_from = `${this.$moment(preparedData.date)
          .format('YYYY-MM-DD')}T${this.formData.datetime_from}`;
        preparedData.datetime_from = this.$moment(date_from)
          .unix();
      } else {
        this.datetimeFromError = 'Укажите время';
        return 'error';
      }
      if (this.formData.datetime_to) {
        let checkedDatetimeTo = this.formData.datetime_to.split(':');
        if (checkedDatetimeTo[0]*1 > 23 || checkedDatetimeTo[1]*1>59) {
          this.datetimeToError = 'Неверно указано время';
          return 'error';
        }
        let date_to = `${this.$moment(this.formData.date)
          .format('YYYY-MM-DD')}T${this.formData.datetime_to}`;
        preparedData.datetime_to = this.$moment(date_to)
          .unix();
      }

      if (preparedData.notify >=0) {
        preparedData.datetime_notify = preparedData.datetime_from - preparedData.notify;
      }


      return preparedData;
    },
  },
  watch: {
    search(val) {
      this.candidateError = '';
      this.querySelections(val);
    },
  },
};
</script>
<style lang="scss">


.event-form {
  display: flex;
  flex-direction: column;


  .candidate-autocomplete-input {
    .v-select__selections {
      overflow: hidden;
    }
    .candidates-list-item_title, .candidates-list-item_subtitle {
      white-space: nowrap;
    }
    .candidates-list-item_subtitle {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .timepicker {
    align-items: flex-end;
  }

  .form-item-script {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 10px;

    &_title {
      font-size: 14px;
      line-height: 20px;
      color: rgba(var(--page-color-main-rgb), .5);
    }

    &_value {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      min-width: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &_before {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      min-width: 0;
      margin-right: 15px;

      .icon {
        color: rgba(var(--page-color-main-rgb), .25);
        margin-top: 2px;
        margin-right: 12px;
        font-size: 24px;
      }
    }

    &_after {
      display: flex;
      align-items: center;
      min-width: 0;

      .icon {
        cursor: pointer;
        margin-left: 8px;
        font-size: 16px;
      }

      &.empty {
        color: rgba(var(--page-color-main-rgb), .25);
      }
    }
  }

  .place-form-input {
    min-width: 0;

    .form-field_content_inner {
      border-bottom: 1px solid var(--border-color);
      &.empty {
        color: rgba(var(--page-color-main-rgb), 0.25);
        .form-field_input {
          color: inherit;
        }
      }
    }
    .form-field_input {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      border-bottom: none;
    }
    .form-field_content {
      cursor: pointer;
    }

    .icon-close {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
      cursor: pointer;
    }
  }

  .candidates-list-item {
    cursor: pointer;
    display: flex;

    &_media {
      position: relative;
      width: 32px;
      height: 32px;
      padding: 12px 0;
      box-sizing: content-box;
      margin-right: 18px;
      flex-shrink: 0;

      .candidate-media {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .candidate-rating {
        position: absolute;
        bottom: 12px;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }

    &_inner {
      width: 100%;
      padding: 15px 0;
      display: flex;
    }

    &_title {
      font-size: 14px;
      line-height: 20px;
      margin-right: 4px;
    }

    &_subtitle {
      font-size: 14px;
      line-height: 20px;
    }

  }

}
</style>
