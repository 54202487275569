<template>
  <div class="item-comment" v-if="item.last_comment">
    <div class="icon icon-comment mr-1"></div>
    <div class="item-comment-text"
         :class="{'in-shared-table': sharedTable}"
         v-html="item.last_comment"></div>
  </div>
</template>

<script>
export default {
  name: 'ItemComment',
  props: ['item', 'sharedTable'],
};
</script>

<style scoped lang="scss">
.item-comment {
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 17px;
  max-width: 400px;
  color: rgba(var(--page-color-main-rgb), 0.5);
  &-text {
    max-height: 17px;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    &.in-shared-table {
      max-height: 50px;
      max-width: 400px;
    }
  }
}
</style>
