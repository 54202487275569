<template>
  <div v-show="isActive" :class="classes" class="tab">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Tab',
  props: {
    name: { required: true },
    textLink: {},
    selected: { default: false },
    classes: String,
  },
  emits: [
    'created',
  ],
  data: () => ({
    isActive: false,
  }),
  mounted() {
    this.isActive = this.selected;
  },
  created() {
    this.$parent.tabs.push(this);
    if (this.selected) this.$parent.defaultTabName = this.name;
    this.$emit('created', this);
  },
};
</script>
