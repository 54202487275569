export default {
  namespaced: true,
  state: {
    eventIds: [],
  },
  mutations: {
    setEventIds(state, events) {
      state.eventIds = events.map(({ id }) => id);
    },
  },
  getters: {
    getEvents(state, getters, rootState, rootGetters) {
      return rootGetters.getEvents(({ id }) => state.eventIds.includes(id));
    },
  },
};
