<template>
  <Modal ref="modal" content-class="conflict-event-modal" @backdrop-click="close" @close-icon-click="close"
         title="На это время уже есть собеседование"
         :width="600"
         :vertical-btns="true"
  >
    <v-row v-if="Object.keys(existEvent).length" class="pa-0">
      <v-col class="pa-0 align-center d-flex flex-column">
        <CandidateMedia :candidate="existEvent.candidate" :size="56" class="mb-2"/>
        <div class="event-label mb-4">Существующий</div>
        <div class="candidate-name mb-4">{{ existEvent.candidate.name }} {{ existEvent.candidate.surname }}</div>
        <div class="event-time">
          {{
            $moment(Number(`${existEvent.datetime_from}000`))
              .format('HH:mm')
          }} {{ existEvent.datetime_to && !['', '0'].includes( existEvent.datetime_to) ? ` - ${$moment(Number(`${existEvent.datetime_to}000`))
          .format('HH:mm')}` : ''}}
        </div>
      </v-col>
      <v-col class="pa-0 align-center d-flex flex-column">
        <CandidateMedia :candidate="newEvent.candidate" :size="56" class="mb-2"/>
        <div class="event-label mb-4">Новый</div>
        <div class="candidate-name mb-4">{{ newEvent.candidate.name }} {{ newEvent.candidate.surname }}</div>
        <div class="event-time">
          {{ newEvent.datetime_from }} {{newEvent.datetime_to ? ` - ${newEvent.datetime_to}` : ''}}
        </div>
      </v-col>
    </v-row>
    <template v-slot:actions>
      <v-btn color="primary" class="mb-4" elevation="0" depressed @click="match(1)">Оставить оба</v-btn>
      <v-btn elevation="0" depressed @click="match(0)">Заменить</v-btn>
      <v-btn elevation="0" depressed @click="close" class="mt-4">Поменять время</v-btn>
    </template>
  </Modal>
</template>

<script>
import CandidateMedia from '@/views/candidate/CandidateMedia';
import Modal from '@/components/Modal';
export default {
  name: 'ConflictDate',
  components: {
    CandidateMedia,
    Modal
  },

  data() {
    return {
      existEvent: {},
      newEvent: {}
    };
  },
  emits: ['callback'],
  methods: {
    open(params) {
      this.clearData();
      this.$refs.modal.open();
      this.existEvent = params.existEvent;
      console.log(this.existEvent);
      this.newEvent = params.newEvent;
    },
    close() {
      this.clearData();
      this.$refs.modal.close();
    },
    clearData() {
      this.existEvent = {};
      this.newEvent = {};
    },
    match(match) {
      this.$emit('callback', match);
      this.close();
    }
  }
};
</script>

<style lang="scss">
.conflict-event-modal {
  .candidate-name {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  .event-label {
    background: #E9E9E9;
    border-radius: 4px;
    padding: 4px 8px;
    width: fit-content;
  }
}

</style>