<template>
  <div class="event-list">
    <template v-if="groupByDates">
      <div class="event-list_section" v-for="(arr, key) in eventsByDate" :key="key">
        <div class="event-list_section_head">{{
            $moment(key)
              .locale('ru')
              .format('DD MMMM, ddd')
          }}
        </div>
        <ul>
          <li v-for="event in arr" :key="event.id">
            <EventListItem :event="event">
              <template #after="{ event }">
                <slot name="itemAfter" :event="event"/>
              </template>
            </EventListItem>
          </li>
        </ul>
      </div>
    </template>
    <template v-else>
      <ul>
        <li v-for="event in events" :key="event.id">
          <EventListItem :event="event">
            <template #after="{ event }">
              <slot name="itemAfter" :event="event"/>
            </template>
          </EventListItem>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
import EventListItem from '@/views/event/EventListItem.vue';

export default {
  name: 'EventList',
  components: { EventListItem },
  props: {
    events: {
      events: Array,
    },
    groupByDates: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    eventsByDate() {
      const map = new Map();
      this.events.forEach((item) => {
        let date;
        if (item.datetime_from) {
          date = this.$moment(Number(`${item.datetime_from}000`))
            .format('YYYY-MM-DD');
        } else {
          date = item.date;
        }

        if (map.get(date)) {
          map.get(date)
            .push(item);
          return;
        }
        map.set(date, [item]);
      });
      return Object.fromEntries(map.entries());
    },
  },
};
</script>

<style lang="scss">
.event-list {
  width: 100%;
  max-width: 580px;
  position: relative;

  &_section {
    margin-bottom: 40px;

    &_head {
      font-size: 16px;
      line-height: 20px;
      color: rgba(var(--page-color-main-rgb), .4);
      margin-bottom: 12px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      border-top: 1px solid var(--border-color);

      &:last-child {
        border-bottom: 1px solid var(--border-color);
      }
    }
  }
}
</style>
