import functions from '@/tests/functions';

export default {
  async openProfile() {

    functions.querySelector(`.main-sidebar .user-link-wrap a`).click();
    await functions.wait(700);
    let dataBefore = [
      functions.querySelector('.profile-user-name').innerText,
      functions.querySelector('.profile-user-phone').innerText,
      functions.querySelector('.profile-user-email').innerText,
    ];
    functions.querySelector('.profile-user-data_edit-button')
      .click();
    await functions.wait(300);



    let modal = functions.getModal();
    if (functions.querySelector('.modal-title', modal).innerText !== 'Редактировать информацию') throw 'Заголовок модалки не "Редактировать информацию"';

    let userData = [
      functions.genLongText(1),
      functions.genLongText(1),
      functions.generatePhone(),
      Math.floor(Math.random() * 10000) + '@gmail.com',
      functions.date(Math.floor(Math.random() * 10)),
    ];

    let form = functions.querySelector('.edit-user-form', modal);
    await functions.fillForm(form, userData);

    functions.querySelector('.v-btn.primary', modal).click();
    await functions.wait(700);
    if (dataBefore[0].toLowerCase() === `${userData[0]} ${userData[1]}`.toLowerCase()) throw 'Имя и фамилия юзера не изменились';
    if (dataBefore[2] === userData[3]) throw 'имаил юзера не изменился';
    if (userData[0].toLowerCase() !== functions.querySelector('.user-link-wrap .nav-item_text').innerText.toLowerCase()) throw 'Имя юзера в сайдбаре не изменилось'
  }
};