<template>
  <Modal ref="modal">
    <div class="completion-modal new-probation-date-modal">
      <img
        src="@/images/pictures/picture-4.svg"
        alt="vacancy closed picture"
        class="completion-modal_icon-1"
      >
      <div class="completion-modal_check">
        <div class="icon icon-check"></div>
      </div>
      <NoResults>
        <template #title>Срок окончания перенесен</template>
        <template #text>
          на {{
            $moment(dateProbation)
              .format('DD.MM.YYYY')
          }}
        </template>
      </NoResults>
    </div>
    <template v-slot:actions>
      <div class="default-button" @click="close()">Готово</div>
    </template>
  </Modal>
</template>

<script>
export default {
  name: 'NewProbationDateModal',
  props: ['dateProbation'],
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    }
  }
};
</script>

<style scoped>

</style>