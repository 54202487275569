export default {
  namespaced: true,
  state: {
    currentIds: [],
    archiveIds: [],
  },
  mutations: {
    setCurrentIds(state, vacancies) {
      state.currentIds = vacancies.map(({ id }) => id);
    },
    setArchiveIds(state, vacancies) {
      state.archiveIds = vacancies.map(({ id }) => id);
    },
  },
  getters: {
    getCurrent(state, getters, rootState, rootGetters) {
      return rootGetters.getVacancies(({ id }) => state.currentIds.includes(id));
    },
    getArchive(state, getters, rootState, rootGetters) {
      return rootGetters.getVacancies(({ id }) => state.archiveIds.includes(id));
    },
  },
};
