<template>
  <div
    class="vacancy-item"
    @click="$router.push(`/vacancies/vacancy/${vacancy.id}/`)"
  >
    <div class="vacancy-item_inner">
      <div class="vacancy-item_subtitle" v-if="vacancy.company">{{ vacancy.company }}</div>
      <div class="vacancy-item_title">{{ vacancy.title }}</div>
      <VacancyProgressStagesBlock :vacancy="vacancy"/>
    </div>
    <div class="vacancy-item_after">
      <div class="vacancy-item_candidates">
        <template v-if="vacancy.candidates.length">
          <div class="vacancy-item_candidates_list">
            <CandidateMedia
              v-for="candidate in firstCandidates"
              :key="candidate.id"
              :candidate="candidate"
            />
          </div>
          <div class="vacancy-item_candidates_text">
            всего <span class="count">{{ vacancy.candidates.length }}</span>
          </div>
        </template>
        <span v-else class="vacancy-item_candidates_text">нет кандидатов</span>
      </div>
    </div>
  </div>
</template>

<script>
import VacancyProgressStagesBlock from '@/views/vacancy/VacancyProgressStagesBlock';
export default {
  name: 'DashboardVacancyItem',
  components: { VacancyProgressStagesBlock },
  props: {
    vacancy: Object,
  },
  computed: {
    firstCandidates() {
      return this.vacancy.candidates.slice(0, 6);
    },
  },
};
</script>

<style scoped lang="scss">
.vacancy-item {
  display: flex;
  cursor: pointer;

  &_inner {
    width: 100%;
    padding: 28px 0;
    display: flex;
    flex-direction: column;
    min-width: 0;

    & > * {
      margin-bottom: 4px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &_after {
    flex-shrink: 0;
    padding: 40px 0;
  }

  &_title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    max-width: 450px;
  }

  &_subtitle {
    font-size: 14px;
    line-height: 16px;
    color: rgba(var(--page-color-main-rgb), .35);
  }

  .vacancy-progress-stages {
    margin-top: 6px;
  }

  &_candidates {
    display: flex;
    align-items: center;
    cursor: pointer;

    &_text {
      white-space: nowrap;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: rgba(var(--page-color-main-rgb), .25);

      .count {
        color: var(--page-color-main);
      }
    }

    &_list {
      display: flex;
      align-items: center;
      margin-right: 22px;

      & > * {
        margin-right: -10px;
      }
    }

    .candidate-media {
      width: 32px;
      height: 32px;
      border: 2px solid #FFFFFF;
    }
  }
}
</style>
