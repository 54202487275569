<template>
  <Modal ref="modal" :title="formData.title">
    <div class="note-examples">
      <div class="note-examples_title">Например:</div>
      <div class="note-examples_list">
        <div
          v-for="(example, index) in examples"
          :key="index" class="note-examples_item"
          @click="formData.comment = example"
        >
          <span>{{ example }}</span>
        </div>
      </div>
    </div>
    <v-textarea v-model="formData.comment" name="comment" placeholder="Комментарий..." rows="2"/>
    <div class="note-remind">
      <v-checkbox
        v-model="remind"
        label="Напомнить"
      />
      <div class="note-datetime" v-show="remind">
        <DateMenu :value="formData.date" label=""
                  @update="formData.date = $event" :full-width="true"/>
        <v-text-field
          v-model="formData.time"
          placeholder="__:__"
          :error-messages="timeError"
          persistent-placeholder
          v-mask="['##:##']"
          hide-details="auto"
        />
      </div>
    </div>
    <template v-slot:actions>
      <div class="default-button" @click="createNote">Готово</div>
    </template>
  </Modal>
</template>

<script>
export default {
  name: 'CandidateNoteModal',
  data() {
    return {
      dateError: null,
      timeError: null,
      dateMenu: false,
      formData: {
        comment: '',
        type: '3',
        date: Date.now(),
        time: '',
        datetime_notify: '',
      },
      remind: null,
      examples: [
        'Не удалось дозвониться',
        'Попросил перезвонить',
        'Позвоню когда будет настроение',
      ],
    };
  },
  methods: {
    toResponse() {
      const formData = { ...this.formData };
      if (this.remind) {
        formData.type = '2';

        formData.datetime_notify = this.$moment(`${this.$moment(formData.date)
          .format('YYYY-MM-DD')} ${formData.time}`)
          .unix();
      }
      return formData;
    },
    createNote() {
      if (this.remind && !this.formData.date) {
        this.dateError = 'Укажите дату';
        return;
      }
      if (this.remind && !this.formData.time) {
        this.timeError = 'Укажите время';
        return;
      }
      this.$server.request2('event/create', this.toResponse(), () => {
        this.clearForm();
        this.$eventBus.emit('event-created-in-candidate');
        this.$refs.modal.close();
      });
    },
    clearForm() {
      this.formData = {};
      this.remind = false;
    },
    open(data) {
      this.dateError = null;
      this.timeError = null;
      this.dateMenu = false;
      this.formData = {
        comment: '',
        type: '3',
        date: Date.now(),
        time: '',
        datetime_notify: '',
      };
      this.remind = null;
      this.formData = { ...this.formData, ...data };
      this.$refs.modal.open();
    },
  },
  watch: {
    'formData.date': function () {
      this.dateError = null;
    },
    'formData.time': function () {
      this.timeError = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-title {
  text-align: left;
}

.note-examples {
  font-size: 13px;
  line-height: 17px;

  &_title {
    margin-bottom: 10px;
    color: rgba(var(--page-color-main-rgb), 0.4);
  }

  &_item {
    margin-bottom: 8px;
    color: var(--color-blue);
    cursor: pointer;
  }
}

.note-filed {
  margin-bottom: 10px;
}

.note-remind {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .form-field {
    width: 150px;
  }

  .note-datetime {
    .timepicker {
      display: flex;
    }
  }
}

.note-checkbox-wrap {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;

  .checkbox {
    margin-right: 12px;
  }
}
</style>
