<template>
  <Modal ref="modal" content-class="edit-resume-modal"
         :backdrop-click-to-close="false"
         @close-icon-click="close"
         :close-on-icon-click-special="true"
         title="Редактировать резюме"
         :vertical-btns="false"
         @backdrop-click="()=>{}"
  >
    <div class="resume-container" :contenteditable="editable" v-html="editedResume" :key="resume_key"></div>
    <template v-slot:actions>
      <v-btn width="200" depressed :loading="saving" :ripple="false" color="primary" class="mr-4" @click="save">Сохранить</v-btn>
      <v-btn width="200" depressed :ripple="false" @click="openConfirmModal">Отменить изменения</v-btn>
    </template>
    <Confirm ref="confirm_modal" @accept="cancel" title="Отменить изменения?" accept-text="Да" cancel-text="Нет"/>
    <Confirm ref="confirm_exit_without_saving_modal" title="Есть несохраненные изменения. Сохранить перед закрытием?" @accept="save" cancel-text="Нет, закрыть" @cancel="closeWithoutSaving"/>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
import Confirm from '@/components/Confirm';

export default {
  name: 'EditResumeModal',
  components: {
    Confirm,
    Modal,
  },
  emits: ['on-update'],
  data() {
    return {
      editedResume: '',
      loadedResume: '',
      loadedResumeText: '',
      saving: false,
      candidate_id: '',
      editable: true,
      resume_key: 0,
    };
  },
  methods: {
    open(params) {
      this.editedResume = '';
      this.loadedResume = '';
      this.loadedResumeText = '';
      this.editable = true;
      this.resume_key = 0;
      this.loadedResume = params.resume;
      this.editedResume = params.resume;
      this.resume_key = Math.floor(Math.random() * (10000 - 1 + 1) + 1);
      this.candidate_id = params.candidate_id;
      this.$refs.modal.open();
      setTimeout(()=>{
        this.loadedResumeText = document.querySelector('.edit-resume-modal .resume-container').innerText;
      });
    },
    close() {
      if (this.loadedResumeText !== document.querySelector('.edit-resume-modal .resume-container').innerText) {
        this.$refs.confirm_exit_without_saving_modal.open();
        return;
      }
      this.$refs.modal.close();
    },
    save() {
      this.saving = true;
      this.editable = false;
      const doctype = `<!DOCTYPE html>\\n<html xmlns=\\"http://www.w3.org/1999/xhtml\\" lang=\\"\\" xml:lang=\\"\\">\\n<head>`;
      const htmlToRequest = (doctype + document.querySelector('.edit-resume-modal .resume-container').innerHTML).replaceAll('\\n', '');
      this.$server.request2(`candidate/update/${this.candidate_id}`, { resume_html: htmlToRequest }, (data) => {
        this.saving = false;
        this.$eventBus.emit('resume-update');
        this.$emit('on-update');
        this.$refs.modal.close();
      }, () => {
        this.saving = false;
      });
    },
    openConfirmModal() {
      this.$refs.confirm_modal.open();
    },
    cancel() {
      this.editedResume = this.loadedResume;
      this.resume_key++;
      this.$forceUpdate();
    },
    closeWithoutSaving() {
      this.$refs.confirm_exit_without_saving_modal.close();
      this.$refs.modal.close();
    }
  }
};
</script>

<style lang="scss">
.edit-resume-modal {
  .resume-container {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>