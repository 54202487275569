export default {
  namespaced: true,
  state: {
    allIds: [],
  },
  mutations: {
    setAllIds(state, ids) {
      state.allIds = ids;
    },
    addIds(state, ids) {
      state.allIds.push(...ids);
    },
  },
  getters: {
    getAll(state, getters, rootState, rootGetters) {
      return state.allIds.map((id) => rootGetters.getCandidate(id)).filter(Boolean);
    },
  },
};
