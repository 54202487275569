<template>
  <div :class="classes" class="candidate-media"
       :style="size ? `width: ${size}px; height: ${size}px` : ''"
       v-if="candidate && (candidate.image && candidate.image.url || candidate.name || candidate.candidate)">
    <div v-if="candidate.image && candidate.image.url"
         :style="{'background-image':`url(${candidate.image.url})`, 'background-position':cssImagePosition, 'background-size':'cover'}" class="candidate-media_content"></div>
    <!--    <img v-if="candidate.image && candidate.image.url" :style="{'object-position': cssImagePosition}" :src="candidate.image.url" alt="candidate image"-->
    <!--         class="candidate-media_content">-->
    <div v-else class="candidate-media_content no-image"
         :style="size ? `width: ${size}px; height: ${size}px` : ''"
    >
      {{ candidate.candidate ? candidate.candidate.name[0].toUpperCase() : candidate.name[0].toUpperCase() }}
      <span v-if="candidate.candidate">{{ candidate.candidate.surname ? candidate.candidate.surname[0].toUpperCase() : '' }}</span>
      <span v-else>{{ candidate.surname ? candidate.surname[0].toUpperCase() : '' }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CandidateMedia',
  props: {
    classes: String,
    parallax: Boolean,
    candidate: Object,
    size: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      cssImagePosition: 'center'
    };
  },
  methods: {
    onMove(event) {
      let windowCenter = {
        x: window.innerWidth / 2,
        y: window.innerHeight / 2
      };
      // speed between 0 and 50
      let speed = {
        x: 50,
        y: 50
      };
      // let cursorOffset = {
      //   x: (event.clientX/2 / windowCenter.x) * speed.x + (50 - speed.x),
      //   y: -((event.clientY/2 / windowCenter.y) * speed.y + (50 - speed.y)),
      // };
      let cursorOffset = {
        x: (event.clientX - windowCenter.x) / windowCenter.x * 50 + 50,
        y: (event.clientY - windowCenter.y) / windowCenter.y * 50 + 50,
        // y: -((event.clientY / 2 / windowCenter.y) * speed.y + (50 - speed.y)),
      };

      this.cssImagePosition = `${cursorOffset.x}% ${cursorOffset.y}%`;
    }
  },
  mounted() {
    if (this.parallax) window.addEventListener('mousemove', this.onMove);
  },
  beforeDestroy() {
    if (this.parallax) window.removeEventListener('mousemove', this.onMove);
  }
};
</script>

<style lang="scss">
.candidate-media {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;

  &_content {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-size: 110% auto;
    //transition: background-position .5s linear 0s;

    &.no-image {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: 500;
      line-height: 12px;
      background: var(--color-gray);
      color: var(--page-color-main);
    }
  }
}
</style>
