<template>
  <Modal ref="modal" class="select-vacancies-modal" noVerticalPadding title="Выбрать вакансию">
    <!--    <Searchbar
          v-model="filterParams.query"
          name="search"
          placeholder="Поиск"
          @input="loadList"
        />-->
    <v-list>
      <v-list-item v-for="vacancy in vacancies" :key="vacancy.id" class="py-4" @click="submit(vacancy.id)">
        <v-list-item-title>{{ vacancy.title }}</v-list-item-title>
        <v-list-item-subtitle>{{ vacancy.company }}</v-list-item-subtitle>
      </v-list-item>
    </v-list>
  </Modal>
</template>

<script>
export default {
  name: 'SelectVacanciesModal',
  props: {
    multiple: Boolean,
    requestParams: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      vacancies: [],
      candidates: [],
      selectedVacancy: null,
      loadingTable: false,
      tableHeaders: [
        { value: 'title' },
      ],
      filterParams: {
        query: '',
      },
    };
  },
  methods: {
    open(data) {
      this.$refs.modal.open();
      this.loadList();
      this.candidates = data.map((candidate) => candidate.id);
    },
    close() {
      this.vacancies = [];
      this.candidates = [];
      this.selectedVacancy = null;
      this.$refs.modal.close();
    },
    submit(vacancyId) {
      this.$server.request2(`vacancy/addCandidates/${vacancyId}`, { ids: this.candidates}, (data) => {
        this.close();
        this.$eventBus.emit('candidates-added-to-vacancy');
      });
    },
    loadList() {
      this.loadingTable = true;
      this.$server.request2('vacancy/get', {}, (data) => {
        this.vacancies = data.response;
      }, () => {
      }, () => {
        this.loadingTable = false;
      });
    },
  },
};
</script>

<style lang="scss">
.select-vacancies-modal .modal-content {
  display: flex;
  flex-direction: column;

  .vacancies-list {
    min-height: 285px;
    margin-top: 18px;
    margin-bottom: 28px;
    max-height: 450px;
    overflow: scroll;
    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
    }

    li {
      border-top: 1px solid var(--border-color);

      &:last-child {
        border-bottom: 1px solid var(--border-color);
      }
    }
  }

  .vacancies-list-item {
    cursor: pointer;
    display: flex;

    &_inner {
      width: 100%;
      padding: 15px 0;

      & > * {
        margin-bottom: 4px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &_title {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }

    &_subtitle {
      font-size: 14px;
      line-height: 16px;
      color: rgba(var(--page-color-main-rgb), .5);
    }

    &_after {
      align-self: stretch;
      display: flex;
      align-items: center;
    }

    .check-wrap {
      input {
        display: none;

        &:checked ~ .icon {
          display: block;
        }
      }

      .icon {
        display: none;
      }
    }
  }
}
</style>
