<template>
  <v-dialog class="modal" :content-class="contentClass" ref="main_modal" v-model="opened" :width="width"
            @click:outside="customBackdropClickToClose ? customBackdropClose : backdropClickToClose ? close : ()=>{}" :persistent="!backdropClickToClose">
    <div class="modal-container" style="min-width: 400px">
      <div v-if="title" class="modal-header">
        <div class="modal-title">{{ title }}</div>
      </div>
      <div v-if="$slots.default" class="icon icon-close default-modal-close-icon" @click="closeIconClick"></div>
      <div v-if="$slots.default" class="modal-content" :class="{'py-4': !noVerticalPadding}">
        <slot></slot>
      </div>
      <div v-if="$slots.actions" class="modal-actions" :class="{'flex-column': verticalBtns}">
        <slot name="actions"></slot>
      </div>
    </div>
  </v-dialog>
</template>
<script>
export default {
  name: 'Modal',
  props: {
    title: {
      type: String,
      default: ''
    },
    width: {
      type: [Number, String],
      default: 'auto'
    },
    openOnInit: {
      type: Boolean,
      default: false,
    },
    noVerticalPadding: {
      type: Boolean,
      default: false,
    },
    verticalBtns: {
      type: Boolean,
      default: true,
    },
    component: { default: this },
    contentClass: String,
    backdropClickToClose: {
      default: true,
      type: Boolean,
    },
    customBackdropClickToClose: {
      default: false,
      type: Boolean
    },
    closeOnIconClickSpecial: {
      default: false,
      type: Boolean
    }
  },
  emits: [
    'open',
    'opened',
    'close',
    'closed',
    'backdrop-click',
    'close-icon-click',
    'update:component',
  ],
  data: () => ({
    opened: false,
  }),
  methods: {
    open() {
      this.opened = true;
    },
    close() {
      this.opened = false;
    },
    closeIconClick() {
      if (this.closeOnIconClickSpecial) {
        this.$emit('close-icon-click');
      } else {
        this.close();
      }
    },
    customBackdropClose() {
      this.$emit('backdrop-click');
    }
  },
  mounted() {
    if (this.openOnInit) this.open();
  },
};
</script>

<style lang="scss">
.v-dialog {
  background: #FFFFFF;

  .modal-container {
    background: #FFFFFF;
    box-shadow: 2px 8px 30px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    max-height: calc(100vh - 80px);

    .modal-header {
      position: sticky;
      top: 0;
      padding: 10px 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      .modal-title {
        display: block;
        align-items: center;
        font-weight: bold;
        font-size: 22px;
        line-height: 32px;
        text-align: center;
        letter-spacing: -1px;
        width: 100%;
      }
    }

    .default-modal-close-icon {
      position: absolute;
      top: 13px;
      right: 10px;
      cursor: pointer;
      font-size: 24px;
      z-index: 999;
      opacity: .4;
    }

    .modal-content {
      padding: 0 20px;
      overflow-y: auto;
      max-height: calc(100vh - 80px - 53px - 62px);
      position: relative;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .modal-actions {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #FFFFFF;
      position: sticky;
      bottom: 0;
      left: 0;
      padding: 10px 20px;
      //box-shadow: 1px 6px 35px rgba(0, 0, 0, 0.2);

      .v-btn {
        width: 100%;
      }
    }

    &.no-scroll {
      overflow: hidden;
    }
  }
}
</style>
