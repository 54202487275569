<template>
  <Modal ref="modal" title="Гонорар" content-class="receipt-fee-modal">
    <template v-if="formData.increaseDateProbation === 0">
      <div class="row-buttons-wrap">
        <div class="modal-subtitle">Кандидат вышел на должность?</div>
        <div class="row-buttons">
          <!--                  :color="working === null ? '' : working === 0 ? '#A0EED8' : '#FFC6CD'"
              -->
          <v-btn
            width="100"
            depressed @click="formData.working = 0" :ripple="false">Нет
          </v-btn>
          <!--                      :color="working === null ? '' : working === 1 ? '#A0EED8' : '#FFC6CD'"-->
          <v-btn
            width="100"
            depressed @click="formData.working = 1" :ripple="false">Да
          </v-btn>
        </div>
      </div>
      <div class="candidate-on-work"  v-if="formData.working === 1">
        <div class="row-buttons-wrap">
          <div class="modal-subtitle">Гонорар получен?</div>
          <div class="row-buttons">
            <v-btn
              :color="'#FFC6CD'"
              depressed @click="formData.fee_received = 0" :ripple="false">Нет, ожидаю
            </v-btn>
            <v-btn
              :color="'#A0EED8'"
              depressed @click="formData.fee_received = 1" :ripple="false">Да
            </v-btn>
          </div>
        </div>
        <v-text-field
          label="Если нужно, измените гонорар"
          placeholder="50 000"
          v-mask="$store.state.static.masksSumSeparator"
          v-model="formData.fee"
        />
      </div>
      <div v-if="formData.working === 0" class="candidate-not-on-work">
        <div class="row-buttons-wrap">
          <div class="modal-subtitle">Открыть вакансию?</div>
          <div class="row-buttons">
            <v-btn :color="'#FFC6CD'" depressed @click="formData.reopen = 0" :ripple="false">Нет</v-btn>
            <v-btn :color="'#A0EED8'" depressed @click="reopen" :ripple="false">Да</v-btn>
          </div>
        </div>
        <Field
          classes="comment-form-field"
          title="Комментарий"
          placeholder="Введите текст..."
          v-model="comment"
          type="textarea"
        />
        <div class="actions">
          <v-btn text :ripple="false" @click="formData.increaseDateProbation = 1">Продлить срок</v-btn>
        </div>
      </div>
    </template>



    <template v-if="formData.increaseDateProbation === 1">
      <DateMenu :value="formData.dateProbation" label="Новая дата окончания"
                @update="formData.dateProbation = $event"
      />
      <div class="row-buttons-wrap">
        <div class="row-buttons">
          <div class="cancel-button" @click="formData.increaseDateProbation = 0">Назад</div>
          <div
            class="default-button"
            :class="{'disabled': !formData.dateProbation }"
            @click="setDateProbation"
          >
            Готово
          </div>
        </div>
      </div>
    </template>

    <template v-slot:actions>
      <v-btn color="primary" depressed :ripple="false" @click="submit" :disabled="!check">Готово</v-btn>
    </template>
    <AfterChangingFeeModal ref="after_changing_fee_modal" :after-submit-modal-params="afterSubmitModalParams"
                           :candidate="formData.working" :open-vacancy="formData.reopen"
    />
    <NewProbationDateModal ref="new_probation_date_modal" :date-probation="formData.dateProbation"/>
  </Modal>
</template>

<script>
import NewProbationDateModal from '@/views/vacancy/close/NewProbationDateModal';
import AfterChangingFeeModal from '@/views/vacancy/close/AfterChangingFeeModal';

export default {
  name: 'ReceiptFeeModal',
  components: {
    NewProbationDateModal,
    AfterChangingFeeModal
  },
  emits: ['onload', 'submit'],
  data() {
    return {
      vacancyId: null,
      vacancy: {},
      formData: {
        working: 0,
        fee_received: 0,
        increaseDateProbation: 0
      },
      // candidate: null,
      // fee_received: null,
      // increaseDateProbation: null,
      // dateProbation: null,
      // reopen: null,
      comment: '',
    };
  },
  methods: {
    open(params) {
      this.formData = {
        working: 0,
        fee_received: 0,
        increaseDateProbation: 0
      };
      this.vacancy = {};
      this.vacancyId = params.vacancyId;
      this.getVacancy();
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    getVacancy() {
      this.$server.request2(`vacancy/get2/${this.vacancyId}`, {}, (data) => {
        this.vacancy = data.response;
        this.formData.fee = this.vacancy.fee;
      });
    },
    setDateProbation() {
      if (this.formData.dateProbation) {
        this.$server.request2(`vacancy/update/${this.vacancyId}`, { remind_date_to: this.formData.dateProbation }, (data) => {
          this.$refs.new_probation_date_modal.open();
          this.close();
        });
      }
    },
    submit() {
      if (!this.check) return;
      const formData = { ...this.formData };
      formData.id = this.vacancyId;

      if (this.working === 0) {
        formData.fee_received = 0;
        formData.comment = this.comment;
      }

      this.$server.request2('vacancy/closeWithParams', formData, (data) => {
        this.$refs.after_changing_fee_modal.open();
        this.$eventBus.emit('vacancy-closed-with-params');
        this.close();
      });
    },
    reopen() {
      this.$server.request2('vacancy/open', { id: this.vacancy.id }, (data) => {
        this.$router.push(`/vacancies/vacancy/${this.vacancy.id}`);
        this.close();
      });
    },
  },
  computed: {
    check() {
      const a = (this.working === 1 && this.formData.fee_received !== null);
      const b = (this.working === 0 && this.formData.reopen !== null);
      return !!(a || b);
    },
    afterSubmitModalParams() {
      const params = {};
      if (this.working && !this.formData.fee_received) {
        params.class = 'v-1';
        params.title = `Ожидается гонорар ${this.sumSeparator(this.vacancy.fee || '')}`;
        params.text = 'Перенести гонорар из Ожидаемых и Полученных можно будет в разделе Финансы';
        params.icon = require('@/images/pictures/picture-4.svg');
      }
      if (this.working && this.vacancy.formData.fee_received) {
        params.class = 'v-2';
        params.title = `Гонорар ${this.sumSeparator(this.vacancy.fee || '')} получен`;
        params.text = 'Вакансия останется в Архиве, а гонорар – в Финансах';
        params.icon = require('@/images/pictures/picture-5.svg');
      }
      if (!this.working && !this.formData.reopen) {
        params.class = 'v-3';
        params.title = 'Вакасния осталась незакрытой в архиве';
        params.text = 'Вы сможете открыть ее заново в любой момент';
        params.icon = require('@/images/pictures/picture-1.svg');
      }
      if (!this.working && this.formData.reopen) {
        params.class = 'v-4';
        params.title = 'Вакансия открыта заново';
        params.text = '';
        params.icon = require('@/images/pictures/picture-3.svg');
      }
      return params;
    },
  },
};
</script>

<style scoped lang="scss">
.receipt-fee-modal {
  .actions {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .row-buttons {
    justify-content: center;
  }

  .modal-subtitle {
    margin-bottom: 18px;
    font-size: 18px;
    line-height: 16px;
    text-align: center;
  }
}


.row-buttons-wrap {
  margin-bottom: 38px;

  &:last-child {
    margin-bottom: 0;
  }
}

.form-field {
  margin-bottom: 28px;
}

.comment-form-field {
  text-align: center;

  &::v-deep(.form-field_input) {
    text-align: center;
  }
}

.fee-form-field {

  &::v-deep(.form-field_input) {
    text-align: center;
    font-size: 22px;
    line-height: 32px;
    font-weight: bold;
  }
}

.button-to-vacancy {
  margin-bottom: 30px;
  color: var(--page-color-main);
}
</style>
