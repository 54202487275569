<template>
  <div class="form-skeleton-field">
    <div class="form-skeleton-field_title" v-if="title">{{ title }}</div>
    <v-skeleton-loader
      class="mx-auto"
      max-width="300"
      type="text"
    ></v-skeleton-loader>
  </div>
</template>

<script>
export default {
  name: 'SkeletonField',
  props: {
    title: String,
  },
};
</script>

<style lang="scss">
.form-skeleton-field {
  display: block;
  &_title {
    font-size: 14px;
    line-height: 16px;
    color: rgba(var(--page-color-main-rgb), .5);
    margin-bottom: 6px;
  }
  .el-skeleton__text {
    height: 25px;
  }
}
</style>
