<template>
  <v-menu
    v-model="date_menu"
    min-width="250"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        class="datepicker-field"
        :class="`${fullWidth?'':'col-6'} ${inputClass}`"
        @click:prepend-inner="date_menu = true"
        prepend-inner-icon="mdi-calendar-month-outline"
        :value="value ? $moment(value).format('DD.MM.YYYY') : ''"
        readonly
        v-bind="attrs"
        v-on="on"
        :label="label"
        hide-details="auto"
        :placeholder="placeholder || 'ДД.ММ.ГГГГ'"
        persistent-placeholder
        :clearable="isClearable"
        :error-messages="errorText"
        @click:clear="onClear"
      />
    </template>
    <v-date-picker
      class="custom-date-picker"
      v-model="date_value"
      @input="onInput"
      locale="ru"
    />
  </v-menu>
</template>

<script>
export default {
  name: 'DateMenu',
  props: ['value', 'label', 'isClearable', 'fullWidth', 'errorText', 'placeholder', 'inputClass'],
  emits: ['update'],
  data() {
    return {
      date_menu: false,
      date_value: null,
    };
  },
  methods: {
    onInput() {
      this.$emit('update', this.date_value);
      this.date_value = null;
      this.date_menu = false;
    },
    onClear() {
      this.$emit('update', '');
      this.date_value = null;
      this.date_menu = false;
    }
  },
  watch: {

  }
};
</script>

<style lang="scss">
.datepicker-field {
  input {
    min-width: 80px;
  }
  .v-input__control > .v-input__slot > .v-text-field__slot {
    position: initial !important;
  }
  .v-input__control input {
    border-bottom: none !important;
  }
  .v-icon {
    font-size: 16px;
  }
}
.custom-date-picker {
  .vc-day.is-not-in-month * {
    opacity: 0.3;
  }
  .vc-day.is-today {
    border: 1px solid #111111;
    border-radius: 50%;
    width: 32px;
    height: 32px;
  }
}
</style>
