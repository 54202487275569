<template>
  <div class="analytics-grid-item">
    <div class="analytics-grid-item_title">{{ sumSeparator(value)  }}</div>
    <div class="analytics-grid-item_subtitle">{{ title }}</div>
    <div
      class="analytics-grid-item_diagram swiper-container"
      :style="{'height' : `calc(1em + 4px + ${columnsHeightMax}px)`}"
    >
      <div class="swiper-wrapper">
        <div
          class="analytics-grid-item_diagram_column swiper-slide"
          :class="{'active': columns.length - 1 - index === monthIndex}"
          v-for="(column, index) in columnsReverse"
          :key="index"
          @click="columnClick(column)"
        >
          <div
            class="analytics-grid-item_diagram_column_fill-wrap"
            :style="{'height' : `${columnsHeightMax}px`}"
          >
            <div
              class="analytics-grid-item_diagram_column_fill"
            />
          </div>
          <div class="analytics-grid-item_diagram_column_name">
            {{
              $moment(column.date)
                .locale('ru')
                .format('MMM')
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AnalyticsGridItem',
  props:  ['title' ,'columns', 'monthIndex'],
  emits: ['update:monthIndex'],
  data() {
    return {
      swiper: null,
      columnsHeightMax: 77,
      columnsHeightMin: 10,
    };
  },
  methods: {
    columnClick(column) {
      this.columns.some((_column, index) => {
        if (_column === column) {
          this.$emit('update:monthIndex', index);
        }
        return _column === column;
      });
    },
    setHeightForSwiperSlides(swiper = this.swiper) {
      if (!swiper) return;
      const visibleItems = [];
      swiper.slides.forEach((slide, index) => {
        if (slide.classList.contains('swiper-slide-visible')) {
          visibleItems.push({
            slide,
            column: this.columnsReverse[index],
          });
        }
      });

      const columns = visibleItems.map(({ column }) => column);
      visibleItems.forEach((item) => {
        const columnEl = item.slide.querySelector('.analytics-grid-item_diagram_column_fill');
        columnEl.style.maxHeight = `${this.getHeightColumn(item.column, columns)}px`;
      });
    },
    getHeightColumn(column, columns = this.columns) {
      const { value } = column;
      if (!Number(value)) return this.columnsHeightMin;

      const values = columns.map((_column) => Number(_column.value));
      const max = Math.max(...values);
      const changingSpace = this.columnsHeightMax - this.columnsHeightMin;

      return (changingSpace * (value / max)) + this.columnsHeightMin;
    },
    createSwiper() {
      this.swiper = this.$swiperCreate(this.$el.querySelector('.analytics-grid-item_diagram'), {
        slidesPerView: 6,
        observer: true,
        spaceBetween: 8,
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
        on: {
          observerUpdate: (swiper) => {
            swiper.slideTo(this.currentSlideIndex, 0);
            swiper.slides.forEach((slide) => {
              const columnEl = slide.querySelector('.analytics-grid-item_diagram_column_fill');
              columnEl.style.maxHeight = `${this.columnsHeightMin}px`;
            });
            setTimeout(() => {
              this.setHeightForSwiperSlides(swiper);
            });
          },
          slideChange: this.setHeightForSwiperSlides,
          transitionEnd: this.setHeightForSwiperSlides,
        },
      });
      const slide = this.swiper.slides[this.currentSlideIndex];
      if (!slide.classList.contains('.swiper-slide-visible')) {
        this.swiper.slideTo(this.currentSlideIndex);
      }
    }
  },
  computed: {
    columnsReverse() {
      return [...this.columns].reverse();
    },
    currentSlideIndex() {
      return this.columns.length - 1 - this.monthIndex;
    },
    value() {
      if (!this.columns.length) {
        return 0;
      }
      return this.columns[this.monthIndex]?.value;
    },
  },
  mounted() {
    if (this.columns.length > 0) {
      this.createSwiper();
    }
  },
};
</script>

<style scoped lang="scss">
.analytics-grid-item {
  width: 100%;
  padding: 24px;
  background: #FFFFFF;
  box-shadow: 2px 8px 30px rgba(0, 0, 0, 0.2);
  border-radius: 12px;

  &_title {
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 22px;
    line-height: 24px;
    letter-spacing: -0.5px;
  }

  &_subtitle {
    margin-bottom: 28px;
    font-size: 13px;
    line-height: 20px;
  }

  &_diagram {
    display: flex;
    justify-content: space-between;

    &_column {
      cursor: pointer;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      &.active {
        .analytics-grid-item_diagram_column_fill {
          background: #67C7AC;
        }

        .analytics-grid-item_diagram_column_name {
          color: var(--page-color-main);
        }
      }

      &_fill-wrap {
        display: flex;
        align-items: flex-end;
        width: 100%;
      }

      &_fill {
        width: 100%;
        height: 100%;
        transition: max-height .5s;
        background: #F4F3F2;
        border-radius: 6px;
      }

      &_name {
        margin-top: 5px;
        flex-shrink: 0;
        text-align: center;
        font-weight: 600;
        font-size: 10px;
        line-height: 15px;
        text-transform: uppercase;
        color: rgba(var(--page-color-main-rgb), .4);
      }
    }
  }
}
</style>
