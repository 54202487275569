<template>
  <div v-if="Number(candidate.rate) || showZero" :class="[classes, color]" class="candidate-rating">
    {{
      Number(candidate.rate)
        .toFixed(1)
    }}
  </div>
</template>

<script>
export default {
  name: 'CandidateRating',
  props: {
    classes: String,
    candidate: Object,
    showZero: {
      default: false,
      type: Boolean,
    },
    colored: {
      default: true,
      type: Boolean,
    },
  },
  computed: {
    color() {
      if (!Number(this.candidate.rate) || !this.colored) return null;
      if (this.candidate.rate >= 4) return 'green_color';
      if (this.candidate.rate >= 3) return 'yellow_color';
      return 'red_color';
    },
  },
};
</script>

<style lang="scss">
.candidate-rating {
  width: 28px;
  height: 16px;
  background: var(--border-color);
  border: 1px solid #FFFFFF;
  border-radius: 15px;
  font-weight: 600;
  font-size: 10px;
  text-transform: uppercase;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;

  &.green_color {
    background: #8ABCAE;
  }

  &.yellow_color {
    background: #BA9A35;
  }

  &.red_color {
    background: #B8705E;
  }
}
</style>
