<template>
  <Modal ref="modal" class="share-vacancy-modal" @backdrop-click="close" @close-icon-click="close"
         title="Настройки доступа" :width="500">
    <div class="share-vacancy-modal-content">
      <v-row>
        <v-col :cols="6" class="pb-0">
          <v-text-field
            v-model="searchQuery"
            hide-details="auto"
            label="Пригласить пользователя"
            placeholder="Email или телефон"
            persistent-placeholder
            :error-messages="userError"
            @input="userError = ''"
          />
        </v-col>
        <v-col cols="3" class="pb-0">
          <v-select
            v-model="formData.type"
            :items="$store.state.static.accessTypes"
            placeholder="Доступ"
            item-text="name"
            item-value="type"
            hide-details="auto"
            @change="typeError = ''"
            :error-messages="typeError"
          />
        </v-col>
        <v-col cols="3" class="pb-0 d-flex align-end">
          <v-btn
            class="add-user-btn"
            :ripple="false" depressed @click="setAccess" color="primary" :loading="loadingSetAccess">Добавить</v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-0 px-3" v-if="formData.user && formData.user.id">
        <v-checkbox
          label="Уведомить пользователя"
          v-model="formData.notify"
          true-value="1"
          false-value="0"
        />
      </v-row>
      <v-row class="mb-4 px-3">
        <div class="mt-6  page-subtitle">Пользователи, имеющие доступ</div>
        <!--        <v-list-item class="px-0">
                  <div class="candidates-list-item_media">
                    <CandidateMedia :candidate="vacancy.user"/>
                  </div>
                  <div class="candidates-list-item_inner">
                    <div class="candidates-list-item_title">{{ vacancy.user.name }} {{ vacancy.user.surname ? vacancy.user.surname : '' }}
                      <span v-if="vacancy.user.id === $store.state.user.id">(Вы)</span>
                    </div>
                    <div v-if="vacancy.user.email" class="candidates-list-item_subtitle">{{ vacancy.user.email }}</div>
                  </div>
                  <div class="candidates-list-item_after">
                    <div class="user-access-name">Владелец</div>
                  </div>
                </v-list-item>-->
        <v-list width="100%">
          <v-list-item v-for="user in usersWithAccess" :key="user.id">
            <div class="candidates-list-item_media">
              <div class="candidate-media">
                <img v-if="user.user.image" :src="user.user.image" alt="user image">
                <div v-else class="candidate-media_content no-image">
                  {{ user.user.name ? user.user.name[0].toUpperCase() : '' }}
                  <span>{{ user.user.surname ? user.user.surname[0].toUpperCase() : '' }}</span>
                </div>
              </div>
            </div>
            <div class="candidates-list-item_inner">
              <div class="candidates-list-item_title">{{user.user.email ? user.user.email : user.user.phone}}</div>
              <div class="candidates-list-item_subtitle">{{ user.user.name }} {{ user.user.surname }}</div>
            </div>
            <div class="candidates-list-item_after">
              <v-select
                v-model="user.type"
                :items="$store.state.static.accessTypes"
                placeholder="Доступ"
                item-text="name"
                item-value="type"
                @change="setAccess($event, user.user.email ? user.user.email : user.user.phone)"
              />
            </div>
          </v-list-item>
        </v-list>
      </v-row>
      <v-row class="ma-0">
        <v-col cols="8" class="link-container">
          <a ref="link" class="share-link external" :href="share_access_link" target="_blank">{{ share_access_link }}</a>
          <div v-if="copied" class="icon icon-check"></div>
        </v-col>
        <v-spacer/>
        <v-btn
          :ripple="false"
          depressed
          class="default-button col-3" :class="{'cancel-button': copied}" @click="copy">{{ copied ? 'Cкопировано' : 'Скопировать' }}
        </v-btn>
      </v-row>
    </div>
    <template v-slot:actions>
      <v-btn color="primary" :ripple="false" depressed :loading="loading" @click="edit" width="200">Готово</v-btn>
    </template>
  </Modal>
</template>

<script>
export default {
  name: 'ShareVacancy',
  data() {
    return {
      vacancy: {
        user: {},
      },
      searchQuery: '',
      formData: {
        notify: '0',
        user: {},
      },
      users: [],
      search: null,

      loading: false,
      loadingShare: false,
      loadingSetAccess: false,
      userError: '',
      typeError: '',
      access_token: '',
      share_access_link: '',
      copied: false
    };
  },
  emits: ['shared'],
  methods: {
    open(params) {
      this.formData = {
        notify: '0',
        user: {}
      };
      this.users = [];
      this.searchQuery = '';
      this.userError = '';
      this.typeError = '';
      this.vacancy = {};
      this.access_token = '';
      this.share_access_link = '';

      this.vacancy = params.vacancy;
      this.createAccessToken();
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    findUsers(query, callback) {
      this.$server.request2('user/findUser', { q: query }, (data) => {
        callback(data.response);
        /* if (data.response && Object.keys(data.response).length) this.formData.user = data.response;
         else if (data.response && data.response.length) this.users = data.response;*/
      });
    },
    setAccess(type = '', q = '') {
      this.loadingSetAccess = true;
      this.$server.request2(`vacancy/setAccess/${this.vacancy.id}`, {
        q: q ? q : this.searchQuery,
        type: typeof type === 'string' ? type : this.formData.type
      }, (data) => {
        this.loadingSetAccess = false;
        this.usersWithAccess = data.response;
        this.searchQuery = '';
        this.formData.type = '';
        this.$emit('shared');
        this.$forceUpdate();
      }, () => {
        this.loadingSetAccess = false;
      });
    },
    createAccessToken() {
      this.$server.request2('vacancy/createAccessToken', { ids: [this.vacancy.id] }, (data) => {
        this.access_token = data.response;
        this.share_access_link = `https://web.rekroo.org/shr/v/${data.response}`;

      });
    },
    copy() {
      navigator.clipboard.writeText(this.share_access_link);
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 3000);
    },
    edit() {
      this.close();
    }
  },
  created() {
    this.$eventBus.on('vacancy-loaded', ()=>this.$forceUpdate());
  },
  beforeDestroy() {
    this.$eventBus.off('vacancy-loaded');
  },
  computed: {
    usersWithAccess: {
      get: function () {
        return this.vacancy.access;
      },
      set: function(newVal) {
        this.vacancy.access = newVal
      }
    }
  },
  watch: {
    search(q) {
      this.searchQuery = q;
      // if (!q) this.findUsers(q);
    }
  }
};
</script>

<style lang="scss">
.share-vacancy-modal {
  .share-vacancy-modal-content {
    .page-subtitle {
      font-weight: 600;
    }
  }
}
.link-container {
  max-width: 310px;
  display: flex;
  align-items: center;
  a {
    display: inline-block;
    max-width: 250px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>