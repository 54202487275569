<template>
  <div class="form-field" :class="[classes, {'error': error}]">
    <div v-if="title" class="form-field_title">{{ title }}</div>
    <div class="form-field_content">
      <div class="form-field_content_before" v-if="$slots.before">
        <slot name="before"/>
      </div>
      <div class="form-field_content_inner">
        <div v-if="errorMessage" class="error-message color-red">{{errorMessage}}</div>
        <template v-if="!['textarea', 'select', 'date', 'time'].includes(type)">
          <input
            v-maska="mask"
            :disabled="disabled"
            :name="name"
            :placeholder="placeholder"
            :type="type"
            :value="modelRawValue ? maskedValue : modelValue"
            class="form-field_input"
            @input="inputEvent"
            @change="changeEvent"
            @maska="maskEvent"
            @blur="blurEvent"
            :list="list"
          >
        </template>
        <template v-else-if="type === 'textarea'">
          <div style="opacity: 0; min-height: 53px">{{modelRawValue ? maskedValue : modelValue}}</div>
          <v-textarea
            :placeholder="placeholder"
            :value="value"
            @input="inputEvent($event, 'textarea')"
            auto-grow
            rows="2"
            hide-details
          />
<!--          <textarea
            style="position: absolute; top: 0; height: 100%; min-height: 53px"
            v-maska="mask"
            :disabled="disabled"
            :name="name"
            :placeholder="placeholder"
            :value="modelRawValue ? maskedValue : modelValue"
            class="form-field_input"
            @input="inputEvent"
            @maska="maskEvent"
            @blur="blurEvent"
          >
          </textarea>-->
        </template>
        <template v-else-if="type === 'select'">
          <select
            :disabled="disabled"
            :name="name"
            :placeholder="placeholder"
            :value="modelValue"
            class="form-field_input"
            @input="inputEvent"
            @blur="blurEvent"
          >
            <option
              v-for="(option, index) in options"
              :key="index"
              :disabled="option.disabled"
              :value="option.value"
            >
              {{ option.text }}
            </option>
          </select>
        </template>
        <template v-else-if="type === 'date'">
          <v-menu
            v-model="dateMenu"
            max-width="250"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="datepicker-field"
                prepend-inner-icon="mdi-calendar-month-outline"
                :value="dataDateValue ? $moment(dataDateValue).format('DD.MM.YYYY') : ''"
                clearable
                readonly
                v-bind="attrs"
                placeholder="Не указана"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              class="custom-date-picker"
              type="date"
              v-model="dataDateValue"
              @input="inputEvent($event, 'date')"
              :placeholder="placeholder"
              @change="dateMenu = false"
              locale="ru"
            ></v-date-picker>
          </v-menu>
        </template>
        <template v-else-if="type === 'time'">
          <v-text-field
            v-model="timeValue"
            v-mask="['##:##']"
            placeholder="__:__"
          />
        </template>
      </div>
      <div class="form-field_content_after" v-if="$slots.after">
        <slot name="after"/>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Field',
  props: {
    modelValue: String,
    name: { require: true },
    type: { default: 'text' },
    value: { default: '' },
    placeholder: { default: '' },
    title: { default: '' },
    values: { default: '' }, // only select '0:not selected;1:male;2:female' or [{value:'0', text: 'not selected'}, {value:'1', text: 'male'}, {value:'2', text: 'female'}]
    classes: { default: '' },
    disabled: { default: false },
    mask: { default: '' },
    modelRawValue: { default: false },
    validate: Function,
    isValid: Boolean,
    errorMessage: String,
    showError: Boolean,
    list: String,
    disabledDate: Function,
  },
  emits: [
    'update:modelValue',
    'update:isValid',
    'update:showError',
    'blur',
    'on-change'
  ],
  data() {
    return {
      dateMenu: false,
      maskedValue: '',
      errorIsShown: this.showError,
      dataDateValue: this.value,
      dataTimeValue: this.value,
    };
  },
  methods: {
    updateValue(value) {
      this.$emit('update:modelValue', value);
      let isValid = !!value;
      if (typeof this.validate === 'function') {
        isValid = this.validate(value, this);
      }
      this.$emit('update:isValid', isValid);
    },
    maskEvent(event) {
      if (this.mask && this.modelRawValue) {
        this.maskedValue = event.target.value;
        this.updateValue(event.target.getAttribute('data-mask-raw-value'));
      }
    },
    inputEvent(event, type = '') {
      if (!this.mask || !this.modelRawValue) {
        if (type === 'date') {
          const value = this.$moment(event).format('YYYY-MM-DD');
          this.updateValue(value);
        } else if (type === 'textarea') {
          this.updateValue(event);
        } else {
          this.updateValue(event.target.value);
        }
      }
    },
    changeEvent(event) {
      this.$emit('on-change', event);
    },
    blurEvent(event) {
      this.$emit('blur', event);
    },
  },
  computed: {
    options() { // only select
      let options = [];
      if (typeof this.values === 'string') {
        options = Array.from(this.values.split(';'), (item) => ({
          value: item.split(':')[0],
          text: item.split(':')[1],
        }));
      } else {
        options = this.values;
      }
      if (this.placeholder) {
        options.unshift({
          value: '',
          text: this.placeholder,
          disabled: true,
        });
      }
      return options;
    },
    error: {
      get() {
        return this.errorIsShown;
      },
      set(val) {
        this.$emit('update:showError', !!val);
        this.errorIsShown = !!val;
      },
    },
    dataValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    dateValue: {
      get() {
        if (!this.dataValue) return null;
        return this.dataValue;
      },
      set(date) {
        if (date) {
          this.dataValue = this.$moment(date)
            .format('YYYY-MM-DD');
        } else {
          this.dataValue = null;
        }
      },
    },
    timeValue: {
      get() {
        if (!this.dataValue) return null;
        const h = this.dataValue.split(':')[0];
        const m = this.dataValue.split(':')[1];
        return new Date(new Date(0).setHours(h, m));
      },
      set(date) {
        if (date) {
          this.dataValue = this.$moment(date)
            .format('HH:mm');
        } else {
          this.dataValue = null;
        }
      },
    },
  },
  watch: {
    showError(val) {
      this.error = val;
    },
  },
  created() {
    if (this.modelRawValue) {
      this.maskedValue = this.value;
    }
  },
  mounted() {
    if (this.value) {
      this.$emit('update:modelValue', String(this.value));
      if (this.$el.querySelector('.form-field_input')) {
        this.$el.querySelector('.form-field_input').value = this.value;
      }
    }
  },
};
</script>
<style lang="scss">
.form-field {
  display: block;
  .error-message {
    font-size: 13px;
  }
  &_title {
    font-size: 14px;
    line-height: 16px;
    color: rgba(var(--page-color-main-rgb), .5);
    margin-bottom: 10px;
  }

  &_content {
    position: relative;
    display: flex;

    &_inner {
      width: 100%;
      position: relative;
    }

    &_before, &_after {
      flex-shrink: 0;
    }
  }

  &_input, .el-input__inner {
    display: block;
    width: 100%;
    padding: 8px 0;
    border: none;
    border-bottom: 1px solid var(--border-color);
    border-radius: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: var(--page-color-main);
    background: transparent;

    &::placeholder {
      color: rgba(var(--page-color-main-rgb), .25);
    }
    &:focus {
      border-color: var(--page-color-main);
    }

    &:disabled, &.empty {
      color: rgba(var(--page-color-main-rgb), .25);
    }
  }

  .el-input--prefix .el-input__inner {
    padding-left: 30px;
  }

  .el-input--suffix .el-input__inner {
    padding-right: 30px;
  }

  &.error {
    .form-field_title {
      color: var(--color-red);
    }

    .form-field_input {
      border-color: var(--color-red);
    }
  }

  .el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 100%;

    .el-input__prefix {
      left: 0;
    }

    .el-input__suffix {
      right: 0;
    }
  }
}
</style>
