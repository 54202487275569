<template>
  <Modal ref="modal" content-class="edit-event-modal" @backdrop-click="close" @close-icon-click="close" :width="900"
         :vertical-btns="true"
         :title="params?.title || 'Редактировать' ">
    <div class="vue-editor-container">
      <div class="color-red" v-if="commentError">{{ commentError }}</div>
      <vue-editor v-model="comment"/>
    </div>
    <ChangeEventRating @update:modelValue="event.rate = $event; $forceUpdate()" :model-value="event.rate"/>
    <template v-slot:actions>
      <div class="default-button" @click="submit">Сохранить</div>
      <div class="remove-button" v-if="event.id" @click="remove">Удалить</div>
    </template>
  </Modal>
</template>

<script>
import ChangeEventRating from '@/views/event/ChangeEventRating.vue';

export default {
  name: 'NotMainEventModal',
  components: { ChangeEventRating },
  data() {
    return {
      event: {},
      comment: '',
      params: {},
      commentError: '',
    };
  },
  methods: {
    open(params) {
      this.event = {};
      this.comment = '';

      this.params = params;
      if (params.id) this.loadEvent(params.id);
      this.$refs.modal.open();
    },
    close() {
      this.comment = '';
      this.event = {};
      this.$refs.modal.close();
    },
    loadEvent(event_id) {
      this.$server.request2(`event/get/${event_id}`, {}, (data) => {
        this.event = data.response;
        this.comment = data.response.comment;
      });
    },
    submit() {
      if (!this.comment.length) {
        this.commentError = 'Введите комментарий';
        return;
      }
      const url = this.event.id ? `event/update/${this.params.id}` : 'event/create';
      this.event.comment = this.comment;
      if (!this.event.id) {
        this.event = {
          ...this.params,
          ...this.event,
          type: '3',
          date: this.$moment()
            .format('YYYY-MM-DD'),
          datetime_from: String(Date.now())
            .slice(0, -3),
        };
      } else {
        this.event.candidate = this.event.candidate.id;
      }
      this.$server.request2(url, this.event, () => {
        this.$eventBus.emit('comment-update');
        this.close();
      });
    },
    remove() {
      this.$server.request2(`event/remove/${this.event.id}`, {}, (data) => {
        this.$eventBus.emit('comment-update');
        this.close();
      });
    },
  },
  mounted() {

  },
};
</script>

<style lang="scss">
.edit-event-modal {
  .form {
    margin-bottom: 40px;
  }

  .vue-editor-container {
    .ql-editor {
      height: calc(100vh - 450px);
    }
  }

  .remove-button {
    margin-top: 20px;
  }
}
</style>
