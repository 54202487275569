<template>
  <Modal ref="modal" @backdrop-click="close" @close-icon-click="close" :title="title ? title : ''" :vertical-btns="true">
    <div v-if="subtitle" class="modal-subtitle">{{ subtitle }}</div>
    <Field v-for="(field, index) in fields" :key="randomInt + index" v-bind="{...field}" @update:modelValue="updatedValue(field.name, $event)"/>
    <template v-slot:actions>
      <div
        class="default-button"
        :class="{'disabled': !checkValues}"
        @click="onSubmit"
      >
        {{ buttonText }}
      </div>
      <div
        v-for="(button, index) in addButtons"
        :key="index"
        :class="button.classes"
        @click="addButtonClick(button)"
        style="margin-top: 10px"
      >
        {{ button.text }}
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue';
import Field from '@/components/Field.vue';

export default {
  name: 'FieldsInModal',
  components: {
    Modal,
    Field,
  },
  props: {
    title: String,
    subtitle: String,
    buttonText: {
      type: String,
      default: 'Готово',
    },
    validate: Function,
    addButtons: Array,
  },
  emits: [
    'submit',
  ],
  data() {
    return {
      formData: {},
      fields: [],
      randomInt: 0,
    };
  },
  methods: {
    open(fields) {
      this.fields = [];
      this.formData = {};
      this.randomInt = 0;
      this.$refs.modal.open();

      this.randomInt = Math.floor(Math.random() * (10000 - 1 + 1) + 1);
      this.fields = fields;
    },
    close() {
      this.$refs.modal.close();
    },
    onSubmit() {
      const data = { ...this.formData };
      this.$emit('submit', data);
      this.close();
    },
    updatedValue(fieldName, newVal) {
      this.formData[fieldName] = newVal;
    },
    addButtonClick(button) {
      if (typeof button.onClick === 'function') button.onClick({ ...this.formData }, this);
    },
  },
  computed: {
    checkValues() {
      if (this.validate) return this.validate({ ...this.formData });
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.default-button {
  margin-top: 30px;
}

.form-field {
  margin-bottom: 15px;
}

.modal-subtitle {
  font-size: 14px;
  margin-bottom: 10px;
}
</style>
