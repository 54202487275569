<template>
  <div
    class="user-notifications_item"
    @click="notifyOnClick(notify)"
  >
    <div class="user-notifications_item_before">
      <div class="dot red" v-if="!notify.seen"/>
      <div class="dot gray" v-else/>
    </div>
    <div class="user-notifications_item_inner">
      <div class="user-notifications_item_inner_row" v-if="dataForTypes[notify.type]?.title">
        <div class="user-notifications_item_title">
          {{ dataForTypes[notify.type]?.title }}
        </div>
      </div>
      <div
        class="user-notifications_item_inner_row"
        v-if="candidate"
        @click="notifyInnerOnClick(notify, 'candidate')"
      >
        <div class="user-notifications_item_title">
          {{ candidate.name }}
          {{ candidate.surname }}
        </div>
      </div>
      <div
        class="user-notifications_item_inner_row"
        v-if="notify.info?.event"
        @click="notifyInnerOnClick(notify, 'event')"
      >
        <div class="user-notifications_item_event">
          <div>
          <span
            class="bold user-notifications_item_event_label"
            :class="{
              'color-1': ['0','1'].includes(notify.info?.event.type),
              'color-2': notify.info?.event.type === '2'}"
          >
            <template v-if="['0','1'].includes(notify.info?.event.type)">
              Собеседование
            </template>
            <template v-else-if="notify.info?.event.type === '2'">
              Напоминание
            </template>
            <template v-else>
              {{ notify.info?.event.title }}
            </template>
          </span>
            <span v-if="notify.info.event.datetime_from">
              в
            {{
                $moment(Number(`${notify.info?.event.datetime_from}000`))
                  .locale('ru')
                  .format('HH:mm')
              }}
            </span>

          </div>
          <div class="user-notifications_item_event_comment" v-if="notify.info?.event.comment">
            <div class="icon icon-comment"></div>
            <span>{{ notify.info.event.comment }}</span>
          </div>
        </div>
      </div>
      <div
        class="user-notifications_item_inner_row"
        v-if="notify.info?.vacancy"
        @click="notifyInnerOnClick(notify, 'vacancy')"
      >
        <div class="user-notifications_item_subtitle">
          Вакансия {{ notify.info.vacancy.title }}
        </div>
      </div>
    </div>
    <div
      class="user-notifications_item_after"
      v-if="candidate"
      @click="notifyInnerOnClick(notify, 'candidate')"
    >
      <CandidateMedia
        :candidate="candidate"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotifyListItem',
  props: {
    notify: Object,
  },
  emits: ['notify-click'],
  data() {
    return {
      dataForTypes: {
        probation: {
          title: 'Испытат. срок завершен',
          handler: (notify) => {
            this.$eventBus.emit('receipt-fee-modal-open', { vacancyId: notify.info.vacancy.id });
          },
        },
        event_notify: {},
      },
    };
  },
  methods: {
    notifyOnClick(notify) {
      this.$server.request('user/seenNotify', { id: notify.id })
        .then(() => {
          this.$store.commit('setNotify', {
            ...notify,
            seen: '1',
          });
          this.$store.commit('sortAndSliceNotifies');
        });
      const notifyTypeData = this.dataForTypes[notify.type];
      if (notifyTypeData && typeof notifyTypeData.handler === 'function') {
        this.dataForTypes[notify.type].handler(notify);
      }

      this.$emit('notify-click', notify);
    },
    notifyInnerOnClick(notify, childName) {
      const notifyTypeData = this.dataForTypes[notify.type];
      if (notifyTypeData && typeof notifyTypeData.handler === 'function') return;

      if (childName === 'candidate') {
        this.$router.push({
          name: 'Candidate',
          params: { candidateId: this.candidate.id },
        });
      } else if (childName === 'event') {
        if ( ['0', '1'].includes(notify.info.event.type)) {
          this.$router.push(`/interview/${notify.info.event.id}`);
        } else {
          this.$eventBus.emit('edit-event-modal-open', notify.info.event.id);
        }
      } else if (childName === 'vacancy') {
        this.$router.push(`/vacancies/vacancy/${notify.info.vacancy.id}/`);
      }
    },
  },
  computed: {
    candidate() {
      if (!this.notify) return null;
      if (this.notify.info.candidate) return this.notify.info.candidate;
      if (this.notify.info.event?.candidate) return this.notify.info.event?.candidate;
      return null;
    },
  },
};
</script>

<style scoped lang="scss">
.user-notifications_item {
  display: flex;
  cursor: pointer;

  &_before {
    flex-shrink: 0;
    margin-right: 12px;
    min-width: 10px;
  }

  &_inner {
    width: 100%;
    padding-bottom: 18px;

    & > * {
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &_after {
    flex-shrink: 0;
    display: flex;
    align-items: center;
  }

  .candidate-media {
    width: 30px;
    height: 30px;
  }

  .dot {
    margin-top: 5px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: var(--border-color);

    &.red {
      background: var(--color-red);
    }

    &.gray {
      background: var(--color-gray);
    }
  }

  &_title {
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
  }

  &_subtitle {
    font-size: 13px;
    line-height: 17px;
    color: rgba(var(--page-color-main-rgb), .5);
  }

  &_event {
    &_label {
      font-size: 14px;
      line-height: 20px;
      display: inline-flex;
      align-items: center;
      padding: 0 5px;
      border-radius: 3px;
      margin-right: 3px;

      &.color-1 {
        background: #C7DEDD;
      }

      &.color-2 {
        background: #CED9E3;
      }
    }

    &_comment {
      margin-top: 8px;
      font-size: 13px;
      line-height: 17px;
      color: rgba(var(--page-color-main-rgb), .5);
      display: flex;

      .icon {
        color: rgba(var(--page-color-main-rgb), .25);
        font-size: 16px;
        margin-right: 7px;
      }
    }
  }
}
</style>
