<template>
  <div class="user-notifications">
    <template v-if="notifies.length">
      <div class="user-notifications_title">Уведомления</div>
      <div class="user-notifications_list">
        <ul>
          <li v-for="notify in notifies" :key="notify.id" v-if="notify.info && notify.info.event" >
            <EventListItem :event="notify.info.event">
              <template #after="{ event }">
                <slot name="itemAfter" :event="event"/>
              </template>
            </EventListItem>
<!--            <NotifyListItem :notify="notify" @notify-click="notifyOnClick"/>-->
          </li>
        </ul>
      </div>
    </template>
    <div class="user-notifications_title" v-else>Уведомлений нет</div>
  </div>
</template>

<script>
import NotifyListItem from '@/views/notifies/NotifyListItem.vue';
import EventListItem from '@/views/event/EventListItem';
export default {
  name: 'NotifyList',
  components: { NotifyListItem, EventListItem},
  emits: ['notify-click'],
  methods: {
    notifyOnClick(notify) {
      this.$emit('notify-click', notify);
    },
  },
  computed: {
    notifies() {
      return this.$store.state.notifies;
    },
  },
};
</script>

<style lang="scss">
.user-notifications {
  font-family: var(--font-family);
  color: var(--page-color-main);
  background: #FFFFFF;
  padding: 16px;
  &_title {
    padding-bottom: 12px;
    font-weight: bold;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.5px;
    border-bottom: 1px solid var(--border-color);
  }

  &_list {
    margin-top: 18px;

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
    }

    li {
      margin-bottom: 18px;
      border-bottom: 1px solid var(--border-color);

      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
      }
      .event-list-item_title {
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline;
      }
      .event-list-item_comment {
        max-height: 20px;
      }
    }
  }
  .event-list-item_media, .event-list-item_inner {
    padding: 8px 0;
  }
}
</style>
