<template>
  <div
       style="position: fixed;opacity:0.8;min-width: 200px;padding:5px;background: #eee;box-shadow: 0 0 5px #aaa;bottom: 10px;right: 20px;border-radius: 5px;display: flex;flex-direction: column;">
    <template v-if="result">
      <v-icon @click="result = null" style="position: absolute;right: 0;cursor: pointer">mdi-close</v-icon>
      <div>
        <table style="width: 500px;">
          <tbody>
          <tr v-for="(row, index) in result.tests">
            <td style="width: 250px">{{ index + 1 }}. {{ row.method }}</td>
            <td :style="{color:row.response==='success' ? 'green':row.response?'red':'grey'}">{{ row.response || 'running...' }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div style="display: flex;align-items: center;justify-content: space-evenly;">
        <span>Tests: {{ result.success + result.error }} / {{ result.total }}</span>
        <span>Time: {{ result.time }}</span>
        <span :style="{color:result.error ? 'red':'green'}">{{ result.error }} errors</span>
      </div>
    </template>
    <div v-if="!result || result.done" style="display: flex;min-width: 200px">
      <v-btn @click="startTests({speed:speed})" class="pa-0" elevation="0" style="min-height:30px;flex: 2" text>Run {{result ? 'again' : 'tests'}}</v-btn>
      <v-select hide-details class="pa-0" solo dense style="flex: 1" :items="['Fast','Slow']" v-model="speed"/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Test',
  data() {
    return {
      speed: 'Fast',
      result: null,
    };
  },
  methods: {
    startTests(params) {
      this.$root.$test.run(params);
    }
  },
  mounted() {
    this.$eventBus.on('tests', results => {
      if (!this.result) {
        setInterval(() => {
          this.result.time = this.$moment(this.$moment(this.result.done || Date.now())
            .diff(this.result.timeStart))
            .format('mm:ss');
        }, 200);
        this.result = {};
      }
      for (let i in results) this.result[i] = results[i];
      this.result = { ...this.result };
    });
  }
};
</script>