<template>
  <Modal ref="modal" :title="isNotify ? 'Уведомить кандидата' : 'Контакты'" :show-actions="isNotify">
    <div class="contacts-block contacts-modal">
      <div class="contacts-block_list">
        <div class="contacts-block_list_item" v-if="phone">
          <div class="contacts-block_list_item_inner">
            <div class="contacts-block_list_item_title">Телефон</div>
            <div class="contacts-block_list_item_value">{{ convertPhoneStr(phone)}}</div>
          </div>
          <div class="contacts-block_list_item_after">
            <a
              :href="`tel:+${phone}`"
              class="contacts-block_list_item_button cancel-button">Позвонить</a>
          </div>
        </div>
        <div class="contacts-block_list_item" v-if="phone">
          <div class="contacts-block_list_item_inner">
            <div class="contacts-block_list_item_title">WhatsApp</div>
            <div class="contacts-block_list_item_value">{{convertPhoneStr(phone)}}</div>
          </div>
          <div class="contacts-block_list_item_after">
            <a  v-if="isNotify"
              class="contacts-block_list_item_button cancel-button" @click="notifyTemplate">Написать</a>
            <a v-else
              :href="`https://wa.clck.bar/${phone}`"
              target="_blank"
              class="contacts-block_list_item_button cancel-button">Написать</a>
          </div>
        </div>
        <div class="contacts-block_list_item"
             v-for="contact in filteredContacts" :key="contact.id">
          <div class="contacts-block_list_item_inner">
            <div class="contacts-block_list_item_title">
              {{$store.state.static.candidateContactsStatuses[`${contact.type}`]}}
            </div>
            <div class="contacts-block_list_item_value">{{contact.value}}</div>
          </div>
          <div class="contacts-block_list_item_after">
            <a
              v-if="contact.type === '0'"
              :href="`tel:+${contact.value}`"
              class="contacts-block_list_item_button cancel-button">Позвонить</a>
            <a
              v-else
              :href=" contact.type === '5' ? `mailto:${contact.value}` :'https://' + contact.value"
              target="_blank"
              class="contacts-block_list_item_button cancel-button"
            >
              Написать
            </a>
          </div>
        </div>
        <v-checkbox v-if="showCheckbox" label="Больше не показывать" :true-value="1" :false-value="0" @change="onChangeShowWarn"
                    class="alert-checkbox mt-8"
        />
        <NoResults v-if="filteredContacts.length === 0 && !phone" class="mt-8">
          <template v-slot:title>Контактов нет</template>
          <template v-slot:text>
            <v-btn v-if="candidate_access !== 'read'" color="primary" depressed :ripple="false" @click="addContacts">Добавить</v-btn>
          </template>
        </NoResults>
      </div>
    </div>
    <template v-if="isNotify" v-slot:actions>
      <v-btn text color="btnTextColor" @click="close">Не уведомлять</v-btn>
    </template>
    <NotifyMessageTemplate ref="notify_message_template_modal"/>
  </Modal>
</template>

<script>
import NotifyMessageTemplate from '@/views/templates/NotifyMessageTemplate';
export default {
  name: 'ContactsModal',
  components: { NotifyMessageTemplate },
  data() {
    return {
      showCheckbox: false,
      contacts: [],
      phone: '',
      isNotify: false,
      candidate_access: 'read',
      event: {}
    };
  },
  emits: ['on-click-add'],
  methods: {
    open(params) {
      this.event = {};
      this.contacts = [];
      this.isNotify = false;
      this.candidate_access = 'read';
      this.showCheckbox = false;

      this.phone = params.phone;
      this.getContacts(params.candidateId);
      this.isNotify = params.isNotify;
      if (params.candidate_access) this.candidate_access = params.candidate_access;
      if (params.event) this.event = params.event;
      this.showCheckbox = params.showCheckbox;
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    getContacts(candidateId) {
      this.$server.request2(`candidate/contacts/${candidateId}`, {}, (data)=>{
        this.contacts = data.response;
      });
    },
    addContacts() {
      this.$emit('on-click-add');
      this.$eventBus.emit('edit-phone-modal-open');
      this.close();
    },
    notifyTemplate() {
      this.$refs.notify_message_template_modal.open({event: this.event  });
    },
    onChangeShowWarn(value) {
      this.$server.request2('user/setSetting', {name: 'show_notify_contacts', value}, (data)=>{
        this.$store.commit('setUserData', data.response);
      });
    }
  },
  computed: {
    filteredContacts() {
      return this.contacts.filter((contact) => contact.value !== '' && contact.type !== '2');
    },
  },

};
</script>

<style lang="scss">
.contacts-modal {
  .modal-content {
    top: 100px !important;
  }
  .contacts-block {
    margin-top: 30px;
    &_list {
      & > *:not(:last-child) {
        border-bottom: 1px solid var(--border-color);
      }
      &_item {
        display: flex;
        align-items: center;
        &_button {
          padding: 6px 11px;
        }
        &_title {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
        }
        &_value {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: var(--color-blue);
        }
        &_inner {
          padding: 5px;
          width: 100%;
        }
        &_after {
          padding: 15px 0;
          flex-shrink: 0;
        }
      }
    }
  }
}
</style>
