<template>
  <Modal ref="modal" content-class="create-vacancy-modal"
         :close-on-icon-click-special="true"
         @backdrop-click="close" @close-icon-click="close" :is-no-scroll="false"
         title="Новая вакансия">
    <v-form class="form" ref="vacancy_form" lazy-validation>
      <v-text-field
        v-model="formData.title"
        placeholder="Senior Manager"
        label="Должность"
        persistent-placeholder
        @input="titleError = ''"
        :error-messages="titleError"
      />
      <v-row>
        <DateMenu :value="formData.date_from" label="Дата начала"
                  @update="updateDate('date_from', $event)"
                  :errorText="dateErrorText"
        />
        <DateMenu :value="formData.date_to" label="Дата окончания"
                  @update="updateDate('date_to', $event)"
        />
      </v-row>
      <div v-if="optionsIsShown" class="additional-fields">
        <v-row>
          <v-text-field
            class="col-6"
            v-model="formData.salary_from"
            placeholder="50 000"
            label="Зарплата от"
            :error-messages="salaryError"
            persistent-placeholder
            @input="salaryError = ''"
            v-maska="$store.state.static.masksSumSeparator"

          />
          <v-text-field
            class="col-6"
            v-model="formData.salary_to"
            placeholder="150 000"
            label="Зарплата до"
            persistent-placeholder
            v-maska="$store.state.static.masksSumSeparator"
          />
        </v-row>
        <v-row>
          <v-text-field
            class="col-6"
            v-model="formData.age_from"
            placeholder="18"
            label="Возраст от"
            persistent-placeholder
            @input="ageErrorText = ''"
            :error-messages="ageErrorText"
            v-mask="['#', '##']"
          />
          <v-text-field
            class="col-6"
            v-model="formData.age_to"
            placeholder="45"
            label="Возраст до"
            persistent-placeholder
            v-mask="['#', '##']"
          />
        </v-row>
        <v-row>
          <v-text-field
            class="col-6"
            v-model="formData.experience"
            placeholder="5"
            label="Опыт (лет)"
            v-mask="['#', '##']"
            persistent-placeholder
          />
          <v-select
            class="col-6"
            v-model="formData.gender"
            label="Пол"
            item-value="id"
            item-text="title"
            persistent-placeholder
            :items="[{id: '0', title: 'Любой'}, {id: '1', title: 'Мужской'}, {id: '2', title:'Женский'}]"
            append-icon=""
          />
        </v-row>
        <v-textarea
          v-model="formData.additional"
          placeholder="Стек технологий, с которым предстоит работать: HTML5 / JavaScript / TypeScript / WebSocket"
          label="Навыки и требования"
          persistent-placeholder
          rows="2"
          :auto-grow="true"
        />
        <v-text-field
          v-model="formData.fee"
          placeholder="50 000"
          label="Мой предполагаемый гонорар"
          persistent-placeholder
          v-maska="$store.state.static.masksSumSeparator"
        />
        <!--        <v-select
                  :items="funnels"
                  return-object
                  placeholder="Не выбрана"
                  persistent-placeholder
                  append-icon=""
                  v-model="formData.funnel"
                  class="input-with-label-with-tip"
                >
                  <template v-slot:label @click.prevent="showFunnelTip = !showFunnelTip">
                    <div class="label-with-tip">
                      <span>Воронка</span>
                      <v-btn icon>
                        <v-icon :color="'#ED6B54'">mdi-information</v-icon>
                      </v-btn>
                    </div>
                    <InfoTip text="Воронку можно будет настроить после создания вакансии"/>
                  </template>
                  <template v-slot:item="{item}">
                    <div style="display: flex; flex-wrap: wrap; max-width: 370px;">
                        <span
                          class="mr-1 py-1"
                          style="white-space: nowrap; min-width: 100px;"
                          v-for="(_item, index) in item" :key="index">{{ _item.title }}{{ index === item.length - 1 ? '' : ',' }}</span>
                    </div>
                  </template>
                  <template v-slot:selection="{parent, item}">
                    <div style="display: flex; flex-wrap: wrap; max-width: 350px">
                        <span
                          class="mr-1"
                          style="white-space: nowrap;"
                          v-for="(_item, index) in item" :key="index">{{ _item.title }}{{ index === item.length - 1 ? '' : ',' }}</span>
                    </div>
                  </template>
                </v-select>-->
      </div>
    </v-form>
    <v-btn text color="text_button" @click="optionsIsShown = !optionsIsShown" class="mb-3 align-self-center" :ripple="false">
      {{ optionsIsShown ? 'Скрыть опции' : 'Показать все опции' }}
    </v-btn>
    <template v-slot:actions>
      <v-btn depressed color="primary" @click="createVacancy" :ripple="false" :loading="loading">Готово</v-btn>
    </template>
  </Modal>
</template>
<script>
import InfoTip from '@/components/InfoTip';
import Modal from '@/components/Modal';

export default {
  name: 'CreateVacancyModal',
  components: {
    InfoTip,
    Modal
  },

  data: () => ({
    optionsIsShown: false,
    date_toMenu: false,
    date_fromMenu: false,
    titleError: '',
    salaryError: '',
    dateErrorText: '',
    ageErrorText: '',
    formData: {
      title: '',
      date_from: '',
      date_to: '',
      salary_from: '',
      salary_to: '',
      age_from: '',
      age_to: '',
      experience: '',
      gender: '0',
      additional: '',
      fee: '',
      folder_client: '',
      folder_offer: '',
    },
    funnels: [],
    loading: false,
    showFunnelTip: false,
  }),
  methods: {
    createVacancy() {
      let dataToRequest = this.prepareData();
      if (dataToRequest === 'error') return;

      this.loading = true;
      this.$server.request2('vacancy/create', dataToRequest, (data) => {
        this.loading = false;
        this.$eventBus.emit('vacancy-create');
        this.$router.push(`/vacancies/vacancy/${data.response.id}`);
        this.close();
      }, () => this.loading = false);
    },
    prepareData() {
      if (!this.formData.title) {
        this.titleError = 'Укажите название должности';
      }
      if (this.formData.salary_from * 1 > this.formData.salary_to * 1) {
        this.salaryError = '"зарплата от" должна быть меньше "зарплаты до"';
      }
      if (this.formData.age_from * 1 > this.formData.age_to * 1) {
        this.ageErrorText = '"Возраст от" должен быть меньше "возраста до"';
      }
      if (this.$moment(this.formData.date_from)
        .isAfter(this.$moment(this.formData.date_to))) {
        this.dateErrorText = 'Дата начала не может быть позже даты окончания';
      }
      if (!this.formData.title || this.dateErrorText !== '' || this.salaryError !== '' || this.ageErrorText !== '') {
        return 'error';
      }
      this.formData.date_from = this.formData.date_from ? this.$moment(this.formData.date_from)
        .format('YYYY-MM-DD') : '';
      this.formData.date_to = this.formData.date_to ? this.$moment(this.formData.date_to)
        .format('YYYY-MM-DD') : '';
      // if (this.formData.funnel[0].title === 'Не выбрано' || !this.formData.funnel.length) this.formData.funnel = null;
      return this.formData;
    },
    open() {
      this.optionsIsShown = false;
      this.titleError = '';
      this.dateErrorText = '';
      this.salaryError = '';
      this.ageErrorText = '';
      this.loading = false;
      this.formData = {
        title: '',
        date_from: '',
        date_to: '',
        salary_from: '',
        salary_to: '',
        age_from: '',
        age_to: '',
        experience: '',
        gender: '0',
        additional: '',
        fee: '',
        folder_client: '',
        folder_offer: '',
      };
      this.$refs.modal.open();
      // this.getFunnels();

    },

    close() {
      this.$refs.modal.close();
    },
    /*getFunnels() {
      this.$server.request2('vacancy/funnels', {}, (data) => {
        this.funnels = data.response;
        this.funnels.push([{title: 'Не выбрано'}]);
      });
    },*/
    updateDate(param, date) {
      this.dateErrorText = '';
      this.formData[param] = date;
    }
  },
};
</script>
<style lang="scss">
.create-vacancy-modal {
  .modal-container {
    .modal-content {
      display: flex;
      flex-direction: column;
    }

    .vacancy_form {
      max-height: 450px;
      overflow: scroll;
    }
  }
}
</style>
