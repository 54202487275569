<template>
  <Modal
    ref="modal"
    @backdrop-click="$emit('submit', 0)"
    @close-icon-click="$emit('submit', 0)"
    title="Кандидаты находятся в другой вакансии"
    :vertical-btns="false"
    :width="600"
    content-class="conflict-vacancy-modal"
  >
    <v-simple-table>
      <template #default>
        <thead>
          <tr>
            <th></th>
            <th>Кандидат</th>
            <th>Текущая</th>
            <th>Новая</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(conflict, index) in conflicts" :key="index">
            <td>
              <v-checkbox
                v-model="conflict.selectedCandidate"
                :true-value="1"
                :false-value="0"
              />
            </td>
            <td>
              <CandidateInfoBlock :item="conflict.candidateInfo" :show-position="false"/>
            </td>
            <td>
              <div class="conflicting-vacancy old">
                <div class="conflicting-vacancy_title">
                  {{ conflict.currentVacancy.title }}
                </div>
                <div class="conflicting-vacancy_subtitle">
                  {{ conflict.currentVacancy.company }}
                </div>
              </div>
            </td>
            <td>
              <div class="conflicting-vacancy new">
                <div class="conflicting-vacancy_title">
                  {{ conflict.newVacancy.title }}
                </div>
                <div class="conflicting-vacancy_subtitle">
                  {{ conflict.newVacancy.company }}
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div class="modal-subtitle">Выберите кандидатов, которых хотите переместить в новую вакансию.</div>
    <div class="modal-actions">
      <v-btn depressed :ripple="false" @click="selectAll" :width="200">Выбрать всех</v-btn>
    </div>
    <template v-slot:actions>
      <v-btn depressed :ripple="false" color="primary" @click="submit" max-width="400">Готово</v-btn>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';
export default {
  name: 'ConflictVacancyModal',
  components: {
    Modal
  },
  props: {},
  emits: ['on-submit'],
  data() {
    return {
      conflicts: [],
      vacancyId: null,
    };
  },
  methods: {
    open(data) {
      this.conflicts = [];
      this.vacancyId = null;

      this.conflicts = data.conflicts;
      this.vacancyId = data.vacancyId
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    submit() {
      const selectedCandidates = this.conflicts.filter(conflict=>conflict.selectedCandidate === 1);
      this.$server.request2(`vacancy/addCandidates/${this.vacancyId}`, {
        ids: selectedCandidates.map(conflict=>conflict.candidateInfo.id),
        folder: this.conflicts[0].folder ? this.conflicts[0].folder : '',
        ignore: 1,
      }, (data) => {
        this.$emit('on-submit');
        this.$eventBus.emit('load-vacancy');
        this.close();
      });
    },
    selectAll() {
      this.conflicts.forEach(conflict=>{
        conflict.selectedCandidate = conflict.selectedCandidate === 1 ? 0 : 1;
      })
    }
  },
};
</script>

<style lang="scss">
.v-dialog.conflict-vacancy-modal .modal-content {
  padding-bottom: 0 !important;
  .candidate-info {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .candidate-media {
      width: 56px;
      height: 56px;
      margin-bottom: 4px;
    }

    &_title {
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      margin-bottom: 4px;
    }

    &_subtitle {
      font-size: 16px;
      line-height: 20px;
      color: rgba(var(--page-color-main-rgb), .5)
    }
  }

  .conflicting-vacancies {
    display: flex;

    & > * {
      flex: 1;
    }
  }

  .conflicting-vacancy {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &_title {
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      margin-bottom: 4px;
      margin-top: 4px;
    }

    &_subtitle {
      font-size: 16px;
      line-height: 20px;
      color: rgba(var(--page-color-main-rgb), .5)
    }
  }

  .modal-subtitle {
    margin-bottom: 10px;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    color: rgba(var(--page-color-main-rgb), .5)
  }
}

</style>
