<template>
  <div class="live-search">
    <div class="live-search_current-item-template" @click="currentItemClick">
      <slot name="currentItem" :item="modelValue"/>
    </div>
    <div class="live-search_content" v-show="show">
      <Field
        ref="search-input"
        classes="live-search_input"
        type="text"
        v-model="modelQuery"
        :placeholder="placeholder"
        :title="title"
        @input="$emit('change-query', $event.target.value)"
      />
      <ul class="live-search_content_list">
        <li
          class="live-search_content_list-item"
          v-for="(item, index) in items"
          :key="index"
          @click="select(item)"
        >
          <slot name="listItem" :item="item"/>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LiveSearch',
  props: {
    items: Array,
    modelValue: Object,
    title: String,
    placeholder: String,
    query: String,
  },
  emits: [
    'change-query',
    'update:modelValue',
    'update:query',
    'select',
    'change',
  ],
  data() {
    return {
      show: false,
    };
  },
  methods: {
    currentItemClick() {
      this.show = true;
      this.$nextTick(() => {
        this.$refs['search-input'].$el.querySelector('.form-field_input')
          .focus();
      });
    },
    select(item) {
      this.$emit('update:modelValue', item);
      this.$emit('select', item);
      this.show = false;
    },
  },
  computed: {
    modelQuery: {
      set(value) {
        this.$emit('update:query', value);
      },
      get() {
        return this.query;
      },
    },
  },
  mounted() {
    document.addEventListener('click', (e) => {
      if (this.show) {
        if (!e.target.closest('.live-search')) this.show = false;
      }
    });
  },
};
</script>

<style scoped lang="scss">
.live-search {
  position: relative;
  z-index: 1;

  &_input {
    &::v-deep(.form-field_title) {
      color: var(--page-color-main);
    }
  }

  &_content {
    position: absolute;
    top: -12px;
    right: -12px;
    width: calc(100% + 24px);
    padding: 12px;
    background: #FFFFFF;
    box-shadow: 2px 8px 30px rgba(0, 0, 0, 0.2);
    border-radius: 12px;

    &_list {
      list-style-type: none;
      padding: 0 0 20px 0;
      margin: 0;
      max-height: 200px;
      overflow-y: scroll;

      & > * {
        border-bottom: 1px solid var(--border-color);

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}
</style>
