<template>
  <div class="searchbar">
    <div class="icon icon-search"></div>
    <input type="text" :value="modelValue" @input="onInput" :placeholder="placeholder">
  </div>
</template>

<script>
export default {
  name: 'Searchbar',
  components: {},

  props: {
    modelValue: String,
    placeholder: String,
  },
  emits: [
    'update:modelValue',
    'input',
  ],
  data() {
    return {
      value: null,
      loading: false,
      items: [],
      search: null,
    };
  },
  methods: {
    onInput(event) {
      this.$emit('input', event.target.value);
    },
  },
  watch: {

  },
};
</script>

<style lang="scss" scoped>
.searchbar {
  width: 100%;
  padding-left: 12px;
  display: flex;
  align-items: center;
  background: rgba(233, 233, 233, .6);
  border-radius: 8px;

  .icon {
    font-size: 24px;
    color: rgba(0, 0, 0, .25);
  }

  input {
    width: 100%;
    padding: 10px 8px;
    background: transparent;
    font-size: 15px;
    line-height: 22px;
    border: none;

    &::placeholder {
      opacity: .4;
    }
  }
}
</style>
