import Tabs from '@/components/Tabs/Tabs.vue';
import Tab from '@/components/Tabs/Tab.vue';
import Modal from '@/components/Modal.vue';
import Field from '@/components/Field.vue';
import SkeletonField from '@/components/SkeletonField.vue';
import NoResults from '@/components/NoResults.vue';
import Checkbox from '@/components/Checkbox.vue';
import Searchbar from '@/components/Searchbar.vue';
import CandidateMedia from '@/views/candidate/CandidateMedia.vue';
import CandidateRating from '@/views/candidate/CandidateRating.vue';
import CandidateStatus from '@/views/candidate/CandidateStatus.vue';
import PopoverList from '@/components/PopoverList.vue';
import Alert from '@/components/Alert.vue';
import Confirm from '@/components/Confirm.vue';
import Prompt from '@/components/Prompt.vue';
import ModalWithFields from '@/components/ModalWithFields.vue';
import CandidateFolder from '@/views/candidate/CandidateFolder.vue';
import CandidateInfoBlock from '@/views/candidate/CandidateInfoBlock.vue';
import DateMenu from '@/components/DateMenu.vue';
import ItemComment from '@/components/ItemComment.vue';
import Loader from '@/components/Loader';
export default {
  Tabs,
  Tab,
  Modal,
  Field,
  SkeletonField,
  NoResults,
  Checkbox,
  Searchbar,
  CandidateMedia,
  CandidateRating,
  CandidateStatus,
  PopoverList,
  Alert,
  Confirm,
  ModalWithFields,
  Prompt,
  CandidateFolder,
  CandidateInfoBlock,
  DateMenu,
  ItemComment,
  Loader
};
