<template>
  <Modal ref="modal" :vertical-btns="true" @backdrop-click="close" :custom-backdrop-click-to-close="true"
  :close-on-icon-click-special="true" @close-icon-click="close">
    <div class="completion-modal close">
      <img
        src="@/images/pictures/picture-2.svg"
        alt="vacancy closed picture"
        class="completion-modal_icon-1"
      >
      <img
        src="@/images/pictures/picture-3.svg"
        alt="vacancy closed picture"
        class="completion-modal_icon-2"
      >
      <div class="completion-modal_check">
        <div class="icon icon-check"></div>
      </div>
      <NoResults>
        <template #title>Вакансия закрыта, она в архиве</template>
        <template #text>
          {{ textInCompletionModal }}
        </template>
      </NoResults>
      <v-checkbox label="Больше не показывать" :true-value="1" :false-value="0" @change="onChangeShowModal"
                  class="alert-checkbox"
      />
    </div>
    <template v-slot:actions>
      <v-btn depressed :ripple="false" color="primary" @click="close()" class="mr-4">
        Готово
      </v-btn>
      <v-btn text :ripple="false" @click="openReopenVacancy">Открыть заново</v-btn>
    </template>
    <Confirm
      ref="confirm-reopen-vacancy"
      message="Открыть вакансию заново ?"
      @accept="reopenVacancy"
    />
  </Modal>
</template>

<script>
import { Fireworks } from 'fireworks-js';
import Modal from '@/components/Modal';
export default {
  name: 'OnClose',
  components: {
    Modal
  },
  props: ['remindProbationStartOn'],
  data() {
    return {
      fireworkContainer: null,
      fireworks: null,
    };
  },
  methods: {
    open() {
      this.fireworkContainer = null;
      this.fireworks = null;
      this.$refs.modal.open();
      this.fireworkContainer = document.querySelector('.app-main-container');
      this.fireworks = new Fireworks(this.fireworkContainer, {
        intensity: 160,
      });
      this.fireworks._canvas.className = 'fireworks';
      this.fireworks.start();
      setTimeout(() => {
        this.fireworkStop();
      }, 800);
    },
    close() {
      this.fireworkStop();
      this.$refs.modal.close();
    },
    fireworkStop() {
      setTimeout(() => {
        if (this.fireworks) {
          this.fireworks._canvas.remove();
          this.fireworks.stop();
          this.fireworks = null;
        }
      }, 1500);
    },
    openReopenVacancy() {
      this.$refs['confirm-reopen-vacancy'].open();
      this.$refs.modal.close();
    },
    reopenVacancy() {
      this.$server.request2('vacancy/open', { id: this.vacancy.id }, () => {
        this.$eventBus.emit('vacancy-open', this.vacancy.id);
      });
    },
    onChangeShowModal(value) {
      this.$server.request2('user/setSetting', {name: 'success_close_vacancy_modal', value}, (data)=>{
        this.$store.commit('setUserData', data.response);
      });
    },
  },
  computed: {
    textInCompletionModal() {
      if (this.probationDateTo) {
        const dateText = this.$moment(this.probationDateTo)
          .locale('ru')
          .format('DD MMMM YYYY');
        return `Завершение испытательного срока – ${dateText}${this.remindProbationStartOn ? ', мы пришлем уведомление.' : ''}`;
      }
      return 'Если нужно, вы можете открыть ее заново.';
    },
  }
};
</script>

<style scoped lang="scss">
.completion-modal.close {
  overflow-y: hidden;
}
</style>