<template>
  <div class="main-sidebar-wrap">
    <div class="main-sidebar" :class="{'show': showed}">
      <div class="widgets top-widgets">
        <div class="user-link-wrap">
          <router-link class="nav-item" to="/profile">
            <div class="nav-item_media">
              <img
                class="media-image"
                v-if="user.image && user.image.url"
                :src="user.image.url"
              >
              <div class="media-no-img" v-else>
                <div class="icon icon-profile"></div>
              </div>
            </div>
            <span class="nav-item_text">{{ user.name ? user.name : user.phone ? convertPhoneStr(user.phone) : user.email }}</span>
          </router-link>
        </div>
        <div class="nav-list" v-for="(nav, index) in navLists" :key="index">
          <div class="nav-list-title" v-if="nav.title">{{ nav.title }}</div>
          <div class="nav-list-inner">
            <div v-for="(item, index) in nav.items" :key="index">
              <router-link :to="item.path" class="nav-item" v-if="item.path">
                <div class="nav-item_media">
                  <div :class="['icon', item.icon]"></div>
                </div>
                <span class="nav-item_text">{{ item.text }}</span>
              </router-link>
              <div v-else class="nav-item" @click="navItemClick(item)">
                <div class="nav-item_media">
                  <div :class="['icon', item.icon]"></div>
                </div>
                <span class="nav-item_text">{{ item.text }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="widgets bottom-widgets">
        <div class="sidebar-button" @click="$router.push({name: 'candidatesCreate'})">
          <div class="icon icon-new"></div>
          <div class="sidebar-button_text">Новый кандидат</div>
        </div>
        <div class="sidebar-button" @click="$eventBus.emit('create-vacancy-modal-open')">
          <div class="icon icon-new"></div>
          <div class="sidebar-button_text">Новая вакансия</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      el: null,
      showed: null,
      navLists: [
        {
          items: [
            {
              path: '/',
              text: 'Главная',
              icon: 'icon-home',
            },
            {
              path: '/vacancies',
              text: 'Вакансии',
              icon: 'icon-info',
            },
            {
              path: '/candidates',
              text: 'Кандидаты',
              icon: 'icon-candidate',
            },
            {
              path: '/calendar',
              text: 'Календарь',
              icon: 'icon-calendar',
            },
          ],
        },
        {
          title: 'Полезное',
          items: [
            {
              path: '/history',
              text: 'История',
              icon: 'icon-history',
            },
            {
              path: '/finance',
              text: 'Финансы',
              icon: 'icon-money',
            },
            {
              path: '/analytics',
              text: 'Аналитика',
              icon: 'icon-analytics',
            },
            {
              path: '/scripts',
              text: 'Сценарии',
              icon: 'icon-script',
            },
            {
              path: '/templates',
              text: 'Шаблоны сообщений',
              icon: 'icon-envelope',
            },
            {
              path: '/places',
              text: 'Адреса',
              icon: 'icon-geo',
            },
            {
              text: 'Поддержка',
              icon: 'icon-profile',
              handler: () => {
                this.$eventBus.emit('call-support-modal');
              },
            },
          ],
        },
        {
          items: [
            {
              text: 'Плагин для Chrome',
              icon: 'icon-import',
              handler: () => {
                this.gtmEvent('plagin_linkclick');
                window.open('https://chrome.google.com/webstore/detail/rekroo/gggelkilfdbhicgookaepphnojcmdanj', '_blank');
              },
            },
          ]
        }
      ],
    };
  },
  methods: {
    navItemClick(item) {
      if (typeof item.handler === 'function') item.handler(item, this);
    },
    closeHandlerDOM(e) {
      if (!e.target.closest('.main-sidebar')) {
        this.close();
      }
    },
    show() {
      if (this.showed) return;

      this.showed = true;
      setTimeout(() => {
        document.addEventListener('click', this.closeHandlerDOM);
      });
    },
    close() {
      if (!this.showed) return;

      this.showed = false;
      document.removeEventListener('click', this.closeHandlerDOM);
    },
    toggleSidebarVisibility() {
      if (this.showed) {
        this.close();
      } else {
        this.show();
      }
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  mounted() {
    this.el = this.$el.querySelector('.main-sidebar');
    this.$eventBus.on('toggle-sidebar-visibility', this.toggleSidebarVisibility);
    this.$watch(
      () => this.$route.path,
      () => {
        if (this.showed) this.close();
      },
    );
  },
  beforeDestroy() {
    this.$eventBus.off('toggle-sidebar-visibility');
  },
};
</script>
<style scoped lang="scss">
</style>
