<template>
  <v-dialog
    content-class="loader-modal"
    v-model="opened"
    persistent
    width="100"
    :overlay-color="'rgba(0,0,0,0.3)'"
  >
    <div class="loader-container py-5">
      <v-progress-circular
        indeterminate
        color="primary"
        class="mb-0"
      ></v-progress-circular>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'LoaderModal',
  data() {
    return {
      opened: false,
    }
  },
  methods: {
    open() {
      this.opened = true;
    },
    close() {
      this.opened = false;
    }
  }
};
</script>

<style lang="scss">
.loader-modal {
  background: transparent;
  overflow: hidden;
  box-shadow: none;
  .loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>