import functions from '@/tests/functions';

let place;
export default {
  async openPlaces() {
    functions.querySelector(`.main-sidebar .nav-list:nth-child(3) :nth-child(5) a`).click();
    await functions.wait(300);
  },
  async clickCreateBtn() {
    functions.querySelector('.page-header-button.default-button').click();
    await functions.wait(300);
  },
  async createPlace() {
    let placeModal = functions.getModal();
    if (functions.querySelector('.modal-title', placeModal).innerText !== 'Добавить адрес') throw `Заголовок модалки не "Добавить адрес", ${functions.querySelector('.modal-title', placeModal).innerText}`

    let addressInput = functions.querySelector('.v-autocomplete input', placeModal);
    addressInput.click();
    await functions.wait(500);
    functions.fillTextInput(addressInput, 'моск', false);
    await functions.wait(500);

    let menu = functions.currentMenu();
    let addressOptions = menu.querySelectorAll('.v-list-item');
    let randomAddressIndex = Math.floor(Math.random() * (addressOptions.length - 1));
    addressOptions[randomAddressIndex].click();
    await functions.wait(300);


    let form = functions.querySelector('.form', placeModal);
    let inputs = form.querySelectorAll('input');
    inputs = Array.from(inputs).slice(1);
    for (let input of inputs) {
      functions.fillTextInput(input, functions.genLongText(2));
    }
    place = addressInput.value;
    functions.querySelector('button.default-button', placeModal).click();
    await functions.wait(700);
  },
  async checkPlace() {
    let placesList = functions.querySelector('.place-list');
    let places = placesList.querySelectorAll('li .place-list_item_subtitle');
    places = Array.from(places);
    places =  places.map((place)=>place.innerText);
    if (!places.includes(place)) throw 'Новый адрес в списке не появился';
  },
  async deletePlace() {
    let placesList = functions.querySelector('.place-list');
    let places = placesList.querySelectorAll('li');
    let randomPlaceIndex = functions.rand(0, places.length - 1);
    functions.querySelector('.icon-trash', places[randomPlaceIndex]).click();
    await functions.wait(300);
    let modal = functions.getModal();
    functions.confirm();
    let placesAfterDeleting = placesList.querySelectorAll('li');
    if (places.length === placesAfterDeleting) throw 'Кол-во адресов не уменьшилось после удаления'
  }
}