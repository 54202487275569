<template>
  <div class="event-rating">
    <div class="icon" :class="[color, icon]"></div>
    <span class="text" :class="color">{{ rating }}</span>
  </div>
</template>

<script>
export default {
  name: 'EventRating',
  props: {
    rating: [String, Number],
  },
  computed: {
    color() {
      if (!Number(this.rating)) return '';
      if (this.rating >= 4) return 'green_text';
      if (this.rating >= 3) return 'yellow_text';
      return 'red_text';
    },
    icon() {
      if (!Number(this.rating)) return 'icon-rating-line';
      if (this.rating >= 4) return 'icon-rating';
      if (this.rating >= 3) return 'icon-rating-half';
      return 'icon-rating-line';
    },
  },
};
</script>

<style lang="scss">
.event-rating {
  display: inline-flex;
  align-items: center;

  .green_text {
    color: #8ABCAE;
  }

  .yellow_text {
    color: #BA9A35;
  }

  .red_text {
    color: #B8705E;
  }

  .icon {
    font-size: 20px;
  }
  .text {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.25px;
  }
}
</style>
