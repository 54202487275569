<template>
  <Modal ref="modal" title="Связаться с поддержкой">
    <div class="support-block">
      <div class="support-block_list">
        <div class="support-block_list_item">
          <div class="support-block_list_item_inner">
            <div class="support-block_list_item_title">Email</div>
            <div class="support-block_list_item_value">{{email}}</div>
          </div>
          <div class="support-block_list_item_after">
            <a
              :href="`mailto:${email}`"
              target="_blank"
              class="support-block_list_item_button cancel-button"
            >
              Написать
            </a>
          </div>
        </div>
        <div class="support-block_list_item">
          <div class="support-block_list_item_inner">
            <div class="support-block_list_item_title">Telegram</div>
            <div class="support-block_list_item_value">{{telegram}}</div>
          </div>
          <div class="support-block_list_item_after">
            <a :href="telegram"
               target="_blank"
               class="support-block_list_item_button cancel-button"
            >
              Написать
            </a>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
export default {
  name: 'SupportModal',
  data() {
    return {
      email: 'info@rekroo.org',
      telegram: 'https://telegram.me/rekroo_support',
    };
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
  },
};
</script>

<style scoped lang="scss">
.support-block {
  &_list {
    & > *:first-child {
      border-bottom: 1px solid var(--border-color);
    }
    &_item {
      display: flex;
      align-items: center;
      &_button {
        padding: 6px 11px;
      }
      &_title {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }
      &_value {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: var(--color-blue);
      }
      &_inner {
        padding: 5px;
        width: 100%;
      }
      &_after {
        flex-shrink: 0;
      }
    }
  }
}
</style>
