<template>
  <div class="loader" :style="`height: ${height}px`">
    <v-progress-circular indeterminate color="text_button"/>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: ['height'],
};
</script>

<style scoped lang="scss">
.loader {
  width: 100%;
  margin: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>