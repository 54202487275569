import Vue from 'vue';
import Router from 'vue-router';
import Dashboard from '@/views/dashboard/Dashboard.vue';

Vue.use(Router);


function configRoutes() {
  return [
    {
      path: '/',
      name: 'Dashboard',
      component: Dashboard,
    },
    {
      path: '/vacancies',
      component: () => import('../views/vacancies/VacanciesView.vue'),
      children: [
        {
          path: '',
          name: 'Vacancies',
          component: () => import('../views/vacancies/Vacancies.vue'),
        },
        {
          path: 'vacancy/:vacancyId',
          props: true,
          name: 'Vacancy',
          component: () => import('../views/vacancy/Vacancy.vue'),
        },
      ],
    },
    {
      path: '/vacancies/vacancy/:vacancyId/folder/:folder',
      name: 'VacancyFolder',
      props: (route) => ({
        vacancy: {},
        ...route.params,
      }),
      component: () => import('../views/vacancy/Folder.vue'),
    },
    {
      path: '/candidates',
      component: () => import('../views/candidates/CandidatesView.vue'),
      children: [
        {
          path: '',
          name: 'Candidates',
          component: () => import('../views/candidates/Candidates.vue'),
        },
        {
          path: 'candidate/:candidateId',
          name: 'Candidate',
          component: () => import('../views/candidate/Candidate.vue'),
          props: true,
        },
        {
          path: 'create',
          name: 'candidatesCreate',
          component: () => import('../views/candidates/create/MethodsCreatingCandidates.vue'),
        },
        {
          path: 'create/manually',
          name: 'candidatesCreateManually',
          component: () => import('../views/candidates/create/CreateCandidatesManually.vue'),
        },
        {
          path: 'create/resume',
          name: 'candidatesCreateResume',
          component: () => import('../views/candidates/create/CreateCandidatesFromResume.vue'),
        },
      ],
    },
    {
      path: '/calendar',
      name: 'Calendar',
      component: () => import('../views/calendar/Calendar.vue'),
    },
    {
      path: '/interview/:eventId',
      name: 'Interview',
      component: () => import('../views/event/Interview.vue'),
      props: true,
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('../views/profile/Profile.vue'),
    },
    {
      path: '/places',
      name: 'places',
      component: () => import('../views/places/Places.vue'),
    },
    {
      path: '/scripts',
      component: () => import('../views/scripts/ScriptsView.vue'),
      children: [
        {
          path: '',
          name: 'Scripts',
          component: () => import('../views/scripts/Scripts.vue'),
        },
        {
          path: 'script/:scriptId',
          name: 'Script',
          component: () => import('../views/scripts/Script.vue'),
          props: true,
        },
      ],
    },
    {
      path: '/history',
      name: 'history',
      component: () => import('../views/history/History.vue'),
    },
    {
      path: '/finance',
      name: 'finance',
      component: () => import('../views/finance/Finance.vue'),
    },
    {
      path: '/analytics',
      name: 'Analytics',
      component: () => import('../views/analytics/Analytics.vue'),
    },
    {
      path: '/templates',
      name: 'Message Templates',
      component: () => import('../views/templates/Templates')
    },
    {
      path: '/login',
      component: ()=>import('../views/login/LoginView.vue'),
      children: [
        {
          path: 'phone',
          name: 'LoginPhone',
          component: () => import('../views/login/LoginPhone.vue'),
        },
        {
          path: 'email',
          name: 'LoginEmail',
          component: () => import('../views/login/LoginEmail.vue'),
        },
        {
          path: 'code',
          name: 'LoginCode',
          component: () => import('../views/login/LoginCode.vue'),
        },
      ]
    },
    /*{
      path: '/:pathMatch(.*)*',
      redirect: (to) => {
        const searchParams = new URLSearchParams();
        Object.entries(to.query)
          .forEach(([key, value]) => {
            searchParams.append(key, String(value));
          });
        if (searchParams.has('codeToken')) {
          searchParams.delete('codeToken');
        }

        const query = {
          url: `${to.path}?${searchParams.toString()}${to.hash}`,
        };
        if (to.query.codeToken) query.codeToken = to.query.codeToken;

        return {
          name: 'login',
          query,
          params: to.params,
        };
      },
    },*/
    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      component: () => import(/* webpackChunkName: "about" */ '../views/NotFound.vue'),
    },
  ];
}

export const router = new Router({
  mode: 'history',
  linkActiveClass: 'active',
  routes: configRoutes(),
});

function configLoginRoutes() {
  return [
    {
      path: '/',
      name: 'login',
      component: () => import('../views/login/LoginView.vue'),
    },
  ];
}

export const loginRouter = new Router({
  mode: 'history',
  linkActiveClass: 'active',
  routes: configLoginRoutes(),
});

function configShareRoutes() {
  return [
    {
      path: '/shr/v/:token',
      name: 'Vacancies',
      component: () => import('../views/vacancies/Vacancies'),
    },
    {
      path: '/shr/v/:token/:id',
      name: 'Vacancy',
      component: () => import('../views/vacancy/Vacancy'),
    },
    {
      path: '/shr/v/folder/:folder/:token/:vacancyId',
      name: 'VacancyFolder',
      component: () => import('../views/vacancy/Folder'),
    },
    {
      path: '/shr/:token',
      name: 'Candidates',
      component: () => import('../views/candidates/Candidates.vue'),
    },
    {
      path: '/shr/:token/:id',
      name: 'Candidate',
      component: () => import('../views/candidate/Candidate.vue'),
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      component: () => import(/* webpackChunkName: "about" */ '../views/NotFound.vue'),
    },
  ];
}

export const shareRouter = new Router({
  mode: 'history',
  linkActiveClass: 'active',
  routes: configShareRoutes(),
});
