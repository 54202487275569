<template>
  <div class="dashboard-block" :class="classes">
    <div class="dashboard-block_head">
      <div class="dashboard-block_head_left">
        <router-link class="dashboard-block_title" :to="to">
          <span>{{ title }}</span>
          <div class="icon icon-arrow flip-horizontally"></div>
        </router-link>
      </div>
      <div class="dashboard-block_head_right">
        <slot name="head-right"/>
      </div>
    </div>
    <div class="dashboard-block_content">
      <slot name="content"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardBlock',
  props: {
    title: String,
    to: [String, Object],
    classes: String
  },
};
</script>

<style scoped lang="scss">
.dashboard-block {
  width: 100%;

  &_head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
  }

  &_title {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 700 !important;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.5px !important;
    color: #111111 !important;
    .icon {
      margin-top: 4px;
      margin-left: 8px;
      font-size: 16px;
      color: rgba(var(--page-color-main-rgb), .25);
    }
  }
  &_content {
    &::v-deep(.no-results-block) {
      margin-top: 50px;
      margin-bottom: -50px;
    }
  }
}
</style>
