import functions from '@/tests/functions';
import auth from '@/tests/auth';

let sharedAcc = 'm@khromov.su';
let sharedVacancy = 'Тестовая_вакансия_очень длинное название вакансии 2023-03-05T14:09:43';
export default {
  async openVacancyShareModal() {
    functions.openSection(1);
    await functions.wait(500);
    let vacanciesTable = functions.querySelector('.vacancies-page tbody');
    let vacancies = vacanciesTable.querySelectorAll('tr');
    vacancies[0].click();
    await functions.wait(500);
    functions.querySelector('.vacancy-header-buttons').click();
    await functions.wait(300);
    let menu = functions.currentMenu();
    let buttons = menu.querySelectorAll('.popover-list-item button');
    buttons = Array.from(buttons);
     buttons[2].click();
    await functions.wait(300);
    let modal = functions.getModal();
    if (!functions.querySelector('.modal-title', modal).innerText.includes('Настройки доступа')) throw 'Заголовок модалки не содержит "Поделиться"';
  },
  async share() {
    let modal = functions.getModal();
    let data = [sharedAcc, '0'];
    await functions.fillForm(modal, data);
    functions.querySelector('.add-user-btn', modal).click();
    await functions.wait(300);
    let sharedUsers = functions.querySelector('.v-list', modal);
    let sharedUsersList = sharedUsers.querySelectorAll('.v-list-item');
    let lastUser = functions.querySelector('.candidates-list-item_title', sharedUsersList[0]).innerText;
    if (lastUser !== sharedAcc) throw 'Юзер не добавился';
    functions.querySelector('.modal-actions .v-btn.primary', modal).click();
    await functions.wait(300);
  },
  async logout() {
    await auth.logout();
  },
  async loginToSharedAcc() {
    await auth.login(true);
    await functions.wait(300);
    functions.reloadAndStartFrom('Поделиться_вакансией.checkShare', {});
    await auth.checkCode();
  },
  async checkShare() {
    functions.openSection(1);
    await functions.wait(500);
    let vacancies = functions.querySelector('.tabs-list .tab:first-child .vacancies-table tbody');
    let vacanciesList = vacancies.querySelectorAll('tr');
    for (let vacancy of vacanciesList) {
      let vacancyTitle = functions.querySelector('.vacancy-title', vacancy).innerText;
      if (vacancyTitle === sharedVacancy) {
        vacancy.click();
      }
    }
    await functions.wait(500);
    let vacancyPage = functions.querySelector('.page-vacancy');
    if (!functions.querySelector('.owner', vacancyPage, false)) throw 'Владелец не отображается в расшареной вакансии';
    let tabs = vacancyPage.querySelectorAll('.v-tab');
    if (tabs.length === 3) throw 'Вкладки "ред." не должно быть, а она есть';
    tabs[0].click();
    await functions.wait(300);
    if (functions.querySelector('.add-candidate-button', tabs[0], false)) throw 'Есть возможность добавить кандидата. Не должно быть';
    functions.querySelector('.vacancy-folders_title', vacancyPage).click();
    await functions.wait(300);
    let folderPage = functions.querySelector('.page-folder');
    if (functions.querySelector('.add-candidate-button', folderPage, false)) throw 'Есть возможность добавить кандидата. Не должно быть';
    if (functions.querySelector('.v-data-table__checkbox.v-simple-checkbox', folderPage, false)) throw 'Есть возможность выбирать кандидата в списке. Не должно быть';

    functions.querySelector('.page-header_back-link').click();
    await functions.wait(300);

    tabs[1].click();
    await functions.wait(300);
    if (functions.querySelector('.vacancy-events_header', tabs[1], false)) throw 'Кнопок добавления ивентов не должно быть, а они есть';

  }
}