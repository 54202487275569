<template>
  <router-link
    :to="{name: 'Candidate', params: {candidateId: candidate.id}}"
    class="candidate-item py-4"
  >
    <v-row>
      <v-col :cols="candidate.vacancy ? 5 : 8">
        <CandidateInfoBlock :item="candidate"/>
      </v-col>
      <v-col cols="4" class="overflow-hidden d-flex align-center justify-end">
        <router-link
          class="candidate-item-vacancy color-blue"
          :to="{name: 'Vacancy', params: {vacancyId: candidate.vacancy.id}}"
          v-if="candidate.vacancy && Number(candidate.vacancy.id)"
          @click.prevent
        >
          {{ candidate.vacancy.title }} {{ candidate.vacancy.company }}
        </router-link>
        <span class="candidate-item-vacancy empty" v-else @click.stop>Не прикреплен</span>
      </v-col>
      <v-col cols="3" class="d-flex align-center justify-end" v-if="candidate.vacancy">
        <div>
          <CandidateFolder :candidate="candidate" v-if="candidate.folder"/>
          <CandidateStatus :candidate="candidate" v-else/>
        </div>
      </v-col>
    </v-row>
  </router-link>
</template>

<script>
export default {
  name: 'DashboardCandidateItem',
  components: {},

  props: {
    candidate: Object,
  },
};
</script>

<style scoped lang="scss">
.candidate-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  .empty {
    font-weight: 600;
    color: rgba(var(--page-color-main-rgb), .25);
  }
  &_after {
    padding: 24px 0;
    flex-shrink: 0;
    text-align: right;

    & > * {
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .candidate-media {
    width: 42px;
    height: 42px;
  }

  .candidate-rating {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }

  &_title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  &_subtitle {
    font-size: 14px;
    line-height: 16px;
    color: rgba(var(--page-color-main-rgb), .5);
  }

  &-vacancy {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: var(--color-blue);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
  }
}
</style>
